import React from 'react'
import style from "css/library/speakerCard.module.css";
import { Skeleton } from 'antd';

function SpeakerCard({ data }) {
    console.log("SpeakerCard Data");
    console.log(data);
    return (
        <div className={style.card_wrapper}>
            {
                data.IMG_URL !== null && data.IMG_URL !== '' && data.IMG_URL !== undefined
                    ?
                    <img
                        className={style.speaker_img}
                        alt=""
                        src={
                            process.env.REACT_APP_CLIENT_URL +
                            data.IMG_URL
                        }
                    />
                    :
                    <Skeleton.Image
                        style={{ width: '100%', height: '100%' }}
                        className={style.speaker_img}
                        active
                    />
            }
            <div className={style.speaker_name}>{data.FIRST_NM + " " + data.LAST_NM}</div>
            <div className={style.speaker_title}>{data.TITLE}</div>
            <div className={style.speaker_country}>{data.COUNTRY}</div>
        </div>
    )
}

export default SpeakerCard
