import { createSlice } from "@reduxjs/toolkit";

/**
 * 파일명: allowPageSlice.js
 * 작성자: 권도훈
 * 작성일자: 23.04.06
 * 설명: 접근 가능 페이지 목록
 */
const allowPageSlice = createSlice({
    name: "allowPageSlice",
    initialState: {
        pages: []
    },
    reducers: {
        insertAllowPageInfo: (state, action) => {
            state.pages = action.payload.pages;
        },
        deleteAllowPageInfo: (state) => {
            state.pages = [];
        }
    }
})

export default allowPageSlice;

export const { insertAllowPageInfo, deleteAllowPageInfo } = allowPageSlice.actions;