import React, { useState } from "react";
import PropTypes from "prop-types";
import style from "css/account.module.css";
import { ReactComponent as Visible } from "asset/common/visiable.svg";
import { ReactComponent as Invisible } from "asset/common/invisiable.svg";

function Input(props) {
  const [type, setType] = useState(props.type);
  return (
    <div className={style.input_container}>
      <input
        className={props.error ? `${style.input} ${style.error}` : style.input}
        type={type}
        placeholder={props.placeholder}
        value={props.value}
        onChange={(e) => props.onChangeEvent(e.target.value)}
        onKeyDown={(e) =>
          props.onKeyDownEvent !== undefined && props.onKeyDownEvent(e.key)
        }
        disabled={props.disabled}
        maxLength={props.maxLength !== undefined ? props.maxLength : ""}
        min={type === "number" ? 1 : ""}
        max={type === "number" ? 100 : ""}
      />
      {props.type === "password" && (
        <VisibleIcon changeType={(res) => setType(res)} />
      )}
    </div>
  );
}

Input.propTypes = {
  type: PropTypes.string.isRequired,
  error: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  onChangeEvent: PropTypes.func,
  onKeyDownEvent: PropTypes.func,
  disabled: PropTypes.bool,
  maxLength: PropTypes.number,
};

function VisibleIcon({ changeType }) {
  const [isVisible, setIsVisible] = useState(false);
  const onClickEvent = () => {
    if (isVisible) {
      changeType("password");
    } else {
      changeType("text");
    }
    setIsVisible(!isVisible);
  };
  return (
    <div className={style.visible_icon}>
      {isVisible ? (
        <Invisible onClick={() => onClickEvent()} />
      ) : (
        <Visible onClick={() => onClickEvent()} />
      )}
    </div>
  );
}

export default Input;
