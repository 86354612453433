import React from "react";
import PropTypes from "prop-types";
import style from "css/account.module.css";

function Item(props) {
  return (
    <section className={style.item}>
      <article className={style.item_header}>
        <div
          className={
            props.require ? `${style.dot} ${style.require}` : style.dot
          }
        />
        <p className={style.item_title}>{props.title}</p>
      </article>

      <div className={style.item_body}>{props.component}</div>

      {props.error && <p className={style.item_error_msg}>{props.msg}</p>}
    </section>
  );
}

Item.propTypes = {
  require: PropTypes.bool,
  error: PropTypes.bool,
  title: PropTypes.string,
  component: PropTypes.element,
  msg: PropTypes.string,
};

export default Item;
