import React, { useCallback, useEffect, useState } from "react";
import style from "css/register/eventList.module.css";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import axios from "axios";
import Cookies from "universal-cookie";
import { openAlert } from "redux/slices/alertSlice";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import EventRegister from "./eventRegister";
import Loading from "components/common/loading";

/**
 * Version : 1.0
 * 작성일자 : 2023-12-18
 * 작성자 : 김희민
 * 설명 : zoom event list
 */
export default function EventList() {
  const navigate = useNavigate();
  const cookies = new Cookies();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [afterEventList, setAfterEventList] = useState([]);
  const [currentEvent, setCurrentEvent] = useState({});
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  // 미팅날짜(시작-종료) 라벨표시 형식지정
  function eventTimeLabel(startDateTime, endDateTime) {
    // dayjs 객체 생성
    const startTime = dayjs(startDateTime);
    const endTime = dayjs(endDateTime);

    // formatting (ex. 2023.10.14 SAT, 11:30 - 13:00 (GMT))
    const formattedStartDateTime = startTime.format("YYYY.MM.DD ddd, HH:mm");
    const formattedEndDateTime = endTime.format("HH:mm");
    const formattedDateTimeRange = `${formattedStartDateTime} - ${formattedEndDateTime} (GMT)`;

    return formattedDateTimeRange;
  }

  const getEventList = useCallback(() => {
    const nowDateTime = toDateTimeFormat(new Date());
    let config = {
      params: {
        NOW_DATE_TIME: nowDateTime,
      },
    };

    axios
      .get("/event/list", config)
      .then((res) => {
        if (res.status === 200) {
          setAfterEventList(res.data.afterEventList);
          setCurrentEvent(res.data.currentEventList[0]);
        }
      })
      .catch(() => {
        dispatch(
          openAlert({
            title: "Notice",
            content: "An error occurred while processing data.",
            submitEventHandler: () => getEventList(),
          })
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    if (isLoading) {
      getEventList();
    }
  }, [getEventList, isLoading]);

  // date형식 변환 (ex. 2024-08-28 13:00:00)
  function toDateTimeFormat(date) {
    function twoDigits(d) {
      if (0 <= d && d < 10) return "0" + d.toString();
      if (-10 < d && d < 0) return "-0" + (-1 * d).toString();
      return d.toString();
    }

    return (
      date.getUTCFullYear() +
      "-" +
      twoDigits(1 + date.getUTCMonth()) +
      "-" +
      twoDigits(date.getUTCDate()) +
      " " +
      twoDigits(date.getUTCHours()) +
      ":" +
      twoDigits(date.getUTCMinutes()) +
      ":" +
      twoDigits(date.getUTCSeconds())
    );
  }

  // 좋아요 버튼 핸들러
  const toggleFavorite = (eventId, isFavorite) => {
    if (cookies.get("AuthToken") === undefined) {
      dispatch(
        openAlert({
          title: "Notice",
          content: "This service requires login. Please login first.",
          submitEventHandler: () =>
            navigate(process.env.REACT_APP_CLIENT_URL + "signin", {
              state: { returnPage: true },
            }),
        })
      );
      return;
    }

    const params = {
      EVENT_ID: eventId,
      IS_FAVORITE: isFavorite,
    };

    axios
      .put("/event/favorite", params)
      .then((res) => {
        if (res.status === 200) {
          getEventList();
        }
      })
      .catch((error) => {
        dispatch(
          openAlert({
            title: "Notice",
            content: "An error occurred while processing data.",
            submitEventHandler: () => getEventList(),
          })
        );
      });
  };

  // 알람 버튼 핸들러
  const toggleAlarm = (eventId, isAlarm) => {
    if (cookies.get("AuthToken") === undefined) {
      dispatch(
        openAlert({
          title: "Notice",
          content: "This service requires login. Please login first.",
          submitEventHandler: () =>
            navigate(process.env.REACT_APP_CLIENT_URL + "signin", {
              state: { returnPage: true },
            }),
        })
      );
      return;
    }

    const params = {
      EVENT_ID: eventId,
      IS_ALARM: isAlarm,
    };

    axios
      .put("/event/alarm", params)
      .then((res) => {
        if (res.status === 200) {
          getEventList();
        }
      })
      .catch((error) => {
        dispatch(
          openAlert({
            title: "Notice",
            content: "An error occurred while processing data.",
            submitEventHandler: () => getEventList(),
          })
        );
      });
  };

  // 상세페이지로 이동 버튼 핸들러
  const readMoreBtnHandler = (id) => {
    navigate(process.env.REACT_APP_CLIENT_URL + "register?id=" + id);
  };

  return (
    <div className={style.wrapper}>
      <div className={style.body}>
        {isLoading ? (
          <Loading />
        ) : (
          <div className={style.eventListContainer}>
            {/* {currentEvent && (
            <section className={style.firstEvent}>
              <div className={style.firstEventHeader}>
                <div>Register</div>
              </div>
              <div className={style.firstEventCard}>
                <div className={style.firstEventImage_wrapper}>
                  <img
                    className={style.firstEventImage}
                    alt=""
                    src={
                      process.env.REACT_APP_CLIENT_URL +
                      (isMobile
                        ? currentEvent.REGISTER_DETAIL_IMG_MOBILE
                        : currentEvent.REGISTER_DETAIL_IMG)
                    }
                  />
                  <button
                    className={style.firstEventLikeIconArea}
                    onClick={() =>
                      toggleFavorite(
                        currentEvent.EVENT_ID,
                        currentEvent.IS_FAVORITE
                      )
                    }
                    aria-label="like"
                    style={{ right: "13px" }}
                  >
                    <img
                      className={style.firstEventLikeIcon}
                      alt=""
                      src={
                        process.env.REACT_APP_CLIENT_URL +
                        (currentEvent.IS_FAVORITE === 1
                          ? "img/register/like-active-icon.png"
                          : "img/register/like-icon.png")
                      }
                    ></img>
                  </button>
                  <button
                    className={style.firstEventAlarmIconArea}
                    onClick={() =>
                      toggleAlarm(currentEvent.EVENT_ID, currentEvent.IS_ALARM)
                    }
                    aria-label="alarm"
                    style={{ display: "none" }}
                  >
                    <img
                      className={style.firstEventAlarmIcon}
                      alt=""
                      src={
                        process.env.REACT_APP_CLIENT_URL +
                        (currentEvent.IS_ALARM === 1
                          ? "img/register/alarm-active-icon.png"
                          : "img/register/alarm-icon.png")
                      }
                    ></img>
                  </button>
                </div>
                <div className={style.firstEventDetails}>
                  <div className={style.firstEventTitle}>
                    <span>{currentEvent.EVENT_NM}</span>
                  </div>
                  <div className={style.firstEventDescription}>
                    <span>{currentEvent.DESCRIPTION}</span>
                  </div>
                  <div className={style.firstEventDateTime}>
                    <img
                      className={style.firstEventDateTimeImg}
                      alt=""
                      src={
                        process.env.REACT_APP_CLIENT_URL +
                        "img/register/clock-icon.png"
                      }
                    ></img>
                    <span>
                      {eventTimeLabel(
                        currentEvent.START_TIME,
                        currentEvent.END_TIME
                      )}
                    </span>
                  </div>
                  <button
                    className={style.firstEventRegisterBtn}
                    onClick={() => readMoreBtnHandler(currentEvent.EVENT_ID)}
                  >
                    Read More
                  </button>
                </div>
                <button
                  className={style.firstEventRegisterBtn_mobile}
                  onClick={() => readMoreBtnHandler(currentEvent.EVENT_ID)}
                >
                  Read More
                </button>
              </div>
            </section>
          )} */}

            {currentEvent?.EVENT_ID === undefined ||
            currentEvent?.EVENT_ID === null ? (
              <></>
            ) : (
              <>
                <div className={style.firstEventHeader}>
                  <div>
                    Register <span>Register for our next JAM webinar.</span>
                  </div>
                </div>
                <section className={style.firstEvent}>
                  <EventRegister eventId={currentEvent.EVENT_ID} />
                </section>
              </>
            )}

            {/* 커밍순 제테마 측 요청으로 인하여 잠시 주석 */}
            {/* <section className={style.upcomingEvents}>
              <div className={style.upcomingEventHeader}>
                <div>Coming Soon</div>
              </div>
              <div>
                <div className={style.upcomingEventCardArea}>
                  {afterEventList.length > 0 &&
                    afterEventList.map((event, index) => (
                      <div className={style.upcomingEventCard} key={index}>
                        <div className={style.upcomingEventIconArea}>
                          <button
                            className={style.upcomingEventLikeIconArea}
                            onClick={() =>
                              toggleFavorite(event.EVENT_ID, event.IS_FAVORITE)
                            }
                            aria-label="like"
                          >
                            <img
                              className={style.upcomingEventLikeIcon}
                              alt=""
                              src={
                                process.env.REACT_APP_CLIENT_URL +
                                (event.IS_FAVORITE === 1
                                  ? "img/register/like-active-icon.png"
                                  : "img/register/like-icon.png")
                              }
                            ></img>
                          </button>
                          <button
                            className={style.upcomingEventAlarmIconArea}
                            onClick={() =>
                              toggleAlarm(event.EVENT_ID, event.IS_ALARM)
                            }
                            aria-label="alarm"
                            style={{ display: "none" }}
                          >
                            <img
                              className={style.upcomingEventAlarmIcon}
                              alt=""
                              src={
                                process.env.REACT_APP_CLIENT_URL +
                                (event.IS_ALARM === 1
                                  ? "img/register/alarm-active-icon.png"
                                  : "img/register/alarm-icon.png")
                              }
                            ></img>
                          </button>
                        </div>
                        <div className={style.upcomingEventDetails}>
                          <div className={style.upcomingEventTitle}>
                            <span>{event.EVENT_NM}</span>
                          </div>
                          <div className={style.upcomingEventDescription}>
                            <span>{event.DESCRIPTION}</span>
                          </div>
                          <div className={style.upcomingEventDateTime}>
                            <img
                              className={style.upcomingEventDateTimeImg}
                              alt=""
                              src={
                                process.env.REACT_APP_CLIENT_URL +
                                "img/register/clock-icon.png"
                              }
                            ></img>
                            <span>
                              {eventTimeLabel(event.START_TIME, event.END_TIME)}
                            </span>
                          </div>
                          <button
                            className={style.upcomingEventRegisterBtn}
                            onClick={() => readMoreBtnHandler(event.EVENT_ID)}
                          >
                            Read More
                          </button>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </section> */}
          </div>
        )}
      </div>
    </div>
  );
}
