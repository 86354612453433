import React from "react";
import style from "css/myInfo.module.css";
import { Input } from "antd";

function InfoInput({ title, data, setValue }) {
  return (
    <div className={style.myInfo_input_box}>
      {/* input title */}
      <div className={style.myInfo_input_title}>
        <div className={style.orange_dot}></div>
        <div className={style.input_title}>{title}</div>
      </div>
      {/* input box */}
      <Input className={style.input_box} onChange={setValue} value={data} />
    </div>
  );
}

export default InfoInput;
