import React from "react";
import PropTypes from "prop-types";
import style from "css/account.module.css";
import { Spin } from "antd";

function Button(props) {
  const render = () => {
    if (props.loading) {
      return <Spin />;
    } else {
      return props.content;
    }
  };
  return (
    <button
      className={
        props.loading ? `${style.button} ${style.loading}` : style.button
      }
      disabled={props.disabled || props.valid || props.loading}
      onClick={() => props.clickEvent()}
    >
      {render()}
    </button>
  );
}

Button.propTypes = {
  content: PropTypes.any.isRequired,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  valid: PropTypes.bool,
  clickEvent: PropTypes.func,
};

export default Button;
