import React, { useState } from "react";
import style from "css/landing.module.css";
import { useMediaQuery } from "react-responsive";
import { ConfigProvider, FloatButton } from "antd";

function FloatingButton() {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const isTablet = useMediaQuery({
    query: "(min-width: 768px) and (max-width: 1023px)",
  });
  const [isOpen, setIsOpen] = useState(false);

  const youtubeUrl = "https://www.youtube.com/@jetema1834";
  const instagramUrl = "https://www.instagram.com/eptq_official/";
  const jetemaUrl = "https://www.jetema.com/";
  const eptqUrl = "https://epitiquefiller.com/";

  return (
    <ConfigProvider
      theme={{
        components: {
          FloatButton: {
            fontFamily: "Pretendard",
            colorText: "#1f299c",
          },
        },
      }}
    >
      <FloatButton.Group
        rootClassName={isOpen ? style.float_root_btn : ""}
        className={isOpen ? style.float_btn_area : ""}
        onOpenChange={(e) => setIsOpen(e)}
        // className="floatButtonGroup"
        trigger="hover"
        description="SNS"
        icon={null}
        style={{
          right: isMobile ? 8 : isTablet ? 27 : 39,
          width: isMobile ? 53 : isTablet ? 56 : 79,
        }}
      >
        <div className={style.floating_btn_item}>
          <FloatButton
            className={style.floating_btn}
            onClick={() => window.open(youtubeUrl)}
            icon={
              <img
                alt=""
                src={
                  process.env.REACT_APP_CLIENT_URL + "img/landing/youtube.png"
                }
                style={{
                  width: isMobile ? "17px" : isTablet ? "19px" : "27px",
                }}
              />
            }
          />
          <div className={style.floating_btn_label}>Youtube</div>
        </div>

        <div className={style.floating_btn_item}>
          <FloatButton
            className={style.floating_btn}
            onClick={() => window.open(instagramUrl)}
            icon={
              <img
                alt=""
                src={
                  process.env.REACT_APP_CLIENT_URL + "img/landing/instagram.png"
                }
                style={{
                  width: isMobile ? "16px" : isTablet ? "19px" : "26px",
                }}
              />
            }
          />
          <div className={style.floating_btn_label}>Instagram</div>
        </div>
        <div className={style.floating_btn_item}>
          <FloatButton
            className={style.floating_btn}
            onClick={() => window.open(jetemaUrl)}
            icon={
              <img
                alt=""
                src={
                  process.env.REACT_APP_CLIENT_URL + "img/landing/jetema.png"
                }
                style={{
                  width: isMobile ? "13px" : isTablet ? "15px" : "23px",
                }}
              />
            }
          />
          <div className={style.floating_btn_label}>Jetema</div>
        </div>
        <div className={style.floating_btn_item}>
          <FloatButton
            className={style.floating_btn}
            onClick={() => window.open(eptqUrl)}
            icon={
              <img
                alt=""
                style={{
                  width: isMobile ? "25px" : isTablet ? "27px" : "39px",
                }}
                src={process.env.REACT_APP_CLIENT_URL + "img/landing/eptq.png"}
              />
            }
          />
          <div className={style.floating_btn_label}>e.p.t.q.</div>
        </div>
      </FloatButton.Group>
    </ConfigProvider>
  );
}

export default FloatingButton;
