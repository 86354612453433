import React, { useCallback, useEffect, useState } from "react";
import style from "css/myInfo.module.css";
import InfoInput from "./infoInput";
import { Button } from "antd";
import axios from "axios";
import { useDispatch } from "react-redux";
import { closeAlert, openAlert } from "redux/slices/alertSlice";
import SelectInput from "./select";
import NumberInput from "./numberInput";

function Information() {
  //dispatch
  const dispatch = useDispatch();

  const [init, setInit] = useState(true);
  const [myData, setMyData] = useState({
    FIRST_NM: "",
    LAST_NM: "",
    EMAIL: "",
    AGE: 1,
    GENDER: "",
    COUNTRY: "",
    SPECIALTY: "",
  });

  const getProfileData = useCallback(() => {
    axios.get("/mypage/profile").then((res) => {
      if (res.status === 200) {
        console.log(res.data);
        setMyData(res.data);
      }
    });
  }, []);

  useEffect(() => {
    if (init) {
      getProfileData();
      setInit(false);
    }
  }, [getProfileData, init]);

  const saveProfile = () => {
    axios
      .post("/mypage/profile/update", myData)
      .then((res) => {
        if (res.status === 200) {
          const obj = {
            title: "Notice",
            content: "Member information has been modified.",
            submitEventHandler: () => {
              dispatch(closeAlert());
            },
          };
          dispatch(openAlert(obj));
          setInit(true);
        }
      })
      .catch((err) => {
        const obj = {
          title: "Notice",
          content: "The member information failed to modify.",
          submitEventHandler: () => {
            dispatch(closeAlert());
          },
        };
        dispatch(openAlert(obj));
      });
  };

  return (
    <div className={style.myInfo_wrapper}>
      <div className={style.myInfo_container}>
        {/* Page Title */}
        <div className={style.myInfo_title}>Edit My Information</div>
        {/* Input Content */}
        <div className={style.myInfo_content}>
          <InfoInput
            title="First Name"
            data={myData.FIRST_NM}
            setValue={(e) => setMyData({ ...myData, FIRST_NM: e.target.value })}
          />
          <InfoInput
            title="Last Name"
            data={myData.LAST_NM}
            setValue={(e) => setMyData({ ...myData, LAST_NM: e.target.value })}
          />
          <InfoInput
            title="Email"
            data={myData.EMAIL}
            setValue={(e) => setMyData({ ...myData, EMAIL: e.target.value })}
          />
          <NumberInput
            title="Age"
            value={myData.AGE}
            setValue={(res) => setMyData({ ...myData, AGE: res })}
          />
          <SelectInput
            title="Gender"
            list={genderList}
            value={myData.GENDER}
            setValue={(res) => setMyData({ ...myData, GENDER: res })}
          />
          <SelectInput
            title="Country"
            list={countryList}
            value={myData.COUNTRY}
            setValue={(res) => setMyData({ ...myData, COUNTRY: res })}
          />
          <SelectInput
            title="Specialty"
            list={specialtyList}
            value={myData.SPECIALTY}
            setValue={(res) => setMyData({ ...myData, SPECIALTY: res })}
          />
          <div className={style.myInfo_btn_area}>
            <Button className={style.myInfo_btn} onClick={saveProfile}>
              Update Profile
            </Button>
            <div
              className={style.myInfo_input_title}
              style={{ marginTop: "15px" }}
            >
              <div className={style.orange_dot}></div>
              <div className={style.input_title}>Required</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Information;

const genderList = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
];
const specialtyList = [
  { label: "Anatomist", value: "Anatomist" },
  { label: "Anesthesiologist", value: "Anesthesiologist" },
  { label: "Cardiologist", value: "Cardiologist" },
  { label: "Cosmetic Surgeon", value: "Cosmetic Surgeon" },
  { label: "Dermatologist", value: "Dermatologist" },
  { label: "Endocrinologist", value: "Endocrinologist" },
  { label: "Facial Plastic Surgeon", value: "Facial Plastic Surgeon" },
  { label: "Gynecologist", value: "Gynecologist" },
  { label: "Internal medicine", value: "Internal medicine" },
  { label: "Journalist medical", value: "Journalist medical" },
  { label: "MD", value: "MD" },
  { label: "Mesotherapist", value: "Mesotherapist" },
  { label: "Neurologist", value: "Neurologist" },
  { label: "Nutritionist", value: "Nutritionist" },
  { label: "Oculoplastic Surgeon", value: "Oculoplastic Surgeon" },
  { label: "Oncologist", value: "Oncologist" },
  { label: "Ophthalmologist", value: "Ophthalmologist" },
  { label: "Orthopedist", value: "Orthopedist" },
  { label: "Otorhinolaryngologist", value: "Otorhinolaryngologist" },
  { label: "Pediatrician", value: "Pediatrician" },
  { label: "Phlebologist", value: "Phlebologist" },
  { label: "Plastic Surgeon", value: "Plastic Surgeon" },
  { label: "Psychiatrist", value: "Psychiatrist" },
  { label: "Radiologist", value: "Radiologist" },
  { label: "Rheumatologist", value: "Rheumatologist" },
  { label: "Stomatologist", value: "Stomatologist" },
  { label: "Urologist", value: "Urologist" },
  { label: "Beautician", value: "Beautician" },
  { label: "Biomedical engineers", value: "Biomedical engineers" },
  { label: "Dental Surgeon", value: "Dental Surgeon" },
  { label: "Dietetician", value: "Dietetician" },
  { label: "Kinesiotherapist", value: "Kinesiotherapist" },
  { label: "Medical office staff", value: "Medical office staff" },
  { label: "Nurse Practitioner", value: "Nurse Practitioner" },
  { label: "Pharmacist", value: "Pharmacist" },
  { label: "Pharmacologist", value: "Pharmacologist" },
  { label: "PhD - Research", value: "PhD - Research" },
  { label: "Physician Assistant", value: "Physician Assistant" },
  { label: "Psychologist", value: "Psychologist" },
  { label: "Registered Nurse", value: "Registered Nurse" },
  { label: "Chartered Accountant", value: "Chartered Accountant" },
  { label: "Corporate finance", value: "Corporate finance" },
  { label: "Event organizer", value: "Event organizer" },
  { label: "Industry Representative", value: "Industry Representative" },
  {
    label: "Journalist economy / finance",
    value: "Journalist economy / finance",
  },
  { label: "Journalist mass audience", value: "Journalist mass audience" },
  { label: "Lawyer", value: "Lawyer" },
  { label: "Marketing consultant", value: "Marketing consultant" },
  { label: "Press officer", value: "Press officer" },
  { label: "Translator", value: "Translator" },
  { label: "Travel agent", value: "Travel agent" },
  { label: "Not specified", value: "Not specified" },
];
const countryList = [
  { label: " Afghanistan", value: "Afghanistan" },
  { label: " Albania", value: "Albania" },
  { label: " Algeria", value: "Algeria" },
  { label: " American Samoa", value: "American Samoa" },
  { label: " Andorra", value: "Andorra" },
  { label: " Angola", value: "Angola" },
  { label: " Anguilla", value: "Anguilla" },
  { label: " Antarctica", value: "Antarctica" },
  { label: " Antigua and Barbuda", value: "Antigua and Barbuda" },
  { label: " Argentina", value: "Argentina" },
  { label: " Armenia", value: "Armenia" },
  { label: " Aruba", value: "Aruba" },
  { label: " Australia", value: "Australia" },
  { label: " Austria", value: "Austria" },
  { label: " Azerbaijan", value: "Azerbaijan" },
  { label: " Bahamas", value: "Bahamas" },
  { label: " Bahrain", value: "Bahrain" },
  { label: " Bangladesh", value: "Bangladesh" },
  { label: " Barbados", value: "Barbados" },
  { label: " Belarus", value: "Belarus" },
  { label: " Belgium", value: "Belgium" },
  { label: " Belize", value: "Belize" },
  { label: " Benin", value: "Benin" },
  { label: " Bermuda", value: "Bermuda" },
  { label: " Bhutan", value: "Bhutan" },
  { label: " Bolivia", value: "Bolivia" },
  {
    label: " Bonaire, Sint Eustatius and Saba",
    value: "Bonaire, Sint Eustatius and Saba",
  },
  { label: " Bosnia and Herzegovina", value: "Bosnia and Herzegovina" },
  { label: " Botswana", value: "Botswana" },
  { label: " Brazil", value: "Brazil" },
  { label: " British Virgin Islands", value: "British Virgin Islands" },
  { label: " Brunei", value: "Brunei" },
  { label: " Bulgaria", value: "Bulgaria" },
  { label: " Burkina Faso", value: "Burkina Faso" },
  { label: " Burundi", value: "Burundi" },
  { label: " Cambodia", value: "Cambodia" },
  { label: " Cameroon", value: "Cameroon" },
  { label: " Canada", value: "Canada" },
  { label: " Cape Verde", value: "Cape Verde" },
  { label: " Cayman Islands", value: "Cayman Islands" },
  { label: " Central African Republic", value: "Central African Republic" },
  { label: " Chad", value: "Chad" },
  { label: " Chile", value: "Chile" },
  { label: " China", value: "China" },
  { label: " Christmas Island", value: "Christmas Island" },
  { label: " Cocos(Keeling) Islands", value: "Cocos(Keeling) Islands" },
  { label: " Colombia", value: "Colombia" },
  { label: " Comoros", value: "Comoros" },
  {
    label: " Congo(Democratic Republic of the)",
    value: "Congo(Democratic Republic of the)",
  },
  { label: " Congo(Republic of the)", value: "Congo(Republic of the)" },
  { label: " Cook Islands", value: "Cook Islands" },
  { label: " Costa Rica", value: "Costa Rica" },
  { label: " Croatia", value: "Croatia" },
  { label: " Cuba", value: "Cuba" },
  { label: " Curaçao", value: "Curaçao" },
  { label: " Cyprus", value: "Cyprus" },
  { label: " Czech Republic", value: "Czech Republic" },
  { label: " Côte d'Ivoire, value=Côte d'Ivoire" },
  { label: " Denmark", value: "Denmark" },
  { label: " Djibouti", value: "Djibouti" },
  { label: " Dominica", value: "Dominica" },
  { label: " Dominican Republic", value: "Dominican Republic" },
  { label: " Ecuador", value: "Ecuador" },
  { label: " Egypt", value: "Egypt" },
  { label: " El Salvador", value: "El Salvador" },
  { label: " Equatorial Guinea", value: "Equatorial Guinea" },
  { label: " Eritrea", value: "Eritrea" },
  { label: " Estonia", value: "Estonia" },
  { label: " Ethiopia", value: "Ethiopia" },
  { label: " Falkland Islands", value: "Falkland Islands" },
  { label: " Faroe Islands", value: "Faroe Islands" },
  { label: " Fiji", value: "Fiji" },
  { label: " Finland", value: "Finland" },
  { label: " France", value: "France" },
  { label: " French Guiana", value: "French Guiana" },
  { label: " French Polynesia", value: "French Polynesia" },
  { label: " Gabon", value: "Gabon" },
  { label: " Gambia", value: "Gambia" },
  { label: " Georgia", value: "Georgia" },
  { label: " Germany", value: "Germany" },
  { label: " Ghana", value: "Ghana" },
  { label: " Gibraltar", value: "Gibraltar" },
  { label: " Greece", value: "Greece" },
  { label: " Greenland", value: "Greenland" },
  { label: " Grenada", value: "Grenada" },
  { label: " Guadeloupe", value: "Guadeloupe" },
  { label: " Guam", value: "Guam" },
  { label: " Guatemala", value: "Guatemala" },
  { label: " Guernsey", value: "Guernsey" },
  { label: " Guinea", value: "Guinea" },
  { label: " Guinea - Bissau", value: "Guinea - Bissau" },
  { label: " Guyana", value: "Guyana" },
  { label: " Haiti", value: "Haiti" },
  { label: " Honduras", value: "Honduras" },
  { label: " Hong Kong SAR China", value: "Hong Kong SAR China" },
  { label: " Hungary", value: "Hungary" },
  { label: " Iceland", value: "Iceland" },
  { label: " India", value: "India" },
  { label: " Indonesia", value: "Indonesia" },
  { label: " Iran", value: "Iran" },
  { label: " Iraq", value: "Iraq" },
  { label: " Ireland", value: "Ireland" },
  { label: " Isle of Man", value: "Isle of Man" },
  { label: " Israel", value: "Israel" },
  { label: " Italy", value: "Italy" },
  { label: " Jamaica", value: "Jamaica" },
  { label: " Japan", value: "Japan" },
  { label: " Jersey", value: "Jersey" },
  { label: " Jordan", value: "Jordan" },
  { label: " Kazakhstan", value: "Kazakhstan" },
  { label: " Kenya", value: "Kenya" },
  { label: " Kiribati", value: "Kiribati" },
  { label: " Kosovo", value: "Kosovo" },
  { label: " Kuwait", value: "Kuwait" },
  { label: " Kyrgyzstan", value: "Kyrgyzstan" },
  { label: " Laos", value: "Laos" },
  { label: " Latvia", value: "Latvia" },
  { label: " Lebanon", value: "Lebanon" },
  { label: " Lesotho", value: "Lesotho" },
  { label: " Liberia", value: "Liberia" },
  { label: " Libya", value: "Libya" },
  { label: " Liechtenstein", value: "Liechtenstein" },
  { label: " Lithuania", value: "Lithuania" },
  { label: " Luxembourg", value: "Luxembourg" },
  { label: " Macau SAR China", value: "Macau SAR China" },
  { label: " Macedonia", value: "Macedonia" },
  { label: " Madagascar", value: "Madagascar" },
  { label: " Malawi", value: "Malawi" },
  { label: " Malaysia", value: "Malaysia" },
  { label: " Maldives", value: "Maldives" },
  { label: " Mali", value: "Mali" },
  { label: " Malta", value: "Malta" },
  { label: " Marshall Islands", value: "Marshall Islands" },
  { label: " Martinique", value: "Martinique" },
  { label: " Mauritania", value: "Mauritania" },
  { label: " Mauritius", value: "Mauritius" },
  { label: " Mayotte", value: "Mayotte" },
  { label: " Mexico", value: "Mexico" },
  { label: " Micronesia", value: "Micronesia" },
  { label: " Moldova", value: "Moldova" },
  { label: " Monaco", value: "Monaco" },
  { label: " Mongolia", value: "Mongolia" },
  { label: " Montenegro", value: "Montenegro" },
  { label: " Montserrat", value: "Montserrat" },
  { label: " Morocco", value: "Morocco" },
  { label: " Mozambique", value: "Mozambique" },
  { label: " Myanmar", value: "Myanmar" },
  { label: " Namibia", value: "Namibia" },
  { label: " Nauru", value: "Nauru" },
  { label: " Nepal", value: "Nepal" },
  { label: " Netherlands", value: "Netherlands" },
  { label: " New Caledonia", value: "New Caledonia" },
  { label: " New Zealand", value: "New Zealand" },
  { label: " Nicaragua", value: "Nicaragua" },
  { label: " Niger", value: "Niger" },
  { label: " Nigeria", value: "Nigeria" },
  { label: " Niue", value: "Niue" },
  { label: " Norfolk Island", value: "Norfolk Island" },
  { label: " North Korea", value: "North Korea" },
  { label: " Northern Mariana Islands", value: "Northern Mariana Islands" },
  { label: " Norway", value: "Norway" },
  { label: " Oman", value: "Oman" },
  { label: " Pakistan", value: "Pakistan" },
  { label: " Palau", value: "Palau" },
  { label: " Palestine", value: "Palestine" },
  { label: " Panama", value: "Panama" },
  { label: " Papua New Guinea", value: "Papua New Guinea" },
  { label: " Paraguay", value: "Paraguay" },
  { label: " Peru", value: "Peru" },
  { label: " Philippines", value: "Philippines" },
  { label: " Poland", value: "Poland" },
  { label: " Portugal", value: "Portugal" },
  { label: " Puerto Rico", value: "Puerto Rico" },
  { label: " Qatar", value: "Qatar" },
  { label: " Romania", value: "Romania" },
  { label: " Russia", value: "Russia" },
  { label: " Rwanda", value: "Rwanda" },
  { label: " Réunion", value: "Réunion" },
  { label: " Saint Barthélemy", value: "Saint Barthélemy" },
  { label: " Saint Kitts and Nevis", value: "Saint Kitts and Nevis" },
  { label: " Saint Lucia", value: "Saint Lucia" },
  { label: " Saint Martin", value: "Saint Martin" },
  { label: " Saint Pierre and Miquelon", value: "Saint Pierre and Miquelon" },
  {
    label: " Saint Vincent and the Grenadines",
    value: "Saint Vincent and the Grenadines",
  },
  { label: " Samoa", value: "Samoa" },
  { label: " San Marino", value: "San Marino" },
  {
    label: " Santa Helena, Ascension and Tristan de Cuhna",
    value: "Santa Helena, Ascension and Tristan de Cuhna",
  },
  { label: " Saudi Arabia", value: "Saudi Arabia" },
  { label: " Senegal", value: "Senegal" },
  { label: " Serbia", value: "Serbia" },
  { label: " Seychelles", value: "Seychelles" },
  { label: " Sierra Leone", value: "Sierra Leone" },
  { label: " Singapore", value: "Singapore" },
  { label: " Sint Maarten", value: "Sint Maarten" },
  { label: " Slovakia", value: "Slovakia" },
  { label: " Slovenia", value: "Slovenia" },
  { label: " Solomon Islands", value: "Solomon Islands" },
  { label: " Somalia", value: "Somalia" },
  { label: " South Africa", value: "South Africa" },
  { label: " South Korea", value: "South Korea" },
  { label: " South Sudan", value: "South Sudan" },
  { label: " Spain", value: "Spain" },
  { label: " Sri Lanka", value: "Sri Lanka" },
  { label: " Sudan", value: "Sudan" },
  { label: " Suriname", value: "Suriname" },
  { label: " Svalbard and Jan Mayen", value: "Svalbard and Jan Mayen" },
  { label: " Swaziland", value: "Swaziland" },
  { label: " Sweden", value: "Sweden" },
  { label: " Switzerland", value: "Switzerland" },
  { label: " Syria", value: "Syria" },
  { label: " São Tomé and Príncipe", value: "São Tomé and Príncipe" },
  { label: " Taiwan", value: "Taiwan" },
  { label: " Tajikistan", value: "Tajikistan" },
  { label: " Tanzania", value: "Tanzania" },
  { label: " Thailand", value: "Thailand" },
  { label: " Timor - Leste", value: "Timor - Leste" },
  { label: " Togo", value: "Togo" },
  { label: " Tonga", value: "Tonga" },
  { label: " Trinidad and Tobago", value: "Trinidad and Tobago" },
  { label: " Tunisia", value: "Tunisia" },
  { label: " Turkey", value: "Turkey" },
  { label: " Turkmenistan", value: "Turkmenistan" },
  { label: " Turks and Caicos Islands", value: "Turks and Caicos Islands" },
  { label: " Tuvalu", value: "Tuvalu" },
  { label: " U.S.Virgin Islands", value: "U.S.Virgin Islands" },
  { label: " Uganda", value: "Uganda" },
  { label: " Ukraine", value: "Ukraine" },
  { label: " United Arab Emirates", value: "United Arab Emirates" },
  { label: " United Kingdom", value: "United Kingdom" },
  { label: " United States", value: "United States" },
  { label: " Uruguay", value: "Uruguay" },
  { label: " Uzbekistan", value: "Uzbekistan" },
  { label: " Vanuatu", value: "Vanuatu" },
  { label: " Venezuela", value: "Venezuela" },
  { label: " Vietnam", value: "Vietnam" },
  { label: " Wallis and Futuna", value: "Wallis and Futuna" },
  { label: " Western Sahara", value: "Western Sahara" },
  { label: " Yemen", value: "Yemen" },
  { label: " Zambia", value: "Zambia" },
  { label: " Zimbabwe", value: "Zimbabwe" },
  { label: " Åland Islands", value: "Åland Islands" },
];
