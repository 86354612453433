import React from "react";
import style from "css/template.module.css";

/**
 * Version : 1.0
 * 작성일자 : 2023-12-11
 * 작성자 : 권도훈
 * 설명 : 공통 템플릿 영역
 */
function Wrapper({ children }) {
  return <article className={style.wrap}>{children}</article>;
}

export default Wrapper;
