import React from "react";
import PropTypes from "prop-types";
import style from "css/account.module.css";
import { Select } from "antd";

function SelectBox(props) {
  return (
    <div className={style.input_container}>
      <Select
        showSearch
        className={
          props.error
            ? `${style.select} ${style.error}`
            : props.value === ""
            ? `${style.select} ${style.placeholder}`
            : style.select
        }
        style={{ width: "100%", height: "100%" }}
        placeholder={props.placeholder}
        optionFilterProp="children"
        filterOption={(input, option) =>
          (option?.label.toLowerCase() ?? "").includes(input.toLowerCase())
        }
        filterSort={(optionA, optionB) =>
          (optionA?.label ?? "")
            .toLowerCase()
            .localeCompare((optionB?.label ?? "").toLowerCase())
        }
        options={props.options}
        onSelect={(res) => props.onChangeEvent(res)}
      />
    </div>
  );
}

Select.propTypes = {
  error: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  onChangeEvent: PropTypes.func,
  disabled: PropTypes.bool,
  options: PropTypes.array,
};

export default SelectBox;
