import React, { useState } from "react";
import style from "css/newsletter.module.css";
import { useMediaQuery } from "react-responsive";
import { useDispatch } from "react-redux";
import { openAlert } from "redux/slices/alertSlice";
import { Skeleton } from "antd";
import axios from "axios";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";

function NewsletterListItem(props) {
  const cookies = new Cookies();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const isTablet = useMediaQuery({
    query: "(min-width: 768px) and (max-width: 1023px)",
  });
  const [favorite, setFavorite] = useState(
    props.data.FAVORITE === "Y" ? true : false
  );
  const favoriteHandler = () => {
    if (cookies.get("AuthToken") === undefined) {
      // alert("This service requires login. Please login first.");
      dispatch(
        openAlert({
          title: "Notice",
          content: "This service requires login. Please login first.",
          submitEventHandler: () =>
            navigate(process.env.REACT_APP_CLIENT_URL + "signin", { state: { returnPage: true } }),
        })
      );
      return;
    }

    const param = {
      params: {
        NEWSLETTER_ID: props.data.NEWSLETTER_ID,
        FAVORITE_YN: favorite ? "N" : "Y",
      },
    };
    axios
      .get("/newsletter/favorite", param)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.result === "success") {
            setFavorite(!favorite);
          }
        }
      })
      .catch(() => { })
      .finally(() => { });
  };

  return (
    <div
      className={style.newsletter_item}
      onClick={() => props.click(props.data.NEWSLETTER_ID)}
    >
      <div className={style.newsletter_item_img_wrapper}>
        {props.data.THUMBNAIL_IMG === "" ? (
          <Skeleton.Image
            active
            style={
              isMobile
                ? { width: "100%", height: "236px" }
                : isTablet
                  ? { width: "294px", height: "206px" }
                  : { width: "338px", height: "237px" }
            }
          />
        ) : (
          <img
            className={`${style.newsletter_item_img} cursor`}
            alt=""
            src={props.data.THUMBNAIL_IMG}
          />
        )}
        <div
          className={style.newsletter_item_like}
          onClick={(e) => {
            e.stopPropagation();
            favoriteHandler();
          }}
        >
          <img
            alt=""
            src={
              favorite
                ? process.env.REACT_APP_CLIENT_URL +
                "img/library/filled_heart.png"
                : process.env.REACT_APP_CLIENT_URL +
                "img/library/empty_heart.png"
            }
          />
        </div>
      </div>
      <div className={style.newsletter_item_title}>{props.data.TITLE}</div>
      <div className={style.newsletter_item_explain}>
        {props.data.DESCRIPTION}
      </div>
    </div>
  );
}

export default NewsletterListItem;
