import React, { useState } from "react";
import style from "css/mypage.module.css";
import Info from "components/myPage/info";
import { useSelector } from "react-redux";
import Favorite from "components/myPage/favorite";
import Password from "components/myPage/password";
import Information from "components/myPage/information";
import { Cookies } from "react-cookie";
// import { useNavigate } from "react-router-dom";
function MyPage() {
  const cookies = new Cookies();
  const userPayload = useSelector((state) => state.user);
  const [type, setType] = useState("favorite");
  // const navigate = useNavigate();
  const logout = () => {
    const authNm = process.env.REACT_APP_AUTH_TOKEN;
    const refreshNm = process.env.REACT_APP_REFRESH_TOKEN;
    const domain = process.env.REACT_APP_DOMAIN;
    cookies.remove(authNm, { path: "/", domain: domain });
    cookies.remove(refreshNm, { path: "/", domain: domain });
    window.location.href = process.env.REACT_APP_CLIENT_URL;
  };
  const render = () => {
    switch (type) {
      case "favorite":
        return <Favorite />;
      case "information":
        return <Information />;
      case "password":
        return <Password />;
      case "logout":
        logout();
        break;
      default:
        return <></>;
    }
  };
  return (
    <div className={style.mypage}>
      <Info name={userPayload.name} type={type} setType={setType} />
      {render()}
    </div>
  );
}

export default MyPage;
