import React, { useState, useEffect, useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import NewsletterList from "components/newsletter/newsletterList";
import NewsletterDetail from "components/newsletter/newsletterDetail";
import Loading from "components/common/loading";
import axios from "axios";

function Newsletter() {
  const [searchParams, setSearchParam] = useSearchParams();
  const newsletterId = searchParams.get("id");
  const [newsletterTotal, setNewsletterTotal] = useState(0);
  const [newsletterList, setNewsletterList] = useState([]);
  const [loading, setLoading] = useState(true);

  const getNewsletterList = useCallback(() => {
    setLoading(true);
    const url = "/newsletter/list";
    const param = {
      params: {},
    };

    axios
      .get(url, param)
      .then((res) => {
        if (res.status === 200) {
          setNewsletterTotal(res.data.total);
          setNewsletterList(res.data.list);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    getNewsletterList();
  }, [getNewsletterList, newsletterId]);

  return (
    <>
      {loading ? (
        <Loading />
      ) : newsletterId === null ? (
        <NewsletterList
          newsletterTotal={newsletterTotal}
          newsletterList={newsletterList}
        />
      ) : (
        <NewsletterDetail
          newsletterTotal={newsletterTotal}
          newsletter={
            newsletterId === null
              ? {}
              : newsletterList.find(
                  (item) => item.NEWSLETTER_ID.toString() === newsletterId
                )
          }
          newsletterId={newsletterId}
        />
      )}
    </>
  );
}

export default Newsletter;
