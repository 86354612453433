import React, { useState } from "react";
import style from "css/contact/contact.module.css";
import axios from "axios";
import { closeAlert, openAlert } from "redux/slices/alertSlice";
import { useDispatch } from "react-redux";
import { Select } from "antd";
import { useMediaQuery } from "react-responsive";

function Contact() {
  const isMobile = useMediaQuery({ query: "(max-width: 924px)" });
  const isTablet = useMediaQuery({
    query: "(min-width: 925px) and (max-width: 1383px)",
  });

  const youtubeUrl = "https://www.youtube.com/@jetema1834";
  const instagramUrl = "https://www.instagram.com/eptq_official/";
  const jetemaUrl = "https://www.jetema.com/";
  const eptqUrl = "https://epitiquefiller.com/";

  const [info, setinfo] = useState({
    Country: {
      title: "Country",
      value: "",
      require: true,
      error: false,
      msg: "",
    },
    Name: "",
    Phone: "",
    Email: "",
    Message: "",
  });
  window.scrollTo(0, 0);

  const dispatch = useDispatch();

  const sendInfo = () => {
    const data = info;

    if (info.Country.value === "") {
      const obj = {
        title: "Notice",
        content: "Input Country",
        submitEventHandler: () => {
          dispatch(closeAlert());
        },
      };
      dispatch(openAlert(obj));
    } else if (info.Name === "") {
      const obj = {
        title: "Notice",
        content: "Input Name",
        submitEventHandler: () => {
          dispatch(closeAlert());
        },
      };
      dispatch(openAlert(obj));
    } else if (info.Phone === "") {
      const obj = {
        title: "Notice",
        content: "Input Phone",
        submitEventHandler: () => {
          dispatch(closeAlert());
        },
      };
      dispatch(openAlert(obj));
    } else if (info.Email === "") {
      const obj = {
        title: "Notice",
        content: "Input Email",
        submitEventHandler: () => {
          dispatch(closeAlert());
        },
      };
      dispatch(openAlert(obj));
    } else if (info.Message === "") {
      const obj = {
        title: "Notice",
        content: "Input Message",
        submitEventHandler: () => {
          dispatch(closeAlert());
        },
      };
      dispatch(openAlert(obj));
    } else {
      axios.post("/contact/contact", data).then((res) => {
        if (res.status === 200) {
          const obj = {
            title: "Notice",
            content: "Message sent completed",
            submitEventHandler: () => {
              dispatch(closeAlert());
            },
          };
          dispatch(openAlert(obj));
          setinfo({
            Country: {
              title: "Country",
              value: info.Country.value,
              require: true,
              error: false,
              msg: "",
            },
            Name: "",
            Phone: "",
            Email: "",
            Message: "",
          });
        }
      });
    }
  };
  return (
    <div className={style.main_wrapper}>
      <div className={style.title}>Contact Us</div>
      <div className={style.content}>
        <div className={style.contents}>
          <div className={style.map}>
            {/* <iframe
              title="1324"
              width="100%"
              height="100%"
              src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=225-12,%20Pangyoyeok-ro,%20Bundang-gu,%20Seongnam-si,%20Gyeonggi-do,%20Republic%20of%20Korea+()&amp;t=&amp;z=18&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
            >
              <a href="https://www.maps.ie/population/">
                Calculate population in area
              </a>
            </iframe> */}

            <div className={style.sns_title_wrap}>
              <div className={style.sns_title}>STAY IN TOUCH</div>
              <div className={style.sns_description}>
                Visit our social networks and follow us for the latest news!
              </div>
            </div>
            <div className={style.sns_list}>
              <div
                className={`${style.sns_item} cursor`}
                onClick={() => window.open(youtubeUrl)}
              >
                <div className={style.sns_item_icon_wrapper}>
                  <img
                    alt=""
                    src={
                      process.env.REACT_APP_CLIENT_URL +
                      "img/landing/youtube.png"
                    }
                    style={{
                      width: isMobile ? "22px" : isTablet ? "21px" : "34px",
                    }}
                  />
                </div>
                <div className={style.sns_item_title}>Youtube</div>
              </div>
              <div
                className={`${style.sns_item} cursor`}
                onClick={() => window.open(instagramUrl)}
              >
                <div className={style.sns_item_icon_wrapper}>
                  <img
                    alt=""
                    src={
                      process.env.REACT_APP_CLIENT_URL +
                      "img/landing/instagram.png"
                    }
                    style={{
                      width: isMobile ? "20px" : isTablet ? "19px" : "33px",
                    }}
                  />
                </div>
                <div className={style.sns_item_title}>Instagram</div>
              </div>
              <div
                className={`${style.sns_item} cursor`}
                onClick={() => window.open(jetemaUrl)}
              >
                <div className={style.sns_item_icon_wrapper}>
                  <img
                    alt=""
                    src={
                      process.env.REACT_APP_CLIENT_URL +
                      "img/landing/jetema.png"
                    }
                    style={{
                      width: isMobile ? "18px" : isTablet ? "17px" : "29px",
                    }}
                  />
                </div>
                <div className={style.sns_item_title}>Jetema</div>
              </div>
              <div
                className={`${style.sns_item} cursor`}
                onClick={() => window.open(eptqUrl)}
              >
                <div className={style.sns_item_icon_wrapper}>
                  <img
                    alt=""
                    src={
                      process.env.REACT_APP_CLIENT_URL + "img/landing/eptq.png"
                    }
                    style={{
                      width: isMobile ? "37px" : isTablet ? "36px" : "52px",
                    }}
                  />
                </div>
                <div className={style.sns_item_title}>e.p.t.q.</div>
              </div>
            </div>
          </div>
          <div className={style.info}>
            <div className={style.header_1}>Jetema Global</div>
            <div className={style.header_2}>
              <div className={style.header_2_line}></div>
              <div className={style.header_2_text}>
                For inquiries or to leave feedback, please use the form below
              </div>
            </div>
            <div className={style.info_body}>
              <div className={style.contents_line}>
                <div className={style.input_div}>
                  <div className={style.input_header}>
                    <div className={style.input_icon}></div> Country
                  </div>
                  <div className={style.input_box}>
                    {info.Country.value.length > 0 ? (
                      <></>
                    ) : (
                      <img
                        className={style.input_img}
                        alt=""
                        src={
                          process.env.REACT_APP_CLIENT_URL +
                          "img/ContactUs/Country.png"
                        }
                      />
                    )}

                    {/* <input
                      type="text"
                      id="Country"
                      className={style.Country}
                      value={info.Country}
                      onChange={(e) =>
                        setinfo({ ...info, Country: e.target.value })
                      }
                      placeholder="Country"
                    /> */}
                    <Select
                      showSearch
                      className={
                        info.Country.error
                          ? `${style.select} ${style.error}`
                          : info.Country.value === ""
                          ? `${style.select} ${style.placeholder}`
                          : style.select
                      }
                      style={{ width: "100%", height: "100%" }}
                      placeholder="Country"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label.toLowerCase() ?? "").includes(
                          input.toLowerCase()
                        )
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? "").toLowerCase())
                      }
                      options={countryList}
                      onSelect={(res) => {
                        if (info.Country.error && res !== info.Country.value) {
                          setinfo({
                            ...info,
                            Country: {
                              ...info.Country,
                              value: res,
                              error: false,
                              msg: "",
                            },
                          });
                        } else {
                          setinfo({
                            ...info,
                            Country: {
                              ...info.Country,
                              value: res,
                            },
                          });
                        }
                      }}
                    />
                  </div>
                </div>
                <div className={style.input_div}>
                  <div className={style.input_header}>
                    <div className={style.input_icon}></div> Name
                  </div>
                  <div className={style.input_box}>
                    {info.Name.length > 0 ? (
                      <></>
                    ) : (
                      <img
                        className={style.input_img}
                        alt=""
                        src={
                          process.env.REACT_APP_CLIENT_URL +
                          "img/ContactUs/Name.png"
                        }
                      />
                    )}
                    <input
                      type="text"
                      id="Name"
                      className={style.Name}
                      value={info.Name}
                      onChange={(e) =>
                        setinfo({ ...info, Name: e.target.value })
                      }
                      placeholder="Name"
                    />
                  </div>
                </div>
              </div>
              <div className={style.contents_line}>
                <div className={style.input_div}>
                  <div className={style.input_header}>
                    <div className={style.input_icon}></div> Phone
                  </div>
                  <div className={style.input_box}>
                    {info.Phone.length > 0 ? (
                      <></>
                    ) : (
                      <img
                        className={style.input_img}
                        alt=""
                        src={
                          process.env.REACT_APP_CLIENT_URL +
                          "img/ContactUs/Phone.png"
                        }
                      />
                    )}

                    <input
                      type="text"
                      id="Phone"
                      className={style.Phone}
                      value={info.Phone}
                      onChange={(e) =>
                        setinfo({ ...info, Phone: e.target.value })
                      }
                      placeholder="Phone"
                    />
                  </div>
                </div>
                <div className={style.input_div}>
                  <div className={style.input_header}>
                    <div className={style.input_icon}></div> Email
                  </div>
                  <div className={style.input_box}>
                    {info.Email.length > 0 ? (
                      <></>
                    ) : (
                      <img
                        className={style.input_img}
                        alt=""
                        src={
                          process.env.REACT_APP_CLIENT_URL +
                          "img/ContactUs/Email.png"
                        }
                      />
                    )}
                    <input
                      type="text"
                      id="Email"
                      className={style.Email}
                      value={info.Email}
                      onChange={(e) =>
                        setinfo({ ...info, Email: e.target.value })
                      }
                      placeholder="Email"
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className={style.input_header}>
                  <div className={style.input_icon}></div> Message
                </div>
                <div className={style.input_box2}>
                  {info.Message.length > 0 ? (
                    <></>
                  ) : (
                    <img
                      className={style.input_img_2}
                      alt=""
                      src={
                        process.env.REACT_APP_CLIENT_URL +
                        "img/ContactUs/Message.png"
                      }
                    />
                  )}
                  <textarea
                    id="Message"
                    className={style.Message}
                    value={info.Message}
                    onChange={(e) =>
                      setinfo({ ...info, Message: e.target.value })
                    }
                    placeholder="Message"
                  />
                </div>
              </div>
            </div>
            <div className={style.button} onClick={() => sendInfo()}>
              Send
            </div>
          </div>
        </div>
        <div className={style.info2}>
          <div
            className={style.info2_1}
            onClick={() => {
              window.open(
                "https://www.google.com/maps?ll=37.400878,127.108074&z=18&t=m&hl=en&gl=US&mapclient=embed&q=225-12+Pangyoyeok-ro+Bundang-gu,+Seongnam-si,+Gyeonggi-do)"
              );
            }}
          >
            <div className={style.w_circle}>
              <img
                alt=""
                src={
                  process.env.REACT_APP_CLIENT_URL +
                  "img/ContactUs/location.png"
                }
              ></img>
            </div>
            <div className={style.info2_text}>
              225-12, Pangyoyeok-ro, Bundang-gu, Seongnam-si, Gyeonggi-do,
              Republic of Korea (13494)
            </div>
          </div>
          <div className={style.info2_1}>
            <div className={style.w_circle}>
              <img
                alt=""
                src={
                  process.env.REACT_APP_CLIENT_URL + "img/ContactUs/mail.png"
                }
              ></img>
            </div>
            <div className={style.info2_2}>
              <div
                className={style.info2_text2}
                onClick={() => window.open("mailto:jam@jetema.com")}
              >
                jam@jetema.com
              </div>
              {/* <div className={style.info2_text3}></div>
              <div
                className={style.info2_text2}
                onClick={() => window.open("mailto:gacha@jetema.com")}
              >
                gacha@jetema.com
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;

const countryList = [
  { label: " Afghanistan", value: "Afghanistan" },
  { label: " Albania", value: "Albania" },
  { label: " Algeria", value: "Algeria" },
  { label: " American Samoa", value: "American Samoa" },
  { label: " Andorra", value: "Andorra" },
  { label: " Angola", value: "Angola" },
  { label: " Anguilla", value: "Anguilla" },
  { label: " Antarctica", value: "Antarctica" },
  { label: " Antigua and Barbuda", value: "Antigua and Barbuda" },
  { label: " Argentina", value: "Argentina" },
  { label: " Armenia", value: "Armenia" },
  { label: " Aruba", value: "Aruba" },
  { label: " Australia", value: "Australia" },
  { label: " Austria", value: "Austria" },
  { label: " Azerbaijan", value: "Azerbaijan" },
  { label: " Bahamas", value: "Bahamas" },
  { label: " Bahrain", value: "Bahrain" },
  { label: " Bangladesh", value: "Bangladesh" },
  { label: " Barbados", value: "Barbados" },
  { label: " Belarus", value: "Belarus" },
  { label: " Belgium", value: "Belgium" },
  { label: " Belize", value: "Belize" },
  { label: " Benin", value: "Benin" },
  { label: " Bermuda", value: "Bermuda" },
  { label: " Bhutan", value: "Bhutan" },
  { label: " Bolivia", value: "Bolivia" },
  {
    label: " Bonaire, Sint Eustatius and Saba",
    value: "Bonaire, Sint Eustatius and Saba",
  },
  { label: " Bosnia and Herzegovina", value: "Bosnia and Herzegovina" },
  { label: " Botswana", value: "Botswana" },
  { label: " Brazil", value: "Brazil" },
  { label: " British Virgin Islands", value: "British Virgin Islands" },
  { label: " Brunei", value: "Brunei" },
  { label: " Bulgaria", value: "Bulgaria" },
  { label: " Burkina Faso", value: "Burkina Faso" },
  { label: " Burundi", value: "Burundi" },
  { label: " Cambodia", value: "Cambodia" },
  { label: " Cameroon", value: "Cameroon" },
  { label: " Canada", value: "Canada" },
  { label: " Cape Verde", value: "Cape Verde" },
  { label: " Cayman Islands", value: "Cayman Islands" },
  { label: " Central African Republic", value: "Central African Republic" },
  { label: " Chad", value: "Chad" },
  { label: " Chile", value: "Chile" },
  { label: " China", value: "China" },
  { label: " Christmas Island", value: "Christmas Island" },
  { label: " Cocos(Keeling) Islands", value: "Cocos(Keeling) Islands" },
  { label: " Colombia", value: "Colombia" },
  { label: " Comoros", value: "Comoros" },
  {
    label: " Congo(Democratic Republic of the)",
    value: "Congo(Democratic Republic of the)",
  },
  { label: " Congo(Republic of the)", value: "Congo(Republic of the)" },
  { label: " Cook Islands", value: "Cook Islands" },
  { label: " Costa Rica", value: "Costa Rica" },
  { label: " Croatia", value: "Croatia" },
  { label: " Cuba", value: "Cuba" },
  { label: " Curaçao", value: "Curaçao" },
  { label: " Cyprus", value: "Cyprus" },
  { label: " Czech Republic", value: "Czech Republic" },
  { label: " Côte d'Ivoire", value: "Côte d'Ivoire" },
  { label: " Denmark", value: "Denmark" },
  { label: " Djibouti", value: "Djibouti" },
  { label: " Dominica", value: "Dominica" },
  { label: " Dominican Republic", value: "Dominican Republic" },
  { label: " Ecuador", value: "Ecuador" },
  { label: " Egypt", value: "Egypt" },
  { label: " El Salvador", value: "El Salvador" },
  { label: " Equatorial Guinea", value: "Equatorial Guinea" },
  { label: " Eritrea", value: "Eritrea" },
  { label: " Estonia", value: "Estonia" },
  { label: " Ethiopia", value: "Ethiopia" },
  { label: " Falkland Islands", value: "Falkland Islands" },
  { label: " Faroe Islands", value: "Faroe Islands" },
  { label: " Fiji", value: "Fiji" },
  { label: " Finland", value: "Finland" },
  { label: " France", value: "France" },
  { label: " French Guiana", value: "French Guiana" },
  { label: " French Polynesia", value: "French Polynesia" },
  { label: " Gabon", value: "Gabon" },
  { label: " Gambia", value: "Gambia" },
  { label: " Georgia", value: "Georgia" },
  { label: " Germany", value: "Germany" },
  { label: " Ghana", value: "Ghana" },
  { label: " Gibraltar", value: "Gibraltar" },
  { label: " Greece", value: "Greece" },
  { label: " Greenland", value: "Greenland" },
  { label: " Grenada", value: "Grenada" },
  { label: " Guadeloupe", value: "Guadeloupe" },
  { label: " Guam", value: "Guam" },
  { label: " Guatemala", value: "Guatemala" },
  { label: " Guernsey", value: "Guernsey" },
  { label: " Guinea", value: "Guinea" },
  { label: " Guinea - Bissau", value: "Guinea - Bissau" },
  { label: " Guyana", value: "Guyana" },
  { label: " Haiti", value: "Haiti" },
  { label: " Honduras", value: "Honduras" },
  { label: " Hong Kong SAR China", value: "Hong Kong SAR China" },
  { label: " Hungary", value: "Hungary" },
  { label: " Iceland", value: "Iceland" },
  { label: " India", value: "India" },
  { label: " Indonesia", value: "Indonesia" },
  { label: " Iran", value: "Iran" },
  { label: " Iraq", value: "Iraq" },
  { label: " Ireland", value: "Ireland" },
  { label: " Isle of Man", value: "Isle of Man" },
  { label: " Israel", value: "Israel" },
  { label: " Italy", value: "Italy" },
  { label: " Jamaica", value: "Jamaica" },
  { label: " Japan", value: "Japan" },
  { label: " Jersey", value: "Jersey" },
  { label: " Jordan", value: "Jordan" },
  { label: " Kazakhstan", value: "Kazakhstan" },
  { label: " Kenya", value: "Kenya" },
  { label: " Kiribati", value: "Kiribati" },
  { label: " Kosovo", value: "Kosovo" },
  { label: " Kuwait", value: "Kuwait" },
  { label: " Kyrgyzstan", value: "Kyrgyzstan" },
  { label: " Laos", value: "Laos" },
  { label: " Latvia", value: "Latvia" },
  { label: " Lebanon", value: "Lebanon" },
  { label: " Lesotho", value: "Lesotho" },
  { label: " Liberia", value: "Liberia" },
  { label: " Libya", value: "Libya" },
  { label: " Liechtenstein", value: "Liechtenstein" },
  { label: " Lithuania", value: "Lithuania" },
  { label: " Luxembourg", value: "Luxembourg" },
  { label: " Macau SAR China", value: "Macau SAR China" },
  { label: " Macedonia", value: "Macedonia" },
  { label: " Madagascar", value: "Madagascar" },
  { label: " Malawi", value: "Malawi" },
  { label: " Malaysia", value: "Malaysia" },
  { label: " Maldives", value: "Maldives" },
  { label: " Mali", value: "Mali" },
  { label: " Malta", value: "Malta" },
  { label: " Marshall Islands", value: "Marshall Islands" },
  { label: " Martinique", value: "Martinique" },
  { label: " Mauritania", value: "Mauritania" },
  { label: " Mauritius", value: "Mauritius" },
  { label: " Mayotte", value: "Mayotte" },
  { label: " Mexico", value: "Mexico" },
  { label: " Micronesia", value: "Micronesia" },
  { label: " Moldova", value: "Moldova" },
  { label: " Monaco", value: "Monaco" },
  { label: " Mongolia", value: "Mongolia" },
  { label: " Montenegro", value: "Montenegro" },
  { label: " Montserrat", value: "Montserrat" },
  { label: " Morocco", value: "Morocco" },
  { label: " Mozambique", value: "Mozambique" },
  { label: " Myanmar", value: "Myanmar" },
  { label: " Namibia", value: "Namibia" },
  { label: " Nauru", value: "Nauru" },
  { label: " Nepal", value: "Nepal" },
  { label: " Netherlands", value: "Netherlands" },
  { label: " New Caledonia", value: "New Caledonia" },
  { label: " New Zealand", value: "New Zealand" },
  { label: " Nicaragua", value: "Nicaragua" },
  { label: " Niger", value: "Niger" },
  { label: " Nigeria", value: "Nigeria" },
  { label: " Niue", value: "Niue" },
  { label: " Norfolk Island", value: "Norfolk Island" },
  { label: " North Korea", value: "North Korea" },
  { label: " Northern Mariana Islands", value: "Northern Mariana Islands" },
  { label: " Norway", value: "Norway" },
  { label: " Oman", value: "Oman" },
  { label: " Pakistan", value: "Pakistan" },
  { label: " Palau", value: "Palau" },
  { label: " Palestine", value: "Palestine" },
  { label: " Panama", value: "Panama" },
  { label: " Papua New Guinea", value: "Papua New Guinea" },
  { label: " Paraguay", value: "Paraguay" },
  { label: " Peru", value: "Peru" },
  { label: " Philippines", value: "Philippines" },
  { label: " Poland", value: "Poland" },
  { label: " Portugal", value: "Portugal" },
  { label: " Puerto Rico", value: "Puerto Rico" },
  { label: " Qatar", value: "Qatar" },
  { label: " Romania", value: "Romania" },
  { label: " Russia", value: "Russia" },
  { label: " Rwanda", value: "Rwanda" },
  { label: " Réunion", value: "Réunion" },
  { label: " Saint Barthélemy", value: "Saint Barthélemy" },
  { label: " Saint Kitts and Nevis", value: "Saint Kitts and Nevis" },
  { label: " Saint Lucia", value: "Saint Lucia" },
  { label: " Saint Martin", value: "Saint Martin" },
  { label: " Saint Pierre and Miquelon", value: "Saint Pierre and Miquelon" },
  {
    label: " Saint Vincent and the Grenadines",
    value: "Saint Vincent and the Grenadines",
  },
  { label: " Samoa", value: "Samoa" },
  { label: " San Marino", value: "San Marino" },
  {
    label: " Santa Helena, Ascension and Tristan de Cuhna",
    value: "Santa Helena, Ascension and Tristan de Cuhna",
  },
  { label: " Saudi Arabia", value: "Saudi Arabia" },
  { label: " Senegal", value: "Senegal" },
  { label: " Serbia", value: "Serbia" },
  { label: " Seychelles", value: "Seychelles" },
  { label: " Sierra Leone", value: "Sierra Leone" },
  { label: " Singapore", value: "Singapore" },
  { label: " Sint Maarten", value: "Sint Maarten" },
  { label: " Slovakia", value: "Slovakia" },
  { label: " Slovenia", value: "Slovenia" },
  { label: " Solomon Islands", value: "Solomon Islands" },
  { label: " Somalia", value: "Somalia" },
  { label: " South Africa", value: "South Africa" },
  { label: " South Korea", value: "South Korea" },
  { label: " South Sudan", value: "South Sudan" },
  { label: " Spain", value: "Spain" },
  { label: " Sri Lanka", value: "Sri Lanka" },
  { label: " Sudan", value: "Sudan" },
  { label: " Suriname", value: "Suriname" },
  { label: " Svalbard and Jan Mayen", value: "Svalbard and Jan Mayen" },
  { label: " Swaziland", value: "Swaziland" },
  { label: " Sweden", value: "Sweden" },
  { label: " Switzerland", value: "Switzerland" },
  { label: " Syria", value: "Syria" },
  { label: " São Tomé and Príncipe", value: "São Tomé and Príncipe" },
  { label: " Taiwan", value: "Taiwan" },
  { label: " Tajikistan", value: "Tajikistan" },
  { label: " Tanzania", value: "Tanzania" },
  { label: " Thailand", value: "Thailand" },
  { label: " Timor - Leste", value: "Timor - Leste" },
  { label: " Togo", value: "Togo" },
  { label: " Tonga", value: "Tonga" },
  { label: " Trinidad and Tobago", value: "Trinidad and Tobago" },
  { label: " Tunisia", value: "Tunisia" },
  { label: " Turkey", value: "Turkey" },
  { label: " Turkmenistan", value: "Turkmenistan" },
  { label: " Turks and Caicos Islands", value: "Turks and Caicos Islands" },
  { label: " Tuvalu", value: "Tuvalu" },
  { label: " U.S.Virgin Islands", value: "U.S.Virgin Islands" },
  { label: " Uganda", value: "Uganda" },
  { label: " Ukraine", value: "Ukraine" },
  { label: " United Arab Emirates", value: "United Arab Emirates" },
  { label: " United Kingdom", value: "United Kingdom" },
  { label: " United States", value: "United States" },
  { label: " Uruguay", value: "Uruguay" },
  { label: " Uzbekistan", value: "Uzbekistan" },
  { label: " Vanuatu", value: "Vanuatu" },
  { label: " Venezuela", value: "Venezuela" },
  { label: " Vietnam", value: "Vietnam" },
  { label: " Wallis and Futuna", value: "Wallis and Futuna" },
  { label: " Western Sahara", value: "Western Sahara" },
  { label: " Yemen", value: "Yemen" },
  { label: " Zambia", value: "Zambia" },
  { label: " Zimbabwe", value: "Zimbabwe" },
  { label: " Åland Islands", value: "Åland Islands" },
];
