import React from "react";
import style from "css/myInfo.module.css";
import { InputNumber } from "antd";

function NumberInput({ title, value, setValue }) {
  console.log(value);
  return (
    <div className={style.myInfo_input_box}>
      <div className={style.myInfo_input_title}>
        <div className={style.orange_dot}></div>
        <div className={style.input_title}>{title}</div>
      </div>
      <InputNumber
        style={{
          width: "100%",
          height: 50,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        min={1}
        max={100}
        value={value}
        onChange={setValue}
      />
    </div>
  );
}

export default NumberInput;
