import React, { useState, useEffect, useCallback } from "react";
import style from "css/landing.module.css";
import { Image } from "antd";
import { motion } from "framer-motion";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { Cookies } from "react-cookie";
import axios from "axios";

function AboutJam() {
  //cookie
  const cookies = new Cookies();
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const isTablet = useMediaQuery({
    query: "(min-width: 768px) and (max-width: 1023px)",
  });
  const navigate = useNavigate();

  const [registerYn, setRegisterYn] = useState("N");
  // const [registerEvent, setRegisterEvent] = useState({});

  const getRegisterEvent = useCallback(() => {
    const data = {
      params: {},
    };

    axios.get("/event/landing/registerCheck", data).then((res) => {
      if (res.status === 200) {
        setRegisterYn(res.data.REGISTER_YN);
        // setRegisterEvent(res.data.EVENT);
      }
    });
  }, []);

  useEffect(() => {
    getRegisterEvent();
  }, [getRegisterEvent]);

  return (
    <div className={style.about_jam_area}>
      <div className={style.about_jam_preview}>
        <div className={style.about_jam_preview_text_wrap}>
          <motion.div
            className={style.about_jam_preview_label_wrap}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.1 }}
            variants={{
              offscreen: {
                opacity: 0,
                scale: 0.5,
              },
              onscreen: {
                opacity: 1,
                scale: 1,
                transition: {
                  duration: 1,
                  delay: 0.5,
                  ease: [0, 0.71, 0.2, 1.01],
                },
              },
            }}
          >
            <span className={style.about_jam_preview_border} />
            <div className={style.about_jam_preview_label}>About JAM</div>
          </motion.div>
          <motion.div
            className={style.about_jam_preview_title_wrap}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.1 }}
            variants={{
              offscreen: {
                opacity: 0,
                scale: 0.5,
              },
              onscreen: {
                opacity: 1,
                scale: 1,
                transition: {
                  duration: 1,
                  delay: 0.6,
                  ease: [0, 0.71, 0.2, 1.01],
                },
              },
            }}
          >
            <div className={style.about_jam_preview_title}>JAM is</div>
            <div className={style.about_jam_preview_title}>
              more than a meeting
            </div>
          </motion.div>

          <motion.div
            className={style.about_jam_preview_text_item}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.1 }}
            variants={{
              offscreen: {
                opacity: 0,
                scale: 0.5,
              },
              onscreen: {
                opacity: 1,
                scale: 1,
                transition: {
                  duration: 1,
                  delay: 0.7,
                  ease: [0, 0.71, 0.2, 1.01],
                },
              },
            }}
          >
            <div className={style.about_jam_preview_text_item}>
              <div className={style.about_jam_preview_text}>
                <div>It is a community that shares</div>
                <div>
                  the advance of <span>science and growth.</span>
                </div>
              </div>
            </div>
          </motion.div>
        </div>

        <div className={style.about_jam_preview_img_wrap}>
          {
            //11회차 사전등록 전 임시 주석 시작
             registerYn === "Y" ? (
               <motion.div
                 className={style.rotate_item}
                 initial="offscreen"
                 whileInView="onscreen"
                 whileHover={{
                   scale: 1.1,
                   transition: { duration: 1 },
                 }}
                 viewport={{ once: true, amount: 0.1 }}
                 variants={{
                   offscreen: {
                     opacity: 0,
                     scale: 0.5,
                   },
                   onscreen: {
                     opacity: 1,
                     scale: 1,
                     transition: {
                       duration: 1,
                       delay: 1,
                       ease: [0, 0.71, 0.2, 1.01],
                     },
                   },
                 }}
                 // onClick={() =>
                 //   cookies.get("AuthToken") === undefined
                 //     ? navigate(process.env.REACT_APP_CLIENT_URL + "signup")
                 //     : navigate(process.env.REACT_APP_CLIENT_URL + "register")
                 // }
                 onClick={() =>
                   navigate(process.env.REACT_APP_CLIENT_URL + "register")
                 }
               >
                 <img
                   className={style.rotate_img}
                   alt=""
                   src={
                     process.env.REACT_APP_CLIENT_URL +
                     "img/landing/rotate_black.png"
                   }
                 />
                 <img
                   className={style.arrow_img}
                   alt=""
                   src={
                     process.env.REACT_APP_CLIENT_URL +
                     "img/landing/arrow_right_black.png"
                   }
                 />
               </motion.div>
             ) : 
            //11회차 사전등록 전 임시 주석 종료
            cookies.get("AuthToken") === undefined ? (
              //이벤트 등록 X 비로그인 시 Become a member
              <motion.div
                className={style.rotate_item}
                initial="offscreen"
                whileInView="onscreen"
                whileHover={{
                  scale: 1.1,
                  transition: { duration: 0.5, ease: [0, 0.71, 0.2, 1.01] },
                }}
                viewport={{ once: true, amount: 0.1 }}
                variants={{
                  offscreen: {
                    opacity: 0,
                    scale: 0.5,
                  },
                  onscreen: {
                    opacity: 1,
                    scale: 1,
                    transition: {
                      duration: 1,
                      delay: 1,
                      ease: [0, 0.71, 0.2, 1.01],
                    },
                  },
                }}
                // onClick={() =>
                //   cookies.get("AuthToken") === undefined
                //     ? navigate(process.env.REACT_APP_CLIENT_URL + "signup")
                //     : navigate(process.env.REACT_APP_CLIENT_URL + "register")
                // }
                onClick={() =>
                  navigate(process.env.REACT_APP_CLIENT_URL + "signup")
                }
              >
                <img
                  className={style.rotate_img}
                  alt=""
                  src={
                    process.env.REACT_APP_CLIENT_URL +
                    "img/landing/rotate_member_black.png"
                  }
                />
                <img
                  className={style.arrow_img}
                  alt=""
                  src={
                    process.env.REACT_APP_CLIENT_URL +
                    "img/landing/arrow_right_black.png"
                  }
                />
              </motion.div>
            ) : (
              //이벤트 등록 X 로그인 시 미출력
              <></>
            )
          }

          <motion.div
            className={style.about_jam_preview_img}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.1 }}
            variants={{
              offscreen: {
                opacity: 0,
                scale: 0.5,
              },
              onscreen: {
                opacity: 1,
                scale: 1,
                transition: {
                  duration: 1,
                  delay: 0.5,
                  ease: [0, 0.71, 0.2, 1.01],
                },
              },
            }}
          >
            <Image
              preview={false}
              width={"100%"}
              src={
                process.env.REACT_APP_CLIENT_URL + "img/landing/about_jam_1.png"
              }
            />
          </motion.div>
        </div>
      </div>
      <div className={style.about_jam_description}>
        {isMobile ? (
          <>
            <motion.div
              className={style.about_jam_description_content}
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true, amount: 0.2 }}
              variants={{
                offscreen: {
                  opacity: 0,
                  scale: 0.5,
                },
                onscreen: {
                  opacity: 1,
                  scale: 1,
                  transition: {
                    duration: 0.8,
                    delay: 0.5,
                    ease: [0, 0.71, 0.2, 1.01],
                  },
                },
              }}
            >
              <div className={style.about_jam_description_item}>
                JAM brings together the latest evidence-based techniques from
                prominent speakers and the newest developments so that you meet
                and learn from the best in the fields of dermatology, plastic
                surgery, and aging science.
              </div>
              <div className={style.about_jam_description_item}>
                It is an excellent reference and opportunity for practical and
                scientific exchange with colleagues from all over the world.
              </div>
            </motion.div>

            <motion.div
              className={style.about_jam_description_img}
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true, amount: 0.2 }}
              variants={{
                offscreen: {
                  opacity: 0,
                  scale: 0.5,
                },
                onscreen: {
                  opacity: 1,
                  scale: 1,
                  transition: {
                    duration: 0.8,
                    delay: 0.6,
                    ease: [0, 0.71, 0.2, 1.01],
                  },
                },
              }}
            >
              <Image
                preview={false}
                width={"100%"}
                src={
                  process.env.REACT_APP_CLIENT_URL +
                  "img/landing/about_jam_2.png"
                }
              />
            </motion.div>
            <motion.div
              className={style.about_jam_description_summary_content}
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true, amount: 0.2 }}
              variants={{
                offscreen: {
                  opacity: 0,
                  scale: 0.5,
                },
                onscreen: {
                  opacity: 1,
                  scale: 1,
                  transition: {
                    duration: 0.8,
                    delay: 0.7,
                    ease: [0, 0.71, 0.2, 1.01],
                  },
                },
              }}
            >
              <div className={style.about_jam_description_summary_item}>
                <div className={style.about_jam_description_summary_label} />
                <div className={style.about_jam_description_summary_text_wrap}>
                  <div className={style.about_jam_description_summary_text}>
                    Continue the exchange
                  </div>
                  <div className={style.about_jam_description_summary_text}>
                    with speakers and peers
                  </div>
                </div>
              </div>
              <div className={style.about_jam_description_summary_item}>
                <div className={style.about_jam_description_summary_label} />
                <div className={style.about_jam_description_summary_text_wrap}>
                  <div className={style.about_jam_description_summary_text}>
                    Shares the latest evidence-based
                  </div>
                  <div className={style.about_jam_description_summary_text}>
                    techniques and science
                  </div>
                </div>
              </div>
              <div className={style.about_jam_description_summary_item}>
                <div className={style.about_jam_description_summary_label} />
                <div className={style.about_jam_description_summary_text_wrap}>
                  <div className={style.about_jam_description_summary_text}>
                    Continuous discussion on diverse
                  </div>
                  <div className={style.about_jam_description_summary_text}>
                    topics and products
                  </div>
                </div>
              </div>
            </motion.div>
          </>
        ) : (
          <>
            <motion.div
              className={style.about_jam_description_img}
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true, amount: 0 }}
              variants={{
                offscreen: {
                  opacity: 0,
                  scale: 0.5,
                },
                onscreen: {
                  opacity: 1,
                  scale: 1,
                  transition: {
                    duration: 0.8,
                    delay: 0.5,
                    ease: [0, 0.71, 0.2, 1.01],
                  },
                },
              }}
            >
              <Image
                preview={false}
                width={isMobile ? "100%" : isTablet ? "100%" : "100%"}
                src={
                  process.env.REACT_APP_CLIENT_URL +
                  "img/landing/about_jam_2.png"
                }
              />
            </motion.div>
            <div className={style.about_jam_description_wrap}>
              <motion.div
                className={style.about_jam_description_content}
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0 }}
                variants={{
                  offscreen: {
                    opacity: 0,
                    scale: 0.5,
                  },
                  onscreen: {
                    opacity: 1,
                    scale: 1,
                    transition: {
                      duration: 0.8,
                      delay: 0.7,
                      ease: [0, 0.71, 0.2, 1.01],
                    },
                  },
                }}
              >
                <div className={style.about_jam_description_item}>
                  JAM brings together the latest evidence-based techniques from
                  prominent speakers and the newest developments so that you
                  meet and learn from the best in the fields of dermatology,
                  plastic surgery, and aging science.
                </div>
                <div className={style.about_jam_description_item}>
                  It is an excellent reference and opportunity for practical and
                  scientific exchange with colleagues from all over the world.
                </div>
              </motion.div>

              <motion.div
                className={style.about_jam_description_summary_content}
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0.2 }}
                variants={{
                  offscreen: {
                    opacity: 0,
                    scale: 0.5,
                  },
                  onscreen: {
                    opacity: 1,
                    scale: 1,
                    transition: {
                      duration: 0.8,
                      delay: 0.8,
                      ease: [0, 0.71, 0.2, 1.01],
                    },
                  },
                }}
              >
                <div className={style.about_jam_description_summary_item}>
                  <div className={style.about_jam_description_summary_label} />
                  <div
                    className={style.about_jam_description_summary_text_wrap}
                  >
                    <div className={style.about_jam_description_summary_text}>
                      {"Continue the exchange with speakers and peers"}
                    </div>
                  </div>
                </div>
                <div className={style.about_jam_description_summary_item}>
                  <div className={style.about_jam_description_summary_label} />
                  <div
                    className={style.about_jam_description_summary_text_wrap}
                  >
                    <div className={style.about_jam_description_summary_text}>
                      {"Share the latest evidence-based science and techniques"}
                    </div>
                  </div>
                </div>
                <div className={style.about_jam_description_summary_item}>
                  <div className={style.about_jam_description_summary_label} />
                  <div
                    className={style.about_jam_description_summary_text_wrap}
                  >
                    <div className={style.about_jam_description_summary_text}>
                      {"Continuous discussion on diverse topics and products"}
                    </div>
                  </div>
                </div>
              </motion.div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default AboutJam;
