import React, { useState, useEffect, useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import Loading from "components/common/loading";
import axios from "axios";
import AcademyList from "components/academy/academyList";

function Academy() {
  const [searchParams, setSearchParam] = useSearchParams();
  const academyViewType =
    searchParams.get("view") === undefined
      ? "epiticon"
      : searchParams.get("view");
  const [epiticon, setEpiticon] = useState({});
  const [eptq, setEptq] = useState({});
  const [loading, setLoading] = useState(true);

  const getAcademyList = useCallback(() => {
    setLoading(true);
    const url = "/academy/list";
    const param = {
      params: {},
    };

    axios
      .get(url, param)
      .then((res) => {
        if (res.status === 200) {
          setEpiticon(res.data.epiticon);
          setEptq(res.data.eptq);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    getAcademyList();
  }, [getAcademyList]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [academyViewType]);

  return (
    <>
      {loading ? (
        <Loading />
      ) : academyViewType === "epiticon" ? (
        <AcademyList type="epiticon" data={epiticon} />
      ) : (
        <AcademyList type="eptq" data={eptq} />
      )}
    </>
  );
}

export default Academy;
