import React, { useCallback, useLayoutEffect, useState } from "react";
import style from "css/account.module.css";
import { useNavigate } from "react-router-dom";
import Item from "components/account/item";
import Input from "components/account/input";
import Button from "components/account/button";
import { useDispatch } from "react-redux";
import axios from "axios";
import { closeAlert, openAlert } from "redux/slices/alertSlice";
import Loading from "components/common/loading";

function Reset() {
  //dispatch
  const dispatch = useDispatch();
  //navigate
  const navigate = useNavigate();
  //state
  const [init, setInit] = useState(true);
  const [loading, setLoading] = useState(true);
  const [isReset, setIsReset] = useState(false);
  const [email, setEmail] = useState("");
  //useCallBack
  const confirmAuthcode = useCallback(() => {
    const email = new URL(window.location.href).searchParams.get("email");
    const code = new URL(window.location.href).searchParams.get("code");

    if (
      email === undefined ||
      email === "" ||
      email === null ||
      code === undefined ||
      code === "" ||
      code === null
    ) {
      const obj = {
        title: "Notice",
        content: "Required parameter value is missing.",
        submitEventHandler: () => {
          navigate(process.env.REACT_APP_CLIENT_URL);
          dispatch(closeAlert());
        },
      };
      dispatch(openAlert(obj));
      return;
    }

    const data = {
      email: email,
      code: code,
    };

    axios
      .put("/user/password/authcode", data)
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          if (res.data === true) {
            setEmail(email);
            setLoading(false);
          } else {
            const obj = {
              title: "Notice",
              content: "This authcode is not usable.",
              submitEventHandler: () => {
                navigate(process.env.REACT_APP_CLIENT_URL);
                dispatch(closeAlert());
              },
            };
            dispatch(openAlert(obj));
          }
        } else {
          const obj = {
            title: "Notice",
            content: "This authcode is not usable.",
            submitEventHandler: () => {
              navigate(process.env.REACT_APP_CLIENT_URL);
              dispatch(closeAlert());
            },
          };
          dispatch(openAlert(obj));
        }
      })
      .catch(() => {
        const obj = {
          title: "Notice",
          content: "An error has occurred. Please contact the administrator.",
          submitEventHandler: () => {
            navigate(process.env.REACT_APP_CLIENT_URL);
            dispatch(closeAlert());
          },
        };
        dispatch(openAlert(obj));
      });
  }, [dispatch, navigate]);
  //
  useLayoutEffect(() => {
    if (init) {
      confirmAuthcode();
      setInit(false);
    }
  }, [confirmAuthcode, init]);
  return (
    <section className={style.wrapper}>
      <div className={style.container}>
        {loading ? (
          <Loading />
        ) : isReset ? (
          <ResetSuccess />
        ) : (
          <ResetPassword setIsReset={setIsReset} email={email} />
        )}
      </div>
    </section>
  );
}

export default Reset;

function ResetSuccess() {
  //navigate
  const navigate = useNavigate();
  return (
    <>
      <ResetSuccessBanner />
      <section className={style.success}>
        <Button
          content={"Got to log in"}
          disabled={false}
          loading={false}
          valid={false}
          clickEvent={() =>
            navigate(process.env.REACT_APP_CLIENT_URL + "signin")
          }
        />
      </section>
    </>
  );
}

function ResetSuccessBanner() {
  return (
    <article className={style.banner}>
      <p className={style.title}>Password Changed</p>
      <p className={style.title}>Successfully!</p>
    </article>
  );
}

function ResetPassword({ setIsReset, email }) {
  return (
    <>
      <ResetBanner />
      <ResetForm setIsReset={setIsReset} email={email} />
    </>
  );
}

function ResetBanner() {
  return (
    <article className={style.banner}>
      <p className={style.title}>Reset password</p>

      <article className={style.description}>
        <p>The password should include</p>
        <p>
          <strong>alphabet, number, special character,</strong>
        </p>
        <p>
          and be at least <strong>8 character</strong> long.
        </p>
      </article>
    </article>
  );
}
function ResetForm({ setIsReset, email }) {
  //dispatch
  const dispatch = useDispatch();
  //state
  const [password, setPassword] = useState({
    title: "Password",
    value: "",
    confirm_value: "",
    require: true,
    error: false,
    msg: "",
  });
  const [resetBtn, setResetBtn] = useState({
    content: "Reset Password",
    valid: false,
    loading: false,
    disabled: true,
  });
  //func
  const updatePasswordEvent = () => {
    if (!confirmPassword()) {
      return;
    }
    setResetBtn({
      ...resetBtn,
      loading: true,
    });
    const data = {
      email: email,
      password: password.value,
    };

    axios
      .put("/user/password", data)
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          setIsReset(true);
        } else {
          setResetBtn({
            ...resetBtn,
            loading: false,
          });
          const obj = {
            title: "Notice",
            content: "Password reset failed.",
            submitEventHandler: () => {
              dispatch(closeAlert());
            },
          };
          dispatch(openAlert(obj));
        }
      })
      .catch((err) => {
        setResetBtn({
          ...resetBtn,
          loading: false,
        });
        const obj = {
          title: "Notice",
          content: err.response.data,
          submitEventHandler: () => {
            dispatch(closeAlert());
          },
        };
        dispatch(openAlert(obj));
      });
  };
  const confirmPassword = () => {
    if (password.value === "") {
      setPassword({
        ...password,
        error: true,
        msg: "Please insert password.",
      });
      return false;
    } else if (password.confirm_value === "") {
      setPassword({
        ...password,
        error: true,
        msg: "Please insert confirm password.",
      });
      return false;
    } else if (password.value !== password.confirm_value) {
      setPassword({
        ...password,
        error: true,
        msg: "The password does not match.",
      });
      return false;
    } else {
      var number = /[0-9]/;
      var alphabet = /[a-zA-Z]/;
      var special = /[~!@\\#$%<>^&*]/;
      const validLength = password.value.length >= 8;
      const validPassword =
        number.test(password.value) &&
        alphabet.test(password.value) &&
        special.test(password.value);
      if (!validLength) {
        setPassword({
          ...password,
          error: true,
          msg: "The password should be at least 8 character long.",
        });
        return false;
      } else if (!validPassword) {
        setPassword({
          ...password,
          error: true,
          msg: "The password should include alphabet, number, special character.",
        });
        return false;
      }
    }
    return true;
  };
  return (
    <>
      <section className={style.form}>
        <Item
          require={password.require}
          error={password.error}
          msg={password.msg}
          title={password.title}
          component={
            <div className={style.row_grid}>
              <Input
                type="password"
                placeholder="Password"
                error={password.error}
                value={password.value}
                onChangeEvent={(res) => {
                  if (password.error && res !== password.value) {
                    setPassword({
                      ...password,
                      value: res,
                      error: false,
                      msg: "",
                    });
                  } else {
                    setPassword({
                      ...password,
                      value: res,
                    });
                  }
                  if (res.length > 0 && password.confirm_value.length > 0) {
                    setResetBtn({
                      ...resetBtn,
                      disabled: false,
                    });
                  } else {
                    setResetBtn({
                      ...resetBtn,
                      disabled: true,
                    });
                  }
                }}
                disabled={resetBtn.loading}
              />
              <Input
                type="password"
                placeholder="Confirm Password"
                error={password.error}
                value={password.confirm_value}
                onChangeEvent={(res) => {
                  if (password.error && res !== password.confirm_value) {
                    setPassword({
                      ...password,
                      confirm_value: res,
                      error: false,
                      msg: "",
                    });
                  } else {
                    setPassword({
                      ...password,
                      confirm_value: res,
                    });
                  }
                  if (res.length > 0 && password.value.length > 0) {
                    setResetBtn({
                      ...resetBtn,
                      disabled: false,
                    });
                  } else {
                    setResetBtn({
                      ...resetBtn,
                      disabled: true,
                    });
                  }
                }}
                disabled={resetBtn.loading}
              />
            </div>
          }
        />
      </section>

      <section className={style.email}>
        <Button
          content={resetBtn.content}
          disabled={resetBtn.disabled}
          loading={resetBtn.loading}
          valid={resetBtn.valid}
          clickEvent={updatePasswordEvent}
        />
      </section>
    </>
  );
}
