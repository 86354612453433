import React from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom'

/**
 * 파일명: Error.js
 * 작성자: 권도훈
 * 작성일자: 23.04.06
 * 설명: Error page
 */
function Error({ msg }) {
    //navigate
    const navigate = useNavigate();
    //translation
    const { t } = useTranslation();

    const pageBackEventHandler = () => {
        navigate(-1);
    }

    return (
        <div className='app-error-container center-y'>
            <div>{msg}</div>
            <button onClick={() => pageBackEventHandler()}>
                {t("BTN.BACK")}
            </button>
        </div>
    )
}

export default Error