import axios from "axios";
import style from "css/library/libraryCard.module.css";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { openAlert } from "redux/slices/alertSlice";
import Cookies from "universal-cookie";
import dayjs from "dayjs";
import utc from "dayjs-timezone-iana-plugin";
import timezone from "dayjs-plugin-utc";

// dayjs에 플러그인 등록
dayjs.extend(utc);
dayjs.extend(timezone);

function LibraryCard({ data }) {
  const cookies = new Cookies();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const viewBtnHandler = (id) => {
    navigate(process.env.REACT_APP_CLIENT_URL + "library/" + id);
  };
  const [favorite, setFavorite] = useState(data.FAVORITE == 1 ? true : false);

  // 2023-12-25 00:00:00 to 2023.12.25 MON
  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");

    const daysOfWeek = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
    const dayOfWeek = daysOfWeek[date.getDay()];

    return `${year}.${month}.${day} ${dayOfWeek}`;
  }

  // get start/end time range
  function formatTimeRange(startTimeString, endTimeString) {
    const startTime = new Date(startTimeString);
    const endTime = new Date(endTimeString);

    const startHour = startTime.getHours().toString().padStart(2, "0");
    const startMinute = startTime.getMinutes().toString().padStart(2, "0");

    const endHour = endTime.getHours().toString().padStart(2, "0");
    const endMinute = endTime.getMinutes().toString().padStart(2, "0");

    return `${startHour}:${startMinute}-${endHour}:${endMinute} (GMT)`;
  }

  // 1st, 2nd, 3rd
  function getOrdinal(number) {
    // const suffixes = ["st", "nd", "rd"];
    // const lastDigit = number % 10;
    // const specialCases = [11, 12, 13];

    // if (specialCases.includes(number)) {
    //     return number + "th";
    // } else if (suffixes[lastDigit - 1]) {
    //     return number + suffixes[lastDigit - 1];
    // } else {
    //     return number + "th";
    // }

    const suffixes = ["th", "st", "nd", "rd"];
    const lastTwoDigits = number % 100;
    const lastDigit = number % 10;
    const specialCases = [11, 12, 13];

    if (specialCases.includes(lastTwoDigits)) {
      return number + "th";
    } else if (suffixes[lastDigit]) {
      return number + suffixes[lastDigit];
    } else {
      return number + suffixes[0];
    }
  }

  const imgUrl = process.env.REACT_APP_CLIENT_URL + data.CARD_IMG;
  const cardBackgroundStyle = {
    backgroundImage: `url(${imgUrl})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  };
  const fontStyle = {
    color: `${data.FONT_COLOR}`,
  };

  const favoriteHandler = () => {
    if (cookies.get("AuthToken") === undefined) {
      // alert("This service requires login. Please login first.");
      dispatch(
        openAlert({
          title: "Notice",
          content: "This service requires login. Please login first.",
          submitEventHandler: () =>
            // (window.location.href =
            //   process.env.REACT_APP_CLIENT_URL + "signin", { state: { returnPage: true } }),
            navigate(process.env.REACT_APP_CLIENT_URL + "signin", {
              state: { returnPage: true },
            }),
        })
      );
      return;
    }

    const d = {
      params: {
        EVENT_ID: data.EVENT_ID,
        TASK: favorite ? "UNSET" : "SET",
      },
    };
    axios
      .get("/library/favorite", d)
      .then((res) => {
        if (res.status === 200) {
          console.log("success");
          setFavorite(!favorite);
        }
      })
      .catch(() => {
        console.log("failed");
      })
      .finally(() => {});
  };

  return (
    <div className={style.card_wrapper}>
      <div
        className={style.card_top}
        // style={cardBackgroundStyle}
      >
        <button className={style.fav_btn} onClick={() => favoriteHandler()}>
          <img
            className={style.btn_img}
            alt=""
            src={
              favorite
                ? process.env.REACT_APP_CLIENT_URL +
                  "img/library/filled_heart.png"
                : process.env.REACT_APP_CLIENT_URL +
                  "img/library/empty_heart.png"
            }
          />
        </button>
        <div className={style.card_background} style={cardBackgroundStyle}>
          <div className={style.card_title}>
            <div className={style.title_1} style={fontStyle}>
              {getOrdinal(data.EPISODE)} JAM
            </div>
            <div className={style.title_2} style={fontStyle}>
              {data.DESCRIPTION}
              {/* The Latest Discussion About Minimally Invasive Aesthetic
              Procedures */}
            </div>
          </div>
        </div>
        {/* <div className={style.card_img_wrapper}>
                    <img
                        className={style.card_img}
                        alt=""
                        src={
                            process.env.REACT_APP_CLIENT_URL +
                            data.jamImg
                        }
                    />
                </div> */}
      </div>
      <div className={style.card_bot}>
        <div className={style.info_container}>
          <div className={style.info}>
            <div className={style.info_img_container}>
              <img
                className={style.info_img}
                alt=""
                src={process.env.REACT_APP_CLIENT_URL + "img/library/clock.png"}
              />
            </div>
            <div className={style.info_datetime}>
              <div className={style.info_date}>
                {dayjs(data.START_TIME).format("YYYY.MM.DD ddd")}
                {/* {formatDate(data.START_TIME)} */}
              </div>
              <div className={style.info_time}>
                {dayjs(data.START_TIME).format("HH:mm") +
                  "-" +
                  dayjs(data.END_TIME).format("HH:mm") +
                  " (GMT)"}
                {/* {formatTimeRange(data.START_TIME, data.END_TIME)} */}
              </div>
            </div>
          </div>

          {/* <Link to="/library/1"><button className={style.viewmore_btn}>View More</button></Link> */}
          <button
            className={style.viewmore_btn}
            onClick={() => viewBtnHandler(data.EVENT_ID)}
          >
            View More
          </button>
        </div>
      </div>
    </div>
  );
}

export default LibraryCard;
