import React, { useState } from "react";
import style from "css/passwordmypage.module.css";
import axios from "axios";
import { useDispatch } from "react-redux";
import { closeAlert, openAlert } from "redux/slices/alertSlice";

function Password() {
  //dispatch
  const dispatch = useDispatch();

  const [password, setpassword] = useState({
    Current: "",
    New: "",
    Confirm: "",
  });

  const UpdatePassword = () => {
    var number = /[0-9]/;
    var alphabet = /[a-zA-Z]/;
    var special = /[~!@\\#$%<>^&*]/;
    const validLength = password.New.length >= 8;
    const validPassword =
      number.test(password.New) &&
      alphabet.test(password.New) &&
      special.test(password.New);
    if (!validLength) {
      const obj = {
        title: "Notice",
        content: "The password should be at least 8 character long.",
        submitEventHandler: () => {
          dispatch(closeAlert());
        },
      };
      dispatch(openAlert(obj));
      return;
    } else if (!validPassword) {
      const obj = {
        title: "Notice",
        content:
          "The password should include alphabet, number, special character.",
        submitEventHandler: () => {
          dispatch(closeAlert());
        },
      };
      dispatch(openAlert(obj));
      return;
    }
    if (password.New !== password.Confirm) {
      const obj = {
        title: "Notice",
        content: "Password doesn't match",
        submitEventHandler: () => {
          dispatch(closeAlert());
        },
      };
      dispatch(openAlert(obj));
      return;
    } else if (password.Current === password.New) {
      const obj = {
        title: "Notice",
        content:
          "Sorry, your new password cannot be the same as your old password.",
        submitEventHandler: () => {
          dispatch(closeAlert());
        },
      };
      dispatch(openAlert(obj));
      return;
    } else {
      const data = password;

      axios.post("/mypage/updatePassword", data).then((res) => {
        if (res.status === 200) {
          if (res.data > 0) {
            const obj = {
              title: "Notice",
              content: "Your password has been changed.",
              submitEventHandler: () => {
                dispatch(closeAlert());
              },
            };
            dispatch(openAlert(obj));
            setpassword({
              Current: "",
              New: "",
              Confirm: "",
            });
          } else if (res.data === -1) {
            const obj = {
              title: "Notice",
              content: "Please check the password",
              submitEventHandler: () => {
                dispatch(closeAlert());
              },
            };
            dispatch(openAlert(obj));
          } else {
            const obj = {
              title: "Notice",
              content: "Failed to change password",
              submitEventHandler: () => {
                dispatch(closeAlert());
              },
            };
            dispatch(openAlert(obj));
          }
        }
      });
    }
  };
  return (
    <>
      <div className={style.container}>
        <div className={style.wrapper}>
          <div className={style.header}> Changed Password</div>
          <div className={style.body}>
            <InputPassword
              header="Current password"
              type="password"
              onchange={(e) =>
                setpassword({ ...password, Current: e.target.value })
              }
              value={password.Current}
            />
            <InputPassword
              header="New password"
              type="password"
              onchange={(e) =>
                setpassword({ ...password, New: e.target.value })
              }
              value={password.New}
            />
            <InputPassword
              header="Confirm new password"
              type="password"
              onchange={(e) =>
                setpassword({ ...password, Confirm: e.target.value })
              }
              value={password.Confirm}
            />
            <div onClick={() => UpdatePassword()} className={style.button}>
              Update Password
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function InputPassword(props) {
  const [type, settype] = useState(props.type);

  const clickview = () => {
    if (type === "password") {
      settype("text");
    } else {
      settype("password");
    }
  };

  return (
    <div className={style.password}>
      <div className={style.password_header}>{props.header}</div>
      <div className={style.password_body}>
        <input
          className={style.password_input}
          type={type}
          value={props.value}
          onChange={(e) => props.onchange(e)}
        />
        <img
          className={style.password_img}
          alt="12313"
          onClick={() => clickview()}
          src={
            process.env.REACT_APP_CLIENT_URL + "img/password/" + type + ".png"
          }
        />
      </div>
    </div>
  );
}

InputPassword.propTypes = {};

export default Password;
