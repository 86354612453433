import React from "react";
import PropTypes from "prop-types";
import style from "css/mypage.module.css";
import { ReactComponent as FavortiteIcon } from "asset/myPage/favorite.svg";
import { ReactComponent as InformationIcon } from "asset/myPage/information.svg";
import { ReactComponent as PassWordIcon } from "asset/myPage/password.svg";
import { ReactComponent as LogoutIcon } from "asset/myPage/logout.svg";
//22 21
//17 16
function Info(props) {
  return (
    <section className={style.info}>
      <article className={style.info_comment}>
        <p>Hi, {props.name}</p>
      </article>
      <Menu type={props.type} setType={props.setType} />
    </section>
  );
}

function Menu({ type, setType }) {
  const menuList = [
    {
      label: "Favorite Webinar",
      value: "favorite",
      icon: <FavortiteIcon />,
    },
    {
      label: "Edit My Information",
      value: "information",
      icon: <InformationIcon />,
    },
    {
      label: "Change Password",
      value: "password",
      icon: <PassWordIcon />,
    },
    {
      label: "Logout",
      value: "logout",
      icon: <LogoutIcon />,
    },
  ];
  const clickEvent = (value) => {
    setType(value);
  };
  return (
    <section className={`${style.menu} ${style.scrollbar}`}>
      {menuList.map((item, idx) => (
        <div key={idx}>
          <MenuItem
            icon={item.icon}
            label={item.label}
            clickEvent={() => clickEvent(item.value)}
            active={item.value === type}
          />
        </div>
      ))}
    </section>
  );
}
function MenuItem({ icon, label, clickEvent, active }) {
  return (
    <div
      onClick={clickEvent}
      className={
        active ? `${style.menu_item} ${style.active}` : style.menu_item
      }
    >
      {icon}
      <p>{label}</p>
      {/* <div className={style.underline}></div> */}
    </div>
  );
}

Info.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  setType: PropTypes.func,
};

export default Info;
