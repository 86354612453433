import React from "react";
import { useSearchParams } from "react-router-dom";
import EventList from "./eventList";
import EventRegister from "./eventRegister";

function Event() {
  const [searchParams, setSearchParam] = useSearchParams();
  const eventId = searchParams.get("id");

  return (
    <>
      {eventId === null ? <EventList /> : <EventRegister eventId={eventId} />}
    </>
  );
}

export default Event;
