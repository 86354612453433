import React, { useState } from "react";
import style from "css/account.module.css";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { closeAlert, openAlert } from "redux/slices/alertSlice";
import Item from "components/account/item";
import Input from "components/account/input";
import Button from "components/account/button";

/**
 * 파일명: Password.jsx
 * 작성자: 권도훈
 * 작성일자: 24.01.03
 */
function Password() {
  const [sendAuthcode, setSendAuthcode] = useState(false);
  return (
    <section className={style.wrapper}>
      <div className={style.container}>
        {sendAuthcode ? (
          <SendSuccess />
        ) : (
          <ResetPassword setSendAuthcode={setSendAuthcode} />
        )}
      </div>
    </section>
  );
}

function SendSuccess() {
  //navigate
  const navigate = useNavigate();
  return (
    <>
      <SuccessBanner />
      <section className={style.success}>
        <Button
          content={"Got to log in"}
          disabled={false}
          loading={false}
          valid={false}
          clickEvent={() =>
            navigate(process.env.REACT_APP_CLIENT_URL + "signin")
          }
        />
      </section>
    </>
  );
}
function SuccessBanner() {
  return (
    <article className={style.success_banner}>
      <p className={style.title}>You’ve got mail</p>
      <p className={style.success_comment}>Password successfully reset!</p>
      <article className={style.description}>
        <p>An email containing a new password has been sent to</p>
        <p>the email address on file for your account.</p>
      </article>
    </article>
  );
}

function ResetPassword({ setSendAuthcode }) {
  return (
    <>
      <ResetBanner />
      <ResetForm setSendAuthcode={setSendAuthcode} />
    </>
  );
}

function ResetBanner() {
  return (
    <article className={style.banner}>
      <p className={style.title}>Reset password</p>

      <article className={style.description}>
        <p>Please enter your email address.</p>
        <p>
          We will send you a <strong>new password</strong> by email.
        </p>
      </article>
    </article>
  );
}
function ResetForm({ setSendAuthcode }) {
  //dispatch
  const dispatch = useDispatch();
  //state
  const [email, setEmail] = useState({
    title: "Email",
    value: "",
    require: true,
    error: false,
    msg: "",
  });
  const [resetBtn, setResetBtn] = useState({
    content: "Reset Password",
    valid: false,
    loading: false,
    disabled: true,
  });
  //func
  const confirmEmail = () => {
    if (email.value === "") {
      setEmail({
        ...email,
        error: true,
        msg: "Please insert E-Mail.",
      });
      return false;
    }
    return true;
  };
  const sendAuthcodeEvent = () => {
    if (confirmEmail()) {
      setResetBtn({
        ...resetBtn,
        loading: true,
      });
      const data = {
        email: email.value,
      };

      axios
        .post("/user/password", data)
        .then((res) => {
          if (res.status >= 200 && res.status < 300) {
            const obj = {
              title: "Notice",
              content: "Check your Email.",
              submitEventHandler: () => {
                setSendAuthcode(true);
                dispatch(closeAlert());
              },
            };
            dispatch(openAlert(obj));
          } else {
            setResetBtn({
              ...resetBtn,
              loading: false,
            });
            const obj = {
              title: "Notice",
              content: "Fail to send authcode.",
              submitEventHandler: () => {
                dispatch(closeAlert());
              },
            };
            dispatch(openAlert(obj));
          }
        })
        .catch((err) => {
          setResetBtn({
            ...resetBtn,
            loading: false,
          });
          const obj = {
            title: "Notice",
            content: err.response.data,
            submitEventHandler: () => {
              dispatch(closeAlert());
            },
          };
          dispatch(openAlert(obj));
        });
    }
  };
  const onKeyDownEvent = (key) => {
    if (key === "Enter") {
      sendAuthcodeEvent();
    }
  };
  return (
    <>
      <section className={style.form}>
        <Item
          require={email.require}
          error={email.error}
          msg={email.msg}
          title={email.title}
          component={
            <Input
              type="text"
              placeholder="Email"
              error={email.error}
              value={email.value}
              onChangeEvent={(res) => {
                if (email.error && res !== email.value) {
                  setEmail({
                    ...email,
                    value: res,
                    error: false,
                    msg: "",
                  });
                } else {
                  setEmail({
                    ...email,
                    value: res,
                  });
                }

                if (res.length > 0) {
                  setResetBtn({
                    ...resetBtn,
                    disabled: false,
                  });
                } else {
                  setResetBtn({
                    ...resetBtn,
                    disabled: true,
                  });
                }
              }}
              onKeyDownEvent={onKeyDownEvent}
              disabled={resetBtn.loading}
            />
          }
        />
      </section>

      <section className={style.email}>
        <Button
          content={resetBtn.content}
          disabled={resetBtn.disabled}
          loading={resetBtn.loading}
          valid={resetBtn.valid}
          clickEvent={sendAuthcodeEvent}
        />
      </section>
    </>
  );
}

export default Password;
