import React from "react";
import style from "css/newsletter.module.css";
import { useNavigate } from "react-router-dom";
import NewsletterListItem from "components/newsletter/newsletterListItem";
function NewsletterList(props) {
  //navigate
  const navigate = useNavigate();
  const newsletterDetailHandler = (id) => {
    navigate(process.env.REACT_APP_CLIENT_URL + "newsletter?id=" + id);
  };
  return (
    <div className={style.newsletter_wrapper}>
      <div className={style.newsletter_title_wrapper}>
        <div className={style.newsletter_title}>
          Newsletter<span>Read more about JETEMA news.</span>
        </div>
      </div>
      <div className={style.newsletter_list}>
        {props.newsletterList.map((newsletter) => (
          <NewsletterListItem
            key={newsletter.NEWSLETTER_ID}
            data={newsletter}
            click={newsletterDetailHandler}
          />
        ))}
      </div>
    </div>
  );
}

export default NewsletterList;
