import React, { useCallback, useEffect, useRef, useState } from "react";
import style from "css/register/eventRegister.module.css";
import tStyle from "css/template.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { Button, ConfigProvider, Form, Input, Row, Select, Spin } from "antd";
import axios from "axios";
import AgendaTable from "components/library/agendaTable";
import SpeakerCard from "components/library/speakerCard";
import dayjs from "dayjs";
import utc from "dayjs-timezone-iana-plugin";
import timezone from "dayjs-plugin-utc";
import Cookies from "universal-cookie";
import { openAlert } from "redux/slices/alertSlice";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import Loading from "components/common/loading";

// dayjs에 플러그인 등록
dayjs.extend(utc);
dayjs.extend(timezone);

/**
 * Version : 1.0
 * 작성일자 : 2023-12-19
 * 작성자 : 김희민
 * 설명 : zoom event Register
 */
export default function EventRegister(props) {
  const routeParams = useParams();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const cookies = new Cookies();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [tabActive, setTabActive] = useState({
    timezone: false,
    speakers: false,
    agenda: false,
  });
  const [event, setEvent] = useState({});
  const [eventSpeaker, setEventSpeaker] = useState([]);
  const [eventAgenda, setEventAgenda] = useState(null);
  const [regiBtunState, setRegiBtnState] = useState("");
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  const getEvent = useCallback((eventId) => {
    let config = {
      params: {
        EVENT_ID: eventId,
      },
    };

    axios
      .get("/event/detail", config)
      .then((res) => {
        if (res.status === 200) {
          setEvent(res.data.event);
          setEventSpeaker(res.data.eventSpeaker);
          setEventAgenda(res.data.eventAgenda);
        }
      })
      .catch(() => {
        dispatch(
          openAlert({
            title: "Notice",
            content: "registration failed!",
            submitEventHandler: () => getEvent(props.eventId),
          })
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    // getEvent(routeParams.eventId);
    getEvent(props.eventId);
  }, [getEvent, props.eventId]);

  const [convertedTimes, setConvertedTimes] = useState({});

  useEffect(() => {
    const timeZones = [
      "America/Sao_Paulo",
      "Europe/Moscow",
      "Europe/London",
      "Asia/Hong_Kong",
      "Europe/Paris",
      "Asia/Seoul",
    ];

    const newConvertedTimes = timeZones.reduce((acc, timeZone) => {
      const startTimeInTimeZone = dayjs(event.START_TIME)
        .tz(timeZone)
        .format("HH:mm");
      const endTimeInTimeZone = dayjs(event.END_TIME)
        .tz(timeZone)
        .format("HH:mm");
      acc[timeZone] = `${startTimeInTimeZone} - ${endTimeInTimeZone}`;
      return acc;
    }, {});

    setConvertedTimes(newConvertedTimes);

    // 미팅 진행여부 비교, 30분 전부터 입장 가능
    const startTime = dayjs(event.START_TIME).subtract(30, "minutes");
    const endTime = dayjs(event.END_TIME);
    const currentTimeUtc = dayjs().utc();

    if (event.IS_REGISTRATION === 0) {
      // 미등록 상태
      setRegiBtnState("Register");
    } else {
      // 등록 상태
      if (currentTimeUtc.isBefore(startTime)) {
        // 진행 전
        setRegiBtnState("Registered");
      } else if (currentTimeUtc.isAfter(endTime)) {
        // 진행완료
        setRegiBtnState("Ended");
      } else {
        // 진행 중
        setRegiBtnState("Meeting Start");
      }
    }
  }, [event]);

  // tab클릭시 해당 div위치로 이동 (top값 변경)
  const toggleTab = (tabName) => {
    setTabActive({
      ...{ timezone: false, speakers: false, agenda: false },
      [tabName]: true,
    });

    const area = document.getElementById(tabName);
    const offsetTop = getAbsoluteTop(area);
    const headerTabHeight = 160; // 헤더,탭 높이를 고려

    window.scrollTo({ top: offsetTop - headerTabHeight, behavior: "smooth" });
  };

  function getAbsoluteTop(element) {
    let currentElement = element;
    let offsetTop = 0;
    while (currentElement) {
      offsetTop += currentElement.offsetTop;
      currentElement = currentElement.offsetParent;
    }
    return offsetTop;
  }

  useEffect(() => {
    // 스크롤할때 특정 tab area위치시 해당tab 활성화
    const handleScroll = () => {
      const headerTabHeight = 180; // 헤더,탭 높이를 고려
      const scrollPosition = window.scrollY + headerTabHeight;
      let activeTab = "timezone";
      ["timezone", "speakers", "agenda"].forEach((tab) => {
        const section = document.getElementById(tab);
        if (section && getAbsoluteTop(section) <= scrollPosition) {
          activeTab = tab;
        }
      });

      setTabActive({
        timezone: false,
        speakers: false,
        agenda: false,
        [activeTab]: true,
      });
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // 좋아요 버튼 핸들러
  const toggleFavorite = (eventId, isFavorite) => {
    if (cookies.get("AuthToken") === undefined) {
      dispatch(
        openAlert({
          title: "Notice",
          content: "This service requires login. Please login first.",
          submitEventHandler: () =>
            navigate(process.env.REACT_APP_CLIENT_URL + "signin", {
              state: { returnPage: true },
            }),
        })
      );
      return;
    }

    const params = {
      EVENT_ID: eventId,
      IS_FAVORITE: isFavorite,
    };

    axios
      .put("/event/favorite", params)
      .then((res) => {
        if (res.status === 200) {
          getEvent(eventId);
        }
      })
      .catch((error) => {
        dispatch(
          openAlert({
            title: "Notice",
            content: "An error occurred while processing data.",
            submitEventHandler: () => getEvent(props.eventId),
          })
        );
      });
  };

  // 알람 버튼 핸들러
  const toggleAlarm = (eventId, isAlarm) => {
    if (cookies.get("AuthToken") === undefined) {
      dispatch(
        openAlert({
          title: "Notice",
          content: "This service requires login. Please login first.",
          submitEventHandler: () =>
            navigate(process.env.REACT_APP_CLIENT_URL + "signin", {
              state: { returnPage: true },
            }),
        })
      );
      return;
    }

    const params = {
      EVENT_ID: eventId,
      IS_ALARM: isAlarm,
    };

    axios
      .put("/event/alarm", params)
      .then((res) => {
        if (res.status === 200) {
          getEvent(eventId);
        }
      })
      .catch((error) => {
        dispatch(
          openAlert({
            title: "Notice",
            content: "An error occurred while processing data.",
            submitEventHandler: () => getEvent(props.eventId),
          })
        );
      });
  };

  // submit 참가자 event등록
  const onFinish = (registrant) => {
    if (cookies.get("AuthToken") === undefined) {
      dispatch(
        openAlert({
          title: "Notice",
          content: "This service requires login. Please login first.",
          submitEventHandler: () =>
            navigate(process.env.REACT_APP_CLIENT_URL + "signin", {
              state: { returnPage: true },
            }),
        })
      );
      return;
    }
    if (regiBtunState === "Register") {
      // 등록처리
      setIsLoading(true);
      const params = {
        EVENT_ID: props.eventId,
      };

      axios
        .post("/event/register", params)
        .then((res) => {
          if (res.status === 200) {
            dispatch(
              openAlert({
                title: "Notice",
                content: "registration Compeleted!",
                submitEventHandler: () => getEvent(props.eventId),
              })
            );
            form.resetFields();
          }
        })
        .catch((error) => {
          dispatch(
            openAlert({
              title: "Notice",
              content: "An error occurred while processing data.",
              submitEventHandler: () => getEvent(props.eventId),
            })
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      // 참여 처리
      window.open(event.EVENT_JOIN_LINK, "_blank", "noopener, noreferrer");
    }
  };

  // submit 실패시
  const onFinishFailed = (errorInfo) => {
    // console.log('Failed:', errorInfo);
  };

  function eventTimeLabel(startDateTime, endDateTime) {
    // dayjs 객체 생성
    const startTime = dayjs(startDateTime);
    const endTime = dayjs(endDateTime);

    // formatting (ex. 2023.10.14 SAT, 11:30 - 13:00 (GMT))
    const formattedStartDateTime = startTime.format("YYYY.MM.DD ddd, HH:mm");
    const formattedEndDateTime = endTime.format("HH:mm");
    const formattedDateTimeRange = `${formattedStartDateTime} - ${formattedEndDateTime} (GMT)`;

    return formattedDateTimeRange;
  }

  const filterOption = (input, option) => {
    return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  useEffect(() => {
    // style 객체에서 클래스 이름을 가져와서 사용
    const element = document.querySelector(`.${tStyle.wrap}`);
    if (element) {
      // DOM 조작
      element.style.overflow = "visible";
    }
  }, []);

  return (
    <div>
      <Spin spinning={isLoading}>
        <div className={style.body}>
          <div className={style.eventContainer}>
            <section className={style.eventArea}>
              <div className={style.eventCard}>
                <div className={style.eventImageArea}>
                  <img
                    className={style.eventImage}
                    alt=""
                    src={
                      process.env.REACT_APP_CLIENT_URL +
                      (event.STATUS_CD === "CD1403"
                        ? isMobile
                          ? "img/register/detail/comingsoon_m.png"
                          : "img/register/detail/comingsoon.png"
                        : isMobile
                        ? event.REGISTER_DETAIL_IMG_MOBILE
                        : event.REGISTER_DETAIL_IMG)
                    }
                  />
                  <div className={style.eventImageSubArea}>
                    <div>
                      <img
                        className={style.eventImageSubCameraIcon}
                        alt=""
                        src={
                          process.env.REACT_APP_CLIENT_URL +
                          "img/register/camera-icon.png"
                        }
                      ></img>
                      <span>Zoom webinar</span>
                    </div>
                    <div>
                      <img
                        className={style.eventImageSubYoutubeIcon}
                        alt=""
                        src={
                          process.env.REACT_APP_CLIENT_URL +
                          "img/register/youtube-icon.png"
                        }
                      ></img>
                      <span>This meeting may be recorded</span>
                    </div>
                    <div>
                      <img
                        className={style.eventImageSubStreamingIcon}
                        alt=""
                        src={
                          process.env.REACT_APP_CLIENT_URL +
                          "img/register/streaming-icon.png"
                        }
                      ></img>
                      <span>This meeing may be livestreamed</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className={style.eventDetailsTitle}>
                <span>{event.EVENT_NM}</span>
              </div>
              <div className={style.eventDetailsDescription}>
                <span>{event.DESCRIPTION}</span>
              </div>
              <div className={style.eventDetailsTabs}>
                <div
                  className={`${style.eventDetailsTab} ${
                    tabActive["timezone"] ? style.active : ""
                  }`}
                  onClick={() => toggleTab("timezone")}
                >
                  Time
                  {/* <span className={style.timezoneLabel}>(Time Zone)</span> */}
                </div>
                <div
                  className={`${style.eventDetailsTab} ${
                    tabActive["speakers"] ? style.active : ""
                  }`}
                  onClick={() => toggleTab("speakers")}
                >
                  Speakers
                </div>
                <div
                  className={`${style.eventDetailsTab} ${
                    tabActive["agenda"] ? style.active : ""
                  }`}
                  onClick={() => toggleTab("agenda")}
                >
                  Agenda
                </div>
              </div>
              <div className={style.eventTimezoneArea} id="timezone">
                <div className={style.eventTimezoneHeader}>Time Zone</div>
                <div className={style.eventTimezoneContent}>
                  <div className={style.eventTimezone}>
                    <div className={style.eventTimezoneName}>Brasilia</div>
                    <div
                      className={`${style.eventTimezoneDate} ${style.border}`}
                    >
                      {convertedTimes["America/Sao_Paulo"]}
                    </div>
                  </div>
                  <div className={style.eventTimezone}>
                    <div className={style.eventTimezoneName}>London</div>
                    <div
                      className={`${style.eventTimezoneDate} ${style.border}`}
                    >
                      {convertedTimes["Europe/London"]}
                    </div>
                  </div>
                  <div className={`${style.eventTimezone} ${style.end}`}>
                    <div className={style.eventTimezoneName}>Paris</div>
                    <div className={style.eventTimezoneDate}>
                      {convertedTimes["Europe/Paris"]}
                    </div>
                  </div>
                  <div className={style.eventTimezone}>
                    <div className={style.eventTimezoneName}>Moscow</div>
                    <div
                      className={`${style.eventTimezoneDate} ${style.border}`}
                    >
                      {convertedTimes["Europe/Moscow"]}
                    </div>
                  </div>
                  <div className={style.eventTimezone}>
                    <div className={style.eventTimezoneName}>Hong Kong</div>
                    <div
                      className={`${style.eventTimezoneDate} ${style.border}`}
                    >
                      {convertedTimes["Asia/Hong_Kong"]}
                    </div>
                  </div>
                  <div className={`${style.eventTimezone} ${style.end}`}>
                    <div className={style.eventTimezoneName}>Seoul</div>
                    <div className={style.eventTimezoneDate}>
                      {convertedTimes["Asia/Seoul"]}
                    </div>
                  </div>
                </div>
              </div>
              <div className={style.eventSpeakersArea} id="speakers">
                <div className={style.eventSpeakersHeader}>Speakers</div>
                <div className={style.eventSpeakers}>
                  {eventSpeaker.length > 0 &&
                    eventSpeaker.map((speaker, idx) => (
                      <SpeakerCard
                        data={speaker}
                        // key={idx}
                      />
                    ))}
                </div>
              </div>
              <div className={style.eventAgendaArea} id="agenda">
                <div className={style.eventAgendasHeader}>Agendas</div>
                {eventAgenda !== null && eventAgenda.length > 0 && (
                  <AgendaTable data={eventAgenda} />
                )}
              </div>
            </section>
            <section
              className={`${style.eventRegisterArea}`}
              onClick={(e) => e.stopPropagation()}
            >
              <div className={`${style.eventContent} ${style.open}`}>
                <div className={style.moblieFooterDragBar}></div>
                <div className={style.eventTitle}>
                  <span>{event.EVENT_NM}</span>
                </div>
                <div className={style.eventDateTimeLabel}>Date & Time</div>
                <div className={style.eventDateTime}>
                  {eventTimeLabel(event.START_TIME, event.END_TIME)}
                </div>

                <div className={style.eventRegisterLabel}>Registration</div>
                <Form
                  name="basic"
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                  form={form}
                >
                  <Form.Item style={{ marginBottom: "0px" }}>
                    <div
                      className={style.eventRegisterBtnFormItem}
                      style={{ marginTop: "0px" }}
                    >
                      <button
                        className={`${style.eventRegisterLikeIconArea} ${
                          event.IS_FAVORITE === 1 ? style.active : ""
                        }`}
                        onClick={() =>
                          toggleFavorite(event.EVENT_ID, event.IS_FAVORITE)
                        }
                        aria-label="like"
                        type="button"
                      >
                        <img
                          className={style.eventRegisterLikeIcon}
                          alt="like-icon"
                          src={
                            process.env.REACT_APP_CLIENT_URL +
                            (event.IS_FAVORITE === 1
                              ? "img/register/like-active-icon.png"
                              : "img/register/like-icon.png")
                          }
                        ></img>
                      </button>
                      <button
                        className={style.eventRegisterAlarmIconArea}
                        onClick={() =>
                          toggleAlarm(event.EVENT_ID, event.IS_ALARM)
                        }
                        aria-label="alarm"
                        type="button"
                        style={{ display: "none" }}
                      >
                        <img
                          className={style.eventRegisterAlarmIcon}
                          alt="alarm-icon"
                          src={
                            process.env.REACT_APP_CLIENT_URL +
                            (event.IS_ALARM === 1
                              ? "img/register/alarm-active-icon.png"
                              : "img/register/alarm-icon.png")
                          }
                        ></img>
                      </button>
                      <Button
                        type="primary"
                        htmlType="submit"
                        //11회차 임시 주석
                         className={`${style.eventRegisterBtnValue} ${
                           regiBtunState === "Registered"
                             ? style.registered
                             : regiBtunState === "Ended"
                             ? style.registered
                             : regiBtunState === "Register"
                             ? style.register
                             : style.start
                         } ${event.STATUS_CD === "CD1402" ? "" : style.after}`}

                        //임시
                        /*
                        className={`${style.eventRegisterBtnValue} ${
                          style.registered
                        } ${event.STATUS_CD === "CD1402" ? "" : style.after}`}
                        */
                      >
                        {regiBtunState}
                      </Button>
                    </div>
                  </Form.Item>
                </Form>
              </div>
            </section>
          </div>
          {/* 모바일일때 하단에 고정되어있는 버튼 */}
          <div className={style.moblieFooterArea}>
            <button
              className={`${style.moblieFooterLikeIconArea} ${
                event.IS_FAVORITE === 1 ? style.active : ""
              }`}
              onClick={() => toggleFavorite(event.EVENT_ID, event.IS_FAVORITE)}
              aria-label="like"
              type="button"
            >
              <img
                className={style.moblieFooterLikeIcon}
                alt="like-icon"
                src={
                  process.env.REACT_APP_CLIENT_URL +
                  (event.IS_FAVORITE === 1
                    ? "img/register/like-active-icon.png"
                    : "img/register/like-icon.png")
                }
              ></img>
            </button>
            <button
              className={style.moblieFooterAlarmIconArea}
              onClick={() => toggleAlarm(event.EVENT_ID, event.IS_ALARM)}
              aria-label="alarm"
              type="button"
              style={{ display: "none" }}
            >
              <img
                className={style.moblieFooterAlarmIcon}
                alt="alarm-icon"
                src={
                  process.env.REACT_APP_CLIENT_URL +
                  (event.IS_ALARM === 1
                    ? "img/register/alarm-active-icon.png"
                    : "img/register/alarm-icon.png")
                }
              ></img>
            </button>
            <Button
              type="primary"
              htmlType="submit"
              //11회차 임시 주석
               className={`${style.moblieFooterBtn} ${
                 regiBtunState === "Registered"
                   ? style.registered
                   : regiBtunState === "Ended"
                   ? style.registered
                   : regiBtunState === "Register"
                   ? style.register
                   : style.start
               } ${event.STATUS_CD === "CD1402" ? "" : style.after}`}

              //임시
              /*
              className={`${style.eventRegisterBtnValue} ${style.registered} ${
                event.STATUS_CD === "CD1402" ? "" : style.after
              }`} 
              */
              onClick={() => onFinish()}
            >
              {regiBtunState}
            </Button>
          </div>
        </div>
      </Spin>
    </div>
  );
}
