import React, { useState, useRef, useEffect } from "react";
import style from "css/landing.module.css";
import useScrollCount from "components/hook/scrollCount";
import { useMediaQuery } from "react-responsive";
import axios from "axios";

function CountArea() {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const isTablet = useMediaQuery({
    query: "(min-width: 768px) and (max-width: 1023px)",
  });
  const counterRef = useRef();
  const [visitors, setVisitors] = useState(0);
  const [viewers, setViewers] = useState(0);
  const [countries, setCountries] = useState(0);
  const [satisfaction, setSatisfaction] = useState(0);

  const getVisitCount = () => {
    const url = "/user/visitCount";
    axios.get(url).then((res) => {
      if (res.status >= 200 && res.status < 300) {
        setVisitors(res.data.TOTAL);
        setViewers(6797);
        setCountries(76);
        setSatisfaction(100);
      }
    });
  };

  useEffect(() => {
    getVisitCount();
  }, []);

  const visitorsCount = useScrollCount(10, visitors, false);
  const viewersCount = useScrollCount(10, viewers, false);
  const countriesCount = useScrollCount(20, countries, false);
  const satisfactionCount = useScrollCount(30, satisfaction, true);

  return (
    <>
      <div className={style.counting_area_wrapper} ref={counterRef}>
        <div className={style.counting_area}>
          <div className={style.count_item}>
            <div className={style.count_content}>
              <div className={style.count_value} {...visitorsCount}></div>
              <div className={style.count_title}>Visitors</div>
            </div>

            <div className={style.count_text}>
              {isMobile || isTablet
                ? "JAM website visitors"
                : "JAM website visitors"}
            </div>
          </div>
          <div className={style.count_item}>
            <div className={style.count_content}>
              <div className={style.count_value} {...viewersCount}></div>
              <div className={style.count_title}>Viewers</div>
            </div>

            <div className={style.count_text}>
              {isMobile || isTablet
                ? "JAM webinar viewers"
                : "JAM webinar viewers"}
            </div>
          </div>
          <div className={style.count_item}>
            <div className={style.count_content}>
              <div className={style.count_value} {...countriesCount}></div>
              <div className={style.count_title}>Countries</div>
            </div>

            <div className={style.count_text}>
              {isMobile || isTablet
                ? "Number of countries attended JAM webinar"
                : "Number of countries attended JAM webinar"}
            </div>
          </div>
          <div className={style.count_item}>
            <div className={style.count_content}>
              <div className={style.count_value} {...satisfactionCount}></div>
              <div className={style.count_title}>Satisfaction</div>
            </div>

            <div className={style.count_text}>JAM webinar satisfactionn</div>
          </div>
        </div>
        <div className={style.text_description}>
          *Cumulative data since May 2021
        </div>
      </div>
    </>
  );
}

export default CountArea;
