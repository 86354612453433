import React from "react";
import style from "css/academy.module.css";
import AcademyItem from "components/academy/academyItem";
import { useNavigate } from "react-router-dom";

function AcademyList(props) {
  //navigate
  const navigate = useNavigate();
  const academyHandler = (type) => {
    navigate(process.env.REACT_APP_CLIENT_URL + "academy?view=" + type);
  };
  return (
    <div className={style.academy_wrapper}>
      <div className={style.title}>
        Academy<span>Watch our procedural video guides. </span>
      </div>
      <div className={style.list_type_tab}>
        <div
          className={`${
            props.type === "eptq"
              ? style.list_type_tab_item_active
              : style.list_type_tab_item
          }`}
          onClick={() => academyHandler("eptq")}
        >
          e.p.t.q.®
        </div>
        <div
          className={`${
            props.type === "epiticon"
              ? style.list_type_tab_item_active
              : style.list_type_tab_item
          }`}
          onClick={() => academyHandler("epiticon")}
        >
          epiticon®
        </div>
      </div>
      <div className={style.academy_title_wrapper}>
        <div className={style.academy_title}>
          {props.type === "eptq" ? "e.p.t.q.®" : "epiticon®"}
        </div>
        {/* <div className={style.academy_title_explain}>
          Want to know more about Jetema’s latest news, scientific findings, and
          global presence?
        </div> */}
      </div>
      <div className={style.academy_list}>
        {props.data.list.map((item) => (
          <AcademyItem key={item.ACADEMY_ID} data={item} />
        ))}
      </div>
    </div>
  );
}

export default AcademyList;
