import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import style from "css/product/productDetail.module.css";
import { useMediaQuery } from "react-responsive";
import { useDispatch } from "react-redux";
import { closeAlert, openAlert } from "redux/slices/alertSlice";

function Productdetail() {
  const { productId } = useParams();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ query: "(max-width: 880px)" });
  const isTablet = useMediaQuery({
    query: "(min-width: 881px) and (max-width: 1370px)",
  });
  const navigate = useNavigate();
  const viewBtnHandler = () => {
    navigate(process.env.REACT_APP_CLIENT_URL + "contactUs");
  };

  const catalogue = () => {
    if (productId === "1") {
      window.open(
        process.env.REACT_APP_CLIENT_URL +
          "file/product/[THE Toxin] 100U brochure (ENG)_MSL-BT-2208-07.pdf"
      );
    } else if (productId === "2") {
      window.open(
        process.env.REACT_APP_CLIENT_URL +
          "file/product/[e.p.t.q.] brochure (ENG)_MSL-EQ-2304-07.pdf"
      );
    } else if (productId === "3") {
      window.open(
        process.env.REACT_APP_CLIENT_URL +
          "file/product/[epiticon] brochure (ENG)_MSL-EC-2211-11.pdf"
      );
    } else if (productId === "4") {
      const obj = {
        title: "Notice",
        content: "This Catalog will be updated soon!",
        submitEventHandler: () => {
          dispatch(closeAlert());
        },
      };
      dispatch(openAlert(obj));
      // window.open(process.env.REACT_APP_CLIENT_URL + "file/product/");
    } else if (productId === "5") {
      window.open(
        process.env.REACT_APP_CLIENT_URL +
          "file/product/230927_vitten brochure_Outline_print ★.pdf"
      );
    } else if (productId === "6") {
      window.open(
        process.env.REACT_APP_CLIENT_URL +
          "file/product/[By.derm] brochure (ENG)_MSL-CS-2303-15.pdf"
      );
    }
  };
  window.scrollTo(0, 0);
  const title1 = () => {
    if (productId === "1") {
      return <div>Botulinum Toxin Type A, THE TOXIN</div>;
    } else if (productId === "2") {
      return <div>Hyaluronic Acid Filler, e.p.t.q.®</div>;
    } else if (productId === "3") {
      return <div>PDO Thread, epiticon®</div>;
    } else if (productId === "4") {
      return <div>Skin Booster, EXO'LUTION</div>;
    } else if (productId === "5") {
      return <div>Meso Solution, vitten®</div>;
    } else if (productId === "6") {
      return <div>Cosmetic, e.p.t.q. By.derm®</div>;
    }
  };

  const title2 = () => {
    if (productId === "1") {
      return (
        <div>
          Manufactured with strain officially introduced from European company
        </div>
      );
    } else if (productId === "2") {
      return (
        <div>
          Exquisite and safe filler that satisfies strict safety standards
        </div>
      );
    } else if (productId === "3") {
      return (
        <div>
          New lifting thread developed with patented technology of multi-dual
          acting cogs
        </div>
      );
    } else if (productId === "4") {
      return <div>MAXI EFFECT SOLUTION FOR SKIN REJUVENATION</div>;
    } else if (productId === "5") {
      return <div>Total meso solution to maintain healthy skin</div>;
    } else if (productId === "6") {
      return <div>Customized solution for sensitive skin</div>;
    }
  };

  const detail_info = () => {
    if (productId === "1") {
      return (
        <div className={style.info_des_div}>
          <div className={style.info_text_div}>
            <div className={style.title_3}>Botulinum Toxin</div>
            <div>
              <div className={style.text_div}>
                <div className={style.text_mark1}></div>
                <div className={style.text1}>
                  The 1st Korean botulinum toxin type A product manufactured
                  with legally in-licensed strain.
                </div>
              </div>
              <div className={style.text_div}>
                <div className={style.text_mark1}></div>
                <div className={style.text1}>
                  High purity product with low immunogenicity risk.
                </div>
              </div>
              <div className={style.text_div}>
                <div className={style.text_mark1}></div>
                <div className={style.text1}>
                  JETEMA is the only company possessing commercialized strain
                  types A, B, and E in the world
                </div>
              </div>
            </div>
          </div>
          <div className={style.info_button_div}>
            <div className={style.blu_btn} onClick={() => viewBtnHandler()}>
              Contact Us
            </div>
            <div className={style.org_btn} onClick={() => catalogue()}>
              Catalogue
            </div>
          </div>
        </div>
      );
    } else if (productId === "2") {
      return (
        <div className={style.info_des_div}>
          <div className={style.info_text_div}>
            <div className={style.title_3}>e.p.t.q.® Lines</div>
            <div>
              <div className={style.text_div}>
                <div className={style.text_mark1}></div>
                <div className={style.text2}>
                  Modification Degree (MoD) at 1% level, the lowest among
                  competing products, assuring its utmost safety.
                </div>
              </div>
              <div className={style.text_div}>
                <div className={style.text_mark1}></div>
                <div className={style.text2}>CE, ANVISA approved.</div>
              </div>
              <div className={style.text_div}>
                <div className={style.text_mark1}></div>
                <div className={style.text2}>
                  Remarkable quality and worldwide presence in more than 60
                  countries.
                </div>
              </div>
            </div>
          </div>
          <div>
            <img
              alt=""
              src={
                process.env.REACT_APP_CLIENT_URL +
                (isTablet
                  ? "img/product/certification2.png"
                  : isMobile
                  ? "img/product/certification3.png"
                  : "img/product/certification1.png")
              }
            />
          </div>
          <div className={style.info_button_div}>
            <div className={style.blu_btn} onClick={() => viewBtnHandler()}>
              Contact Us
            </div>
            <div className={style.org_btn} onClick={() => catalogue()}>
              Catalogue
            </div>
          </div>
        </div>
      );
    } else if (productId === "3") {
      return (
        <div className={style.info_des_div}>
          <div className={style.info_text_div}>
            <div className={style.title_3}>PDO Thread</div>
            <div>
              <div className={style.text_div3}>
                <div className={style.text_mark1}></div>
                <div className={style.text3}>epiticon® ORIGINAL</div>
              </div>
              <div className={style.text_div2}>
                <div className={style.text_mark2}></div>
                <div className={style.text2}>
                  Fixed-type thread having dual-acting cogs with patented
                  design.
                </div>
              </div>
              <div className={style.text_div3}>
                <div className={style.text_mark1}></div>
                <div className={style.text3}>epiticon® BI</div>
              </div>
              <div className={style.text_div2}>
                <div className={style.text_mark2}></div>
                <div className={style.text2}>
                  Bi-directional thread for uniform and natural contouring.
                </div>
              </div>
              <div className={style.text_div3}>
                <div className={style.text_mark1}></div>
                <div className={style.text3}>epiticon® Thin</div>
              </div>
              <div className={style.text_div2}>
                <div className={style.text_mark2}></div>
                <div className={style.text2}>
                  Thin thread for more accessible and comfortable lifting.
                </div>
              </div>
              <div className={style.text_div3}>
                <div className={style.text_mark1}></div>
                <div className={style.text3}>epiticon® JAMBER</div>
              </div>
              <div className={style.text_div2}>
                <div className={style.text_mark2}></div>
                <div className={style.text2}>
                  Next-generation coiled thread, providing a collagen framework.
                </div>
              </div>
            </div>
          </div>
          <div className={style.info_button_div}>
            <div className={style.blu_btn} onClick={() => viewBtnHandler()}>
              Contact Us
            </div>
            <div className={style.org_btn} onClick={() => catalogue()}>
              Catalogue
            </div>
          </div>
        </div>
      );
    } else if (productId === "4") {
      return (
        <div className={style.info_des_div}>
          <div className={style.info_text_div}>
            <div className={style.title_3}>EXO’LUTION</div>
            <div>
              <div className={style.text_div}>
                <div className={style.text_mark1}></div>
                <div className={style.text1}>
                  61 Effective Ingredients (vial 1) to improve Total Skin
                  Quality
                </div>
              </div>
              <div className={style.text_div}>
                <div className={style.text_mark1}></div>
                <div className={style.text1}>
                  6 Billion Plant Exosome (vial 2) to rejuvenate skin
                </div>
              </div>
              <div className={style.text_div}>
                <div className={style.text_mark1}></div>
                <div className={style.text1}>
                  Non-irritating and Safe Product proven by the skin test 1)
                </div>
              </div>
              <div className={style.text_div}>
                <div className={style.text_mark1}></div>
                <div className={style.text1}>
                  Improved Effects of moisture content, dermal den -sity 2)
                </div>
              </div>
            </div>
          </div>
          <div className={style.info_button_div}>
            <div className={style.blu_btn} onClick={() => viewBtnHandler()}>
              Contact Us
            </div>
            <div className={style.org_btn} onClick={() => catalogue()}>
              Catalogue
            </div>
          </div>
        </div>
      );
    } else if (productId === "5") {
      return (
        <div className={style.info_des_div}>
          <div className={style.info_text_div}>
            <div className={style.title_3}>vitten®</div>
            <div>
              <div className={style.text_div}>
                <div className={style.text_mark1}></div>
                <div className={style.text4}>
                  vitten® is a total meso solution that supplements the
                  essential elements of the skin. The extracellular matrix (ECM)
                  supports the skin structure and gives the skin elasticity and
                  tensile force. vitten® mesosolution provides a diverse
                  supplement for healthy extracellular matrix maintenance.
                </div>
              </div>
            </div>
          </div>
          <div className={style.info_button_div}>
            <div className={style.blu_btn} onClick={() => viewBtnHandler()}>
              Contact Us
            </div>
            <div className={style.org_btn} onClick={() => catalogue()}>
              Catalogue
            </div>
          </div>
        </div>
      );
    } else if (productId === "6") {
      return (
        <div className={style.info_des_div2}>
          <div className={style.info_text_div2}>
            <div className={style.title_3}>Cosmetics</div>
            <div>
              <div className={style.text_div}>
                <div className={style.text_mark1}></div>
                <div className={style.text3}>
                  e.p.t.q. By.derm® Intensive Cream
                </div>
              </div>
              <div className={style.text_div2}>
                <div className={style.text_mark3}>
                  <div className={style.text_mark_c} />
                </div>
                <div className={style.text2}>
                  High-moisturizing cream for skin with damaged skin barrier
                  such as dry skin
                </div>
              </div>{" "}
              <div className={style.text_div2}>
                <div className={style.text_mark3}>
                  <div className={style.text_mark_c} />
                </div>
                <div className={style.text1}>
                  3 Key ingredients : Evening primrose oil (Gamma Linolenic acid
                  9%), Ceramide, Cholesterol
                </div>
              </div>
              <div className={style.text_div}>
                <div className={style.text_mark1}></div>
                <div className={style.text3}>e.p.t.q. By.derm® Plus Mask</div>
              </div>
              <div className={style.text_div2}>
                <div className={style.text_mark2}></div>
                <div className={style.text2}>
                  Plus Mask Hypoallergenic mask containing more natural extract
                  than water
                </div>
              </div>
              <div className={style.text_div}>
                <div className={style.text_mark1}></div>
                <div className={style.text3}>
                  e.p.t.q. By.derm® PLANT-EXOSOME moisture mist
                </div>
              </div>
              <div className={style.text_div2}>
                <div className={style.text_mark2}></div>
                <div className={style.text2}>
                  Gentle mist for sensitive skin
                </div>
              </div>
            </div>
          </div>
          <div className={style.info_button_div}>
            <div className={style.blu_btn} onClick={() => viewBtnHandler()}>
              Contact Us
            </div>
            <div className={style.org_btn} onClick={() => catalogue()}>
              Catalogue
            </div>
          </div>
        </div>
      );
    }
  };
  return (
    <div className={style.main_wrapper}>
      <div className={style.info_button_div_mobile}>
        <div className={style.blu_btn_mobile} onClick={() => viewBtnHandler()}>
          Contact Us
        </div>
        <div className={style.org_btn_mobile} onClick={() => catalogue()}>
          Catalogue
        </div>
      </div>

      <div className={style.title_sec}>
        <div className={style.title_1}>{title1()}</div>
        <div className={style.title_2}>{title2()}</div>
      </div>

      <div className={style.info_sec}>
        <div className={style.info_img_div}>
          <img
            style={{ width: "100%", height: "100%" }}
            alt=""
            src={
              process.env.REACT_APP_CLIENT_URL +
              "img/product/" +
              productId +
              (isMobile ? "M" : isTablet ? "T" : "") +
              ".png"
            }
          ></img>
        </div>
        {detail_info()}
      </div>

      <div className={productId === "6" ? style.PS2 : style.PS}>
        Products Specification
      </div>
      <div className={style.PI}>
        <img
          className={style.PI_IMG}
          alt=""
          src={
            process.env.REACT_APP_CLIENT_URL +
            "img/product/sp" +
            productId +
            (isMobile ? "M" : isTablet ? "T" : "") +
            ".png"
          }
        ></img>
      </div>
    </div>
  );
}

export default Productdetail;
