import React from "react";
import style from "css/landing.module.css";
import { Image, Skeleton } from "antd";
import { useMediaQuery } from "react-responsive";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import utc from "dayjs-timezone-iana-plugin";
import timezone from "dayjs-plugin-utc";

// dayjs에 플러그인 등록
dayjs.extend(utc);
dayjs.extend(timezone);

function LibraryItem(props) {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const isTablet = useMediaQuery({
    query: "(min-width: 768px) and (max-width: 1023px)",
  });
  const navigate = useNavigate();
  const clickHandler = (data) => {
    navigate(process.env.REACT_APP_CLIENT_URL + "library/" + data);
  };

  function eventTimeLabel(startDateTime, endDateTime) {
    // dayjs 객체 생성
    const startTime = dayjs(startDateTime);
    const endTime = dayjs(endDateTime);

    // formatting (ex. 2023.10.14 SAT, 11:30 - 13:00 (GMT))
    const formattedStartDateTime = startTime.format("YYYY.MM.DD(ddd) HH:mm");
    const formattedEndDateTime = endTime.format("HH:mm");
    const formattedDateTimeRange = `${formattedStartDateTime} - ${formattedEndDateTime} (GMT)`;

    return formattedDateTimeRange;
  }
  return (
    <motion.div
      className={style.webinar_library_item}
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true, amount: 0 }}
      variants={{
        offscreen: {
          opacity: 0,
          scale: 0.5,
          x: -200,
        },
        onscreen: {
          opacity: 1,
          scale: 1,
          x: 0,
          transition: {
            duration: 1.5,
            delay: 0.8,
            ease: [0, 0.71, 0.2, 1.01],
          },
        },
      }}
    >
      <div className={style.webinar_library_info_wrap}>
        {props.data.LANDING_IMG === "" ? (
          <div className={style.webinar_library_img_skeleton}>
            <Skeleton.Image
              active
              style={
                isMobile
                  ? { width: "138px", height: "138px" }
                  : isTablet
                  ? { width: "94px", height: "94px" }
                  : { width: "177px", height: "175px" }
              }
            />
          </div>
        ) : (
          <Image
            className="cursor"
            onClick={() => clickHandler(props.data.EVENT_ID)}
            preview={false}
            width={isMobile ? 138 : isTablet ? 94 : 175}
            height={isMobile ? 138 : isTablet ? 94 : 175}
            src={props.data.LANDING_IMG}
          />
        )}
        <div className={style.webinar_library_info}>
          <div className={style.webinar_library_info_text}>
            <div className={style.webinar_library_index}>
              {props.data.EVENT_NM === ""
                ? ""
                : props.data.EVENT_NM.split(" ")[0]}
            </div>
            <div className={style.webinar_library_title}>
              {/* {props.data.TITLE} */}
              Jetema Academic Meeting
            </div>
          </div>
          <div className={style.webinar_library_datetime}>
            {/* {moment(props.data.START_TIME).format("YYYY.MM.DD(ddd) HH:mm") +
              " - " +
              moment(props.data.END_TIME).format("HH:mm") +
              "(GMT)"} */}
            {eventTimeLabel(props.data.START_TIME, props.data.END_TIME)}
          </div>
          {isMobile ? (
            <div
              className={style.webinar_library_btn}
              onClick={() => clickHandler(props.data.EVENT_ID)}
            >
              <img
                alt=""
                src={
                  process.env.REACT_APP_CLIENT_URL +
                  "img/landing/arrow_black.png"
                }
              />
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      {isMobile ? (
        <></>
      ) : (
        <div
          className={style.webinar_library_btn_wrap}
          onClick={() => clickHandler(props.data.EVENT_ID)}
        >
          <div className={style.webinar_library_btn}>
            <img
              alt=""
              src={
                process.env.REACT_APP_CLIENT_URL + "img/landing/arrow_black.png"
              }
            />
          </div>
        </div>
      )}
    </motion.div>
  );
}

export default LibraryItem;
