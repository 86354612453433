import React, { useEffect } from "react";
import style from "css/template.module.css";
import { ReactComponent as JamWhite } from "asset/common/jam_logo_white.svg";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
/**
 * Version : 1.0
 * 작성일자 : 2023-12-11
 * 작성자 : 권도훈
 * 설명 : 공통 템플릿 영역
 */
function Footer() {
  //selector
  const userPayload = useSelector((state) => state.user);
  //navigate
  const navigate = useNavigate();
  const menuHandler = (e) => {
    navigate(process.env.REACT_APP_CLIENT_URL + e);
  };
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const isTablet = useMediaQuery({
    query: "(min-width: 768px) and (max-width: 1023px)",
  });

  const youtubeUrl = "https://www.youtube.com/@jetema1834";
  const instagramUrl = "https://www.instagram.com/eptq_official/";
  const jetemaUrl = "https://www.jetema.com/";
  const eptqUrl = "https://epitiquefiller.com/";

  //footer 예외 페이지
  const location = useLocation();
  let footerStyle = {
    display: location.pathname === "/mypage" ? "none" : "flex",
  };

  return (
    <div className={style.footer} style={footerStyle}>
      {isMobile ? (
        <></>
      ) : (
        <div className={style.footer_logo}>
          <JamWhite
            width={isTablet ? "100" : "147"}
            height={isTablet ? "32" : "50"}
            fill="#1f299c"
          />
        </div>
      )}
      <div className={style.footer_wrap}>
        <div className={style.footer_content}>
          <div className={style.footer_contactus}>
            <div className={style.footer_contactus_text}>
              Do you have any questions?
            </div>
            <div
              className={style.footer_contactus_btn}
              onClick={() => menuHandler("contactus")}
            >
              Contact Us
            </div>
          </div>
          {isMobile ? (
            <></>
          ) : (
            <div className={style.footer_sitemap}>
              <div className={style.footer_sitemap_menu}>
                <div className={style.footer_sitemap_title}>MENU</div>
                <div className={style.footer_sitemap_list}>
                  <div className={style.footer_sitemap_list_item_wrap}>
                    <div
                      className={style.footer_sitemap_list_item}
                      onClick={() => menuHandler("register")}
                    >
                      Register
                    </div>
                    <div
                      className={style.footer_sitemap_list_item}
                      onClick={() => menuHandler("library")}
                    >
                      Library
                    </div>
                    <div
                      className={style.footer_sitemap_list_item}
                      onClick={() => menuHandler("product")}
                    >
                      Product
                    </div>
                  </div>
                  <div className={style.footer_sitemap_list_item_wrap}>
                    <div
                      className={style.footer_sitemap_list_item}
                      onClick={() => menuHandler("newsletter")}
                    >
                      Newsletter
                    </div>
                    <div
                      className={style.footer_sitemap_list_item}
                      onClick={() => menuHandler("contactus")}
                    >
                      Contact us
                    </div>
                    {userPayload.id !== "" ? (
                      <div
                        className={style.footer_sitemap_list_item}
                        onClick={() => menuHandler("mypage")}
                      >
                        My page
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
              <div className={style.footer_sitemap_menu}>
                <div className={style.footer_sitemap_title}>
                  SNS & Family site
                </div>
                <div className={style.footer_sitemap_list}>
                  <div className={style.footer_family_list_item_wrap}>
                    <div
                      className={style.footer_sitemap_list_item}
                      onClick={() => window.open(youtubeUrl)}
                    >
                      <div
                        className={style.footer_sitemap_list_item_icon_wrapper}
                      >
                        <img
                          alt=""
                          src={
                            process.env.REACT_APP_CLIENT_URL +
                            "img/landing/youtube.png"
                          }
                          style={{
                            width: isTablet ? "18px" : "27px",
                          }}
                        />
                      </div>
                      Youtube
                    </div>
                    <div
                      className={style.footer_sitemap_list_item}
                      onClick={() => window.open(instagramUrl)}
                    >
                      <div
                        className={style.footer_sitemap_list_item_icon_wrapper}
                      >
                        <img
                          alt=""
                          src={
                            process.env.REACT_APP_CLIENT_URL +
                            "img/landing/instagram.png"
                          }
                          style={{
                            width: isTablet ? "16px" : "26px",
                          }}
                        />
                      </div>
                      Instagram
                    </div>

                    <div
                      className={style.footer_sitemap_list_item}
                      onClick={() => window.open(jetemaUrl)}
                    >
                      <div
                        className={style.footer_sitemap_list_item_icon_wrapper}
                      >
                        <img
                          alt=""
                          src={
                            process.env.REACT_APP_CLIENT_URL +
                            "img/landing/jetema.png"
                          }
                          style={{
                            width: isTablet ? "15px" : "23px",
                          }}
                        />
                      </div>
                      Jetema
                    </div>
                    <div
                      className={style.footer_sitemap_list_item}
                      onClick={() => window.open(eptqUrl)}
                    >
                      <div
                        className={style.footer_sitemap_list_item_icon_wrapper}
                      >
                        <img
                          alt=""
                          src={
                            process.env.REACT_APP_CLIENT_URL +
                            "img/landing/eptq.png"
                          }
                          style={{
                            width: isTablet ? "30px" : "39px",
                          }}
                        />
                      </div>
                      e.p.t.q.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {isMobile ? (
          <div className={style.footer_logo}>
            <JamWhite width="99" height="31" fill="#1f299c" />
          </div>
        ) : (
          <></>
        )}
        <div className={style.footer_copyright}>
          <span>
            {isMobile
              ? "© 2023 Jetema Academic Meeting. COPYRIGHT Jetema Co., Ltd.\nALL RIGHTS RESERVED."
              : "© 2023 Jetema Academic Meeting. COPYRIGHT Jetema Co., Ltd. ALL RIGHTS RESERVED."}
          </span>
        </div>
      </div>
    </div>
  );
}

export default Footer;
