import React, { useCallback, useEffect, useRef, useState } from "react";
import style from "css/mypage.module.css";
import axios from "axios";
import PropTypes from "prop-types";
import { Image, Skeleton } from "antd";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Grid } from "swiper/modules";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/navigation";
import { useMediaQuery } from "react-responsive";
import Loading from "components/common/loading";
import { useDispatch } from "react-redux";
import { closeAlert, openConfirm } from "redux/slices/alertSlice";
import { ReactComponent as FavortiteIcon } from "asset/common/favorite.svg";
import { useNavigate } from "react-router-dom";

function Library() {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const isTablet = useMediaQuery({
    query: "(min-width: 768px) and (max-width: 1023px)",
  });
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const [init, setInit] = useState(true);
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);

  const getList = useCallback(() => {
    setLoading(true);
    const url = "/mypage/library";
    axios
      .get(url)
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          setList(res.data);
        }
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (init) {
      getList();
      setInit(false);
    }
  }, [getList, init]);

  const refreshEvent = () => {
    setInit(true);
  };

  const previewRender = () => {
    let count;
    if (isTablet) {
      count = 3;
    } else if (isMobile) {
      count = 2;
    } else {
      count = 4;
    }
    return count;
  };

  const spaceRender = () => {
    let count;
    if (isTablet) {
      count = 20;
    } else if (isMobile) {
      count = 11;
    } else {
      count = 17;
    }
    return count;
  };

  // const getDetailEvent = (id) => {
  //   navigate(process.env.REACT_APP_CLIENT_URL + "newsletter?id=" + id);
  // };
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          {list.length > 0 && (
            <section className={style.slide_btn_area}>
              <div ref={prevRef} className={style.slide_btn}>
                <img
                  alt=""
                  src={
                    process.env.REACT_APP_CLIENT_URL +
                    "img/landing/arrow_black.png"
                  }
                />
              </div>
              <div ref={nextRef} className={style.slide_btn}>
                <img
                  alt=""
                  src={
                    process.env.REACT_APP_CLIENT_URL +
                    "img/landing/arrow_black.png"
                  }
                />
              </div>
            </section>
          )}

          {/* <section className={style.newsletter}> */}
          {list.length > 0 && (
            <section className={style.library_swiper}>
              <Swiper
                className={style.swiper}
                navigation={{
                  prevEl: prevRef.current,
                  nextEl: nextRef.current,
                }}
                // grid={{
                //   rows: isMobile ? 2 : 0,
                // }}
                modules={[Navigation, Grid]}
                loop={false}
                slidesPerView={previewRender()}
                spaceBetween={spaceRender()}
                onSwiper={(swiper) => {
                  setTimeout(() => {
                    swiper.params.navigation.prevEl = prevRef.current;
                    swiper.params.navigation.nextEl = nextRef.current;
                    swiper.navigation.destroy();
                    swiper.navigation.init();
                    swiper.navigation.update();
                  });
                }}
              >
                {list.map((item, idx) => (
                  <SwiperSlide
                    key={idx}
                    className={style.library_swiper_item}
                    // onClick={() => getDetailEvent(item.id)}
                  >
                    <Item
                      id={item.id}
                      title={item.title}
                      description={item.description}
                      img={item.img}
                      startDate={item.startDate}
                      startTime={item.startTime}
                      endTime={item.endTime}
                      color={item.color}
                      refreshEvent={refreshEvent}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </section>
          )}
          {/* </section> */}
        </>
      )}
    </>
  );
}

function Item(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getDetailEvent = () => {
    navigate(process.env.REACT_APP_CLIENT_URL + "library/" + props.id);
  };
  const deleteEvent = (e) => {
    e.stopPropagation();
    const obj = {
      title: "Notice",
      content: "Would you like to remove the bookmark.",
      submitEventHandler: () => {
        const config = {
          params: {
            ID: props.id,
          },
        };
        axios
          .delete("/mypage/library", config)
          .then((res) => {
            if (res.status >= 200 && res.status < 300) {
              props.refreshEvent();
            }
          })
          .catch((err) => {
            console.log(err);
          });
        dispatch(closeAlert());
      },
    };
    dispatch(openConfirm(obj));
  };
  return (
    <>
      <div className={style.library_item_img}>
        <div
          className={`${style.like_btn} ${style.top}`}
          onClick={(e) => deleteEvent(e)}
        >
          <FavortiteIcon />
        </div>
        {props.img === "" ? (
          <Skeleton.Image active className={style.skeleton} />
        ) : (
          <Image
            preview={false}
            width={"100%"}
            height={"100%"}
            src={props.img}
          />
        )}
        <article
          className={style.library_item_img_article}
          style={{ color: props.color }}
        >
          <p className={style.library_title}>{props.title}</p>
          <p className={style.library_comment}>{props.description}</p>
        </article>
      </div>
      <div className={style.library_item_footer}>
        <article className={style.newsletter_description}>
          <p className={style.newsletter_comment}>{props.startDate}</p>
          <p className={style.newsletter_comment}>
            {props.startTime}-{props.endTime}(GMT)
          </p>
        </article>
        <button className={style.library_btn} onClick={getDetailEvent}>
          View More
        </button>
      </div>
    </>
  );
}

Item.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  img: PropTypes.string,
  isTablet: PropTypes.bool,
  isMobile: PropTypes.bool,
  startDate: PropTypes.string,
  startTime: PropTypes.string,
  endTime: PropTypes.string,
  color: PropTypes.string,
  refreshEvent: PropTypes.func,
};

export default Library;
