import React, { useState } from "react";
import style from "css/account.module.css";
import Auth from "./auth";
import Email from "./email";
import Info from "./info";
import { ReactComponent as Icon } from "asset/account/join.svg";

function Signup() {
  const [type, setType] = useState("email");
  const [email, setEmail] = useState("");

  const bannerRender = () => {
    switch (type) {
      case "email":
        return <EmailBanner />;
      case "auth":
        return <AuthBanner />;
      case "info":
        return <InfoBanner />;
      default:
        return <></>;
    }
  };

  const contentRender = () => {
    switch (type) {
      case "email":
        return <Email setType={setType} setEmail={setEmail} />;
      case "auth":
        return <Auth setType={setType} email={email} />;
      case "info":
        return <Info email={email} />;
      default:
        return <></>;
    }
  };

  return (
    <>
      {bannerRender()}
      <Step type={type} />
      {contentRender()}
    </>
  );
}

export default Signup;

function Step({ type }) {
  return (
    <section className={style.step_wrapper}>
      <div className={`${style.container} ${style.step_container}`}>
        <StepItem
          number={1}
          type={type}
          activeType={"email"}
          activeList={["email", "auth", "info", "success"]}
          comment={"Enter your Email"}
        />
        <div className={style.step_line} />
        <StepItem
          number={2}
          type={type}
          activeType={"auth"}
          activeList={["auth", "info", "success"]}
          comment={"Enter Code"}
        />
        <div className={style.step_line} />
        <StepItem
          number={3}
          type={type}
          activeType={"info"}
          activeList={["info", "success"]}
          comment={"Enter Information"}
        />
        <div className={style.step_line} />
        <StepItem
          number={4}
          type={type}
          activeType={"success"}
          activeList={["success"]}
          comment={"Join completed"}
        />
      </div>
    </section>
  );
}

function StepItem({ number, type, activeType, activeList, comment }) {
  return (
    <div className={style.step_item}>
      <div
        className={
          activeType === type
            ? `${style.step_round} ${style.active}`
            : activeList.includes(type)
            ? `${style.step_round} ${style.active}`
            : style.step_round
        }
      >
        {activeType === type ? <Icon /> : number}
      </div>
      <p
        className={
          activeType === type
            ? `${style.step_comment} ${style.active}`
            : activeList.includes(type)
            ? `${style.step_comment} ${style.black}`
            : style.step_comment
        }
      >
        {comment}
      </p>
    </div>
  );
}

function EmailBanner() {
  return (
    <article className={style.success_banner}>
      <p className={style.title}>Join us at JAM</p>
      <article className={style.description}>
        <p>Join us at JAM to connect with the best experts</p>
        <p>from all over the world to present their latest and studies</p>
        <p>and answer all your questions through the chat.</p>
      </article>
    </article>
  );
}
function AuthBanner() {
  return (
    <article className={style.success_banner}>
      <p className={style.title}>Check your Email for a Code</p>
      <article className={style.description}>
        <p>Please enter the verification code sent to your email</p>
      </article>
    </article>
  );
}
function InfoBanner() {
  return (
    <article className={style.success_banner}>
      <p className={style.title}>Let’s create your account.</p>
      <article className={style.description}>
        <p>Please enter the information</p>
      </article>
    </article>
  );
}
