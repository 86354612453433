import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Result } from 'antd'
import { useTranslation } from 'react-i18next';

/**
 * 파일명: notFound.js
 * 작성자: 권도훈
 * 작성일자: 23.04.06
 * 설명: 404 page
 */
function NotFound() {
    //navigate
    const navigate = useNavigate();
    //translation
    const { t } = useTranslation();

    const pageBackEventHandler = () => {
        navigate(-1);
    }

    return (
        <Result
            status="404"
            title="404"
            subTitle={t("MESSAGE.CANNOT_FIND_PAGE")}
            style={{
                width: "100%",
                height: "100%"
            }}
            extra={
                <Button
                    type="primary"
                    onClick={() => pageBackEventHandler()}
                >
                    {t("BTN.BACK")}
                </Button>}
        />
    )
}

export default NotFound
