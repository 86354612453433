import React, { useState, useEffect, useCallback } from "react";
import style from "css/landing.module.css";
// import { Swiper, SwiperSlide } from "swiper/react";
// import { Pagination, Autoplay } from "swiper/modules";
import { motion } from "framer-motion";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { Cookies } from "react-cookie";
import axios from "axios";

function SwiperArea() {
  //cookie
  const cookies = new Cookies();

  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const navigate = useNavigate();

  const [registerYn, setRegisterYn] = useState("N");
  const [titleText, setTitleText] = useState("");
  const [count, setCount] = useState(0);

  const getRegisterEvent = useCallback(() => {
    const data = {
      params: {},
    };

    axios.get("/event/landing/registerCheck", data).then((res) => {
      if (res.status === 200) {
        setRegisterYn(res.data.REGISTER_YN);
      }
    });
  }, []);

  useEffect(() => {
    getRegisterEvent();
  }, [getRegisterEvent]);

  useEffect(() => {
    let typingText = "Jetema Academic Meeting";
    const interval = setInterval(() => {
      setTitleText((Text) => {
        let updated = Text;
        updated = Text + typingText[count];
        return updated;
      });
      setCount(count + 1);
    }, 100);
    count === typingText.length && clearInterval(interval);
    return () => clearInterval(interval);
  });

  return (
    <div className={style.swipper_area}>
      <div
        className={style.swipper_item_background}
        style={{
          backgroundImage: isMobile
            ? "url('" +
              process.env.REACT_APP_CLIENT_URL +
              "img/landing/main_mobile.png')"
            : "url('" +
              process.env.REACT_APP_CLIENT_URL +
              "img/landing/main-visual.png')",
        }}
      />
      <div className={style.swipper_item}>
        <div className={style.swipper_contents}>
          {isMobile ? (
            <div className={style.swipper_title_area}>
              <div className={style.swipper_title}>{titleText}</div>
            </div>
          ) : (
            <div className={style.swipper_title}>{titleText}</div>
          )}

          {isMobile ? (
            <div className={style.swipper_explanation}>
              <motion.div
                className={style.swipper_text}
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0 }}
                variants={{
                  offscreen: {
                    opacity: 0,
                    scale: 0.5,
                  },
                  onscreen: {
                    opacity: 1,
                    scale: 1,
                    transition: {
                      duration: 0.8,
                      delay: 0.5,
                      ease: [0, 0.71, 0.2, 1.01],
                    },
                  },
                }}
              >
                <div className={style.swipper_text_item}>
                  It is a community that shares
                </div>
                <div className={style.swipper_text_item}>
                  the advance of science and growth.
                </div>
              </motion.div>
              {/* <div className={style.swipper_text}>
                <div className={style.swipper_text_item}>
                  It is a community that shares
                </div>
                <div className={style.swipper_text_item}>
                  the advance of science and growth.
                </div>
              </div> */}

              {
                //11회차 사전등록 전 임시 주석 시작
                 registerYn === "Y" ? (
                   <div
                     className={style.rotate_item}
                     // onClick={() =>
                     //   cookies.get("AuthToken") === undefined
                     //     ? navigate(process.env.REACT_APP_CLIENT_URL + "signup")
                     //     : navigate(process.env.REACT_APP_CLIENT_URL + "register")
                     // }
                     onClick={() =>
                       navigate(process.env.REACT_APP_CLIENT_URL + "register")
                     }
                   >
                     <img
                       className={style.rotate_img}
                       alt=""
                       src={
                         process.env.REACT_APP_CLIENT_URL +
                         "img/landing/rotate.png"
                       }
                     />
                     <img
                       className={style.arrow_img}
                       alt=""
                       src={
                         process.env.REACT_APP_CLIENT_URL +
                         "img/landing/arrow_right.png"
                       }
                     />
                   </div>
                 ) : 
                //11회차 사전등록 전 임시 주석 종료
                cookies.get("AuthToken") === undefined ? (
                  //이벤트 등록 X 비로그인 시 Become a member
                  <div
                    className={style.rotate_item}
                    // onClick={() =>
                    //   cookies.get("AuthToken") === undefined
                    //     ? navigate(process.env.REACT_APP_CLIENT_URL + "signup")
                    //     : navigate(process.env.REACT_APP_CLIENT_URL + "register")
                    // }
                    onClick={() =>
                      navigate(process.env.REACT_APP_CLIENT_URL + "signup")
                    }
                  >
                    <img
                      className={style.rotate_img}
                      alt=""
                      src={
                        process.env.REACT_APP_CLIENT_URL +
                        "img/landing/rotate_member.png"
                      }
                    />
                    <img
                      className={style.arrow_img}
                      alt=""
                      src={
                        process.env.REACT_APP_CLIENT_URL +
                        "img/landing/arrow_right.png"
                      }
                    />
                  </div>
                ) : (
                  //이벤트 등록 X 로그인 시 미출력
                  <></>
                )
              }
            </div>
          ) : (
            <div className={style.swipper_explanation}>
              <motion.div
                className={style.swipper_text}
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0 }}
                variants={{
                  offscreen: {
                    opacity: 0,
                    scale: 0.5,
                  },
                  onscreen: {
                    opacity: 1,
                    scale: 1,
                    transition: {
                      duration: 0.8,
                      delay: 0.5,
                      ease: [0, 0.71, 0.2, 1.01],
                    },
                  },
                }}
              >
                <div className={style.swipper_text_item}>
                  <span className={style.swipper_border}></span>
                  It is a community that shares
                </div>
                <div className={style.swipper_text_item}>
                  the advance of science and growth.
                </div>
              </motion.div>

              {/* <div className={style.swipper_text}>
                <div className={style.swipper_text_item}>
                  <span className={style.swipper_border}></span>
                  It is a community that shares
                </div>
                <div className={style.swipper_text_item}>
                  the advance of science and growth.
                </div>
              </div> */}

              {
                //11회차 사전등록 전 임시 주석 시작
                 registerYn === "Y" ? (
                   <div
                     className={style.rotate_item}
                     // onClick={() =>
                     //   cookies.get("AuthToken") === undefined
                     //     ? navigate(process.env.REACT_APP_CLIENT_URL + "signup")
                     //     : navigate(process.env.REACT_APP_CLIENT_URL + "register")
                     // }
                     onClick={() =>
                       navigate(process.env.REACT_APP_CLIENT_URL + "register")
                     }
                   >
                     <img
                       className={style.rotate_img}
                       alt=""
                       src={
                         process.env.REACT_APP_CLIENT_URL +
                         "img/landing/rotate.png"
                       }
                     />
                     <img
                       className={style.arrow_img}
                       alt=""
                       src={
                         process.env.REACT_APP_CLIENT_URL +
                         "img/landing/arrow_right.png"
                       }
                     />
                   </div>
                 ) :
                //11회차 사전등록 전 임시 주석 종료
                cookies.get("AuthToken") === undefined ? (
                  //이벤트 등록 X 비로그인 시 Become a member
                  <div
                    className={style.rotate_item}
                    // onClick={() =>
                    //   cookies.get("AuthToken") === undefined
                    //     ? navigate(process.env.REACT_APP_CLIENT_URL + "signup")
                    //     : navigate(process.env.REACT_APP_CLIENT_URL + "register")
                    // }
                    onClick={() =>
                      navigate(process.env.REACT_APP_CLIENT_URL + "signup")
                    }
                  >
                    <img
                      className={style.rotate_img}
                      alt=""
                      src={
                        process.env.REACT_APP_CLIENT_URL +
                        "img/landing/rotate_member.png"
                      }
                    />
                    <img
                      className={style.arrow_img}
                      alt=""
                      src={
                        process.env.REACT_APP_CLIENT_URL +
                        "img/landing/arrow_right.png"
                      }
                    />
                  </div>
                ) : (
                  //이벤트 등록 X 로그인 시 미출력
                  <></>
                )
              }
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default SwiperArea;
