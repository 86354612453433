import { Image, Pagination, Skeleton } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import style from "css/mypage.module.css";
import { closeAlert, openConfirm } from "redux/slices/alertSlice";
import { ReactComponent as FavortiteIcon } from "asset/common/favorite.svg";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loading from "components/common/loading";
import { useMediaQuery } from "react-responsive";

function ComingSoon() {
  // const defaultRow = 4;
  const [init, setInit] = useState(true);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState(false);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState({
    current: 1,
    offset: 0,
    row: 4,
  });
  const [list, setList] = useState([]);

  const getTotal = useCallback(() => {
    const url = "/mypage/register/total";
    axios.get(url).then((res) => {
      if (res.status >= 200 && res.status < 300) {
        if (res.data <= 0) {
          setLoading(false);
        }
        setTotal(res.data);
        setSearch(true);
      }
    });
  }, []);

  const getList = useCallback(() => {
    setLoading(true);
    const url = "/mypage/register/list";
    const config = {
      params: {
        ROW: page.row,
        PAGE: page.offset,
      },
    };
    axios
      .get(url, config)
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          setList(res.data);
        }
      })
      .finally(() => setLoading(false));
  }, [page]);

  useEffect(() => {
    if (init) {
      getTotal();
      setInit(false);
    }
  }, [getTotal, init]);

  useEffect(() => {
    if (search && total > 0) {
      getList();
      setSearch(false);
    }
  }, [getList, search, total]);

  const refreshEvent = () => {
    setPage({
      ...page,
      offset: 0,
      current: 1,
    });
    setInit(true);
  };

  const movePage = (value) => {
    const calOffset = page.row * (value - 1);
    let newOffset = 0;
    if (value > page.current) {
      newOffset = calOffset;
    } else {
      if (calOffset > 0) {
        newOffset = calOffset;
      }
    }
    setPage({
      ...page,
      offset: newOffset,
      current: value,
    });
    setSearch(true);
  };

  return (
    <section className={style.library_container}>
      {loading ? (
        <Loading />
      ) : (
        <>
          {list.length > 0 && (
            <div className={style.library_content}>
              {list.map((item, idx) => (
                <section className={style.library_item} key={idx}>
                  <Card
                    id={item.id}
                    episode={item.episode}
                    title={item.title}
                    description={item.description}
                    img={item.img}
                    startDate={item.startDate}
                    startTime={item.startTime}
                    endTime={item.endTime}
                    refreshEvent={refreshEvent}
                  />
                </section>
              ))}
            </div>
          )}
          {/* </div> */}
          {list.length > 0 && (
            <div className={style.library_paging}>
              <Pagination
                total={total}
                current={page.current}
                pageSize={page.row}
                onChange={(page) => movePage(page)}
              />
            </div>
          )}
        </>
      )}
    </section>
  );
}

function Card(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const isTablet = useMediaQuery({
    query: "(min-width: 768px) and (max-width: 1023px)",
  });
  const getDetailEvent = () => {
    navigate(process.env.REACT_APP_CLIENT_URL + "register?id=" + props.id);
  };
  const deleteEvent = (e) => {
    e.stopPropagation();
    const obj = {
      title: "Notice",
      content: "Would you like to remove the bookmark.",
      submitEventHandler: () => {
        const config = {
          params: {
            ID: props.id,
          },
        };
        axios
          .delete("/mypage/library", config)
          .then((res) => {
            if (res.status >= 200 && res.status < 300) {
              props.refreshEvent();
            }
          })
          .catch((err) => {
            console.log(err);
          });
        dispatch(closeAlert());
      },
    };
    dispatch(openConfirm(obj));
  };
  return (
    <div className={style.library_item_container} onClick={getDetailEvent}>
      <div
        className={
          isTablet
            ? `${style.like_btn} ${style.top}`
            : `${style.like_btn} ${style.bottom}`
        }
        onClick={(e) => deleteEvent(e)}
      >
        <FavortiteIcon />
      </div>

      <div className={style.library_item_img}>
        {props.img === "" ? (
          <Skeleton.Image active className={style.skeleton} />
        ) : (
          <Image
            className="cursor"
            preview={false}
            width={"100%"}
            height={"100%"}
            // src={props.img}
            src={process.env.REACT_APP_CLIENT_URL + "img/mypage/register.png"}
          />
        )}
      </div>
      <div className={style.library_item_info}>
        <article>
          <p className={style.library_item_title}>{props.title}</p>
          {!isTablet && (
            <p className={style.library_item_description}>
              {props.description}
            </p>
          )}
        </article>
        <article className={style.library_item_time}>
          <p>{props.startDate}</p>
          <p>{props.startTime}</p>
          <p>-</p>
          <p>{props.endTime}</p>
          <p>(GMT)</p>
        </article>
      </div>
    </div>
  );
}

Card.propTypes = {
  id: PropTypes.string,
  episode: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  img: PropTypes.string,
  startDate: PropTypes.string,
  startTime: PropTypes.string,
  endTime: PropTypes.string,
  refreshEvent: PropTypes.func,
};
export default ComingSoon;
