import React from "react";
import WebinarArea from "components/landing/webinarArea";
import AboutJam from "components/landing/aboutJamArea";
import ProductsArea from "components/landing/productsArea";
import NewsLetterArea from "components/landing/newsLetterArea";
import SlideTextArea from "components/landing/slideTextArea";
import SwiperArea from "components/landing/swiperArea";
import CountArea from "components/landing/countArea";
import FloatingButton from "components/landing/floatingButton";

/**
 * 파일명: landing.js
 * 작성자: 강연승
 * 작성일자: 23.08.28
 * 설명: landing page
 */
function Landing() {
  return (
    <>
      <FloatingButton />

      <SwiperArea />

      <CountArea />

      <WebinarArea />

      <AboutJam />

      <ProductsArea />

      <NewsLetterArea />

      <SlideTextArea />
    </>
  );
}

export default Landing;
