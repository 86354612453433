import { Modal, Skeleton } from "antd";
import style from "css/library/webinarCard.module.css";
import modalStyle from "css/library/webinarModal.module.css";
import { useCallback, useEffect, useRef, useState } from "react";
import { Cookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import YouTube from "react-youtube";
import { openAlert } from "redux/slices/alertSlice";

function WebinarCard({ data }) {
  const isWeb = useMediaQuery({ query: "(min-width: 1025px)" });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cookies = new Cookies();
  const [isWebinarOpen, setIsWebinarOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isRunning, setIsRunning] = useState(false);
  const [isPlayBtnVisible, setIsPlayBtnVisible] = useState(false);

  // youtube video control
  const youtubeVideoRef = useRef(null);
  const pauseYoutubeVideo = () => {
    if (youtubeVideoRef.current) {
      youtubeVideoRef.current.internalPlayer.pauseVideo();
    }
  };
  const opts = {
    width: "100%",
    // height: '504',
    playerVars: {
      autoPlay: 1,
    },
  };

  // video control
  const videoRef = useRef(null);
  const playVideo = () => {
    if (videoRef.current) {
      videoRef.current.play();
      setIsRunning(true);
    }
  };

  const pauseVideo = () => {
    if (videoRef.current) {
      videoRef.current.pause();
      setIsRunning(false);
    }
  };

  const isVideoPlaying = useCallback(() => {
    if (videoRef.current) {
      const isPaused = videoRef.current.paused;

      if (isPaused) {
        setIsRunning(false);
      } else {
        setIsRunning(true);
      }
    }
  }, []);

  const videoToggler = () => {
    if (isVideoPlaying) {
      pauseVideo();
    } else {
      playVideo();
    }

    isVideoPlaying();
  };

  const btnImg = useCallback(() => {
    if (isRunning) {
      return (
        <img
          className={`${modalStyle.play_triangle}`}
          alt=""
          src={
            process.env.REACT_APP_CLIENT_URL +
            "img/library/pause_triangle_blue.png"
          }
        />
      );
    } else {
      return (
        <img
          className={`${modalStyle.play_triangle}`}
          alt=""
          src={
            process.env.REACT_APP_CLIENT_URL +
            "img/library/play_triangle_blue.png"
          }
        />
      );
    }
  }, [isRunning]);

  useEffect(() => {
    isVideoPlaying();
    console.log("Running Status :: " + isRunning);
  }, []);

  const modalHandler = () => {
    if (cookies.get("AuthToken") === undefined) {
      // alert("This service requires login. Please login first.");
      dispatch(
        openAlert({
          title: "Notice",
          content: "This service requires login. Please login first.",
          submitEventHandler: () =>
            // (window.location.href =
            //   process.env.REACT_APP_CLIENT_URL + "signin"),
            navigate(process.env.REACT_APP_CLIENT_URL + "signin", { state: { returnPage: true } }),
        })
      );
    } else {
      setIsWebinarOpen(true);
    }
  };

  return (
    <>
      <div className={style.wCard_wrapper} onClick={() => modalHandler()}      onContextMenu={
        (e) => {
            e.preventDefault();
        }
    }>
        <Modal
          open={isWebinarOpen}
          onCancel={(e) => {
            e.stopPropagation();
            if (data.WEBINAR_TYPE === "YOUTUBE") {
              pauseYoutubeVideo();
            } else {
              pauseVideo();
            }
            setIsWebinarOpen(false);
          }}
          footer={null}
          width={1136}
          maskClosable={false}
          zIndex={9000}
          centered
        >
          <div className={modalStyle.content_wrapper}>
            <div className={modalStyle.content_container}>
              <div className={modalStyle.video_container}>
                <div className={modalStyle.video_wrapper}>
                  {data.WEBINAR_TYPE === "YOUTUBE" ? (
                    // <iframe
                    //     className={modalStyle.video}
                    //     src={`${data.WEBINAR_URL}`}
                    //     title="YouTube Video"
                    //     frameBorder="0"
                    //     allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    //     allowFullScreen
                    // ></iframe>
                    <YouTube
                      // className={modalStyle.video}
                      videoId={data.WEBINAR_URL}
                      ref={youtubeVideoRef}
                      opts={opts}
                    />
                  ) : (
                    <>
                      {isWeb ? (
                        <>
                          <div
                            className={`${modalStyle.play_btn} ${isPlayBtnVisible ? "" : modalStyle.invisible
                              }`}
                            onMouseEnter={() => setIsPlayBtnVisible(true)}
                            onClick={() =>
                              isRunning ? pauseVideo() : playVideo()
                            }
                          >
                            {btnImg()}
                          </div>
                          <video
                            ref={videoRef}
                            className={modalStyle.video}
                            onMouseEnter={() => setIsPlayBtnVisible(true)}
                            onMouseLeave={() => setIsPlayBtnVisible(false)}
                            onClick={() => setIsRunning(!isRunning)}
                            onPlay={() => isVideoPlaying()}
                            onPause={() => isVideoPlaying()}
                            controls
                            controlsList="nodownload"
                          >
                            <source src={data.WEBINAR_URL} />
                            {/* <source src="blob:https://www.youtube.com/watch?v=rx8dcw6jQQw" /> */}
                          </video>
                        </>
                      ) : (
                        <>
                          <video
                            ref={videoRef}
                            className={modalStyle.video}
                            controls
                            controlsList="nodownload"
                          >
                            <source src={data.WEBINAR_URL} />
                            {/* <source src="blob:https://www.youtube.com/watch?v=rx8dcw6jQQw" /> */}
                          </video>
                        </>
                      )}
                    </>
                  )}
                </div>
                <div className={modalStyle.video_title}>
                  {/* Aesthetic Analysis of Facial Anatomy */}
                  {data.WEBINAR_TITLE}
                </div>
              </div>

              <div className={modalStyle.subContainer}></div>
            </div>
          </div>
        </Modal>
        {/* <DrHan width="100%" height="100%" /> */}
        {data.WEBINAR_THUMBNAIL !== null &&
          data.WEBINAR_THUMBNAIL !== "" &&
          data.WEBINAR_THUMBNAIL !== undefined ? (
          <img
            className={`${style.wCard_img} ${isHovered ? style.img_hover : ""}`}
            alt=""
            src={process.env.REACT_APP_CLIENT_URL + data.WEBINAR_THUMBNAIL}
          />
        ) : (
          <Skeleton.Image
            style={{ width: "100%", height: "100%" }}
            className={`${style.wCard_img} ${isHovered ? style.img_hover : ""}`}
            active
          />
        )}
        <div
          className={style.wCard_cover}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <div
            className={`${style.wCard_play} ${isHovered ? style.img_hover : ""
              }`}
          >
            <img
              className={`${style.play_triangle} ${isHovered ? style.img_hover : ""
                }`}
              alt=""
              src={
                process.env.REACT_APP_CLIENT_URL +
                "img/library/play_triangle_white.png"
              }
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default WebinarCard;
