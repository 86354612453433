import React from "react";
import ReactDOM from "react-dom/client";
import store from "redux/store";
import { Provider } from "react-redux";
import Root from "root";
import ErrorBoundary from "components/app/errorBoundary";
import { ConfigProvider } from "antd";
import "font/font.css";
// import ko_KR from "antd/lib/locale/ko_KR";
// import en_US from "antd/es/locale/en_US";
// import i18n from "react-i18next";
import "font/font.css";

// const customLocale = {
//   ...ko_KR,
//   DatePicker: {
//     ...ko_KR.DatePicker,
//     lang: {
//       ...ko_KR.DatePicker.lang,
//       now: "지금",
//     },
//   },
// };

/**
 * 파일명: index.js
 * 작성자: 권도훈
 * 작성일자: 23.04.06
 * 설명: index
 */
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <ErrorBoundary>
      <ConfigProvider
        // locale={customLocale}
        // locale={i18n.useTranslation().language() === "ko-KR" ? ko_KR : en_US}
        theme={{
          token: {
            fontFamily: "SpoqaHanSansNeo",
          },
          components: {
            Checkbox: {
              colorPrimary: "#13348f",
              colorPrimaryHover: "#13348f",
              colorPrimaryActive: "#13348f",
            },
            Input: {
              colorPrimary: "#13348f",
              colorPrimaryHover: "#13348f",
              colorPrimaryActive: "#13348f",
            },
            DatePicker: {
              colorPrimary: "#13348f",
              colorPrimaryHover: "#13348f",
              colorPrimaryActive: "#13348f",
            },
            Select: {
              colorPrimary: "#13348f",
              colorPrimaryHover: "#13348f",
              colorPrimaryActive: "#13348f",
            },
            Button: {
              borderRadius: 5,
              fontSize: 16,
              colorPrimary: "#1f299c",
              colorPrimaryHover: "#3c45a9",
              colorPrimaryActive: "#3c45a9",
              colorText: "#fff",
              colorTextDisabled: "#fff",
              colorTextLightSolid: "#fff",
              colorError: "#e6e6e6",
              colorErrorHover: "#6b6b6b",
              colorErrorActive: "#6b6b6b",
            },
            Tabs: {
              colorPrimary: "#13348f",
              colorPrimaryHover: "#13348f",
              colorPrimaryActive: "#13348f",
            },
            Menu: {
              itemColor: "#fff",
              itemHoverColor: "rgba(255, 255, 255, 0.7)",
              horizontalItemHoverColor: "#3652a0",
              itemSelectedColor: "#3652a0 !important",
              itemSelectedBg: "rgba(224, 255, 141, 0.24)",
              controlItemBgActive: "rgba(224, 255, 141, 0.24)",
              colorBgElevated: "#000000",
            },
            Radio: {
              colorPrimary: "#13348f",
              colorPrimaryHover: "#13348f",
              colorPrimaryActive: "#13348f",
            },
            Cascader: {
              colorPrimary: "#13348f",
              colorPrimaryHover: "#13348f",
              colorPrimaryActive: "#13348f",
            },
            Switch: {
              colorPrimary: "#13348f",
              colorPrimaryHover: "#13348f",
              colorPrimaryActive: "#13348f",
            },
            Pagination: {
              colorPrimary: "#13348f",
              colorPrimaryHover: "#13348f",
              colorPrimaryActive: "#13348f",
            },
            Table: {
              colorPrimary: "#13348f",
              colorFillAlter: "#fff",
            },
          },
        }}
      >
        <Root />
      </ConfigProvider>
    </ErrorBoundary>
  </Provider>
);
