import React, { useState } from "react";
import style from "css/account.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Item from "components/account/item";
import Input from "components/account/input";
import Button from "components/account/button";
import { closeAlert, openAlert } from "redux/slices/alertSlice";
import { insertUserInfo } from "redux/slices/userSlice";
import axios from "axios";
import { Cookies } from "react-cookie";

/**
 * 파일명: Signin.jsx
 * 작성자: 권도훈
 * 작성일자: 24.01.03
 */
function Signin() {
  return (
    <section className={style.wrapper}>
      <div className={style.container}>
        <Banner />
        <Form />
      </div>
    </section>
  );
}

function Banner() {
  return (
    <article className={style.banner}>
      <p className={style.title}>Welcome!</p>
      <article className={style.description}>
        <p>Log in to your account.</p>
        <p>By participating in JAM, you can meet the best experts.</p>
        <p>We present the latest ideas and findings from around the world.</p>
      </article>
    </article>
  );
}

function Form() {
  //cookies
  const cookies = new Cookies();
  const authNm = process.env.REACT_APP_AUTH_TOKEN;
  const refreshNm = process.env.REACT_APP_REFRESH_TOKEN;
  const refreshTime = process.env.REACT_APP_REFRESH_TOKEN_TIME;
  const domain = process.env.REACT_APP_DOMAIN;
  //dispatch
  const dispatch = useDispatch();
  //navigate
  const navigate = useNavigate();
  //location
  const location = useLocation();
  //state
  const [maintainUser, setMaintainUser] = useState(false);
  const [info, setInfo] = useState({
    email: {
      title: "Email",
      value: "",
      require: true,
      error: false,
      msg: "",
    },
    password: {
      type: "password",
      title: "Password",
      value: "",
      require: true,
      error: false,
      msg: "",
    },
  });
  const [loginBtn, setLoginBtn] = useState({
    content: "Login",
    valid: false,
    loading: false,
    disabled: false,
  });
  //func
  const confirmValue = () => {
    return confirmEmail() && confirmPassword();
  };
  const confirmEmail = () => {
    if (info.email.value === "") {
      setInfo({
        ...info,
        email: {
          ...info.email,
          error: true,
          msg: "Please insert E-Mail.",
        },
      });
      return false;
    }
    return true;
  };
  const confirmPassword = () => {
    if (info.password.value === "") {
      setInfo({
        ...info,
        password: {
          ...info.password,
          error: true,
          msg: "Please insert password.",
        },
      });
      return false;
    }
    return true;
  };
  const signinEvent = () => {
    if (confirmValue()) {
      setLoginBtn({
        ...loginBtn,
        loading: true,
      });

      const data = {
        MEMBER_ID: info.email.value,
        MEMBER_PW: info.password.value,
      };

      axios
        .post("/user/signin", data)
        .then((res) => {
          if (res.status >= 200 && res.status < 300) {
            const memeberInfo = res.data;

            cookies.set(authNm, memeberInfo.authToken, {
              path: "/",
              // maxAge: isCheck ? authTime : null,
              domain: domain,
            });

            cookies.set(refreshNm, memeberInfo.refreshToken, {
              path: "/",
              maxAge: maintainUser ? refreshTime : null,
              domain: domain,
            });

            dispatch(
              insertUserInfo({
                name: memeberInfo.name,
                id: memeberInfo.id,
              })
            );
            if (location.state === null || location.state.returnPage !== true) {
              navigate(process.env.REACT_APP_CLIENT_URL);
            } else {
              navigate(-1);
            }
          } else {
            setLoginBtn({
              ...loginBtn,
              loading: false,
            });
            const obj = {
              title: "Notice",
              content: "Fail to log in.",
              submitEventHandler: () => {
                dispatch(closeAlert());
              },
            };
            dispatch(openAlert(obj));
          }
        })
        .catch(() => {
          setLoginBtn({
            ...loginBtn,
            loading: false,
          });
          const obj = {
            title: "Notice",
            content: "Fail to log in.",
            submitEventHandler: () => {
              dispatch(closeAlert());
            },
          };
          dispatch(openAlert(obj));
        });
    }
  };
  const onKeyDownEvent = (key) => {
    if (key === "Enter") {
      signinEvent();
    }
  };

  return (
    <>
      <section className={style.form}>
        <Item
          require={info.email.require}
          error={info.email.error}
          msg={info.email.msg}
          title={info.email.title}
          component={
            <Input
              type="text"
              placeholder="Email"
              error={info.email.error}
              value={info.email.value}
              onChangeEvent={(res) => {
                if (info.email.error && res !== info.email.value) {
                  setInfo({
                    ...info,
                    email: {
                      ...info.email,
                      value: res,
                      error: false,
                      msg: "",
                    },
                  });
                } else {
                  setInfo({
                    ...info,
                    email: {
                      ...info.email,
                      value: res,
                    },
                  });
                }
              }}
              onKeyDownEvent={onKeyDownEvent}
              disabled={loginBtn.loading}
            />
          }
        />
        <Item
          require={info.password.require}
          error={info.password.error}
          msg={info.password.msg}
          title={info.password.title}
          component={
            <Input
              type={info.password.type}
              placeholder="Password"
              error={info.password.error}
              value={info.password.value}
              onChangeEvent={(res) => {
                if (info.password.error && res !== info.password.value) {
                  setInfo({
                    ...info,
                    password: {
                      ...info.password,
                      value: res,
                      error: false,
                      msg: "",
                    },
                  });
                } else {
                  setInfo({
                    ...info,
                    password: {
                      ...info.password,
                      value: res,
                    },
                  });
                }
              }}
              onKeyDownEvent={onKeyDownEvent}
              disabled={loginBtn.loading}
            />
          }
        />
      </section>

      <section className={style.option}>
        <div className={style.password}>
          <p
            onClick={() =>
              navigate(process.env.REACT_APP_CLIENT_URL + "password")
            }
          >
            Forgot your password?
          </p>
        </div>
        <div className={style.maintain}>
          <div
            className={
              maintainUser
                ? `${style.maintain_chk} ${style.check}`
                : style.maintain_chk
            }
            onClick={() => setMaintainUser(!maintainUser)}
          />
          <p>Keep me loged in</p>
        </div>
      </section>

      <section className={style.singin}>
        <Button
          content={loginBtn.content}
          disabled={loginBtn.disabled}
          loading={loginBtn.loading}
          valid={loginBtn.valid}
          clickEvent={signinEvent}
        />
        <div
          className={style.signup}
          onClick={() => navigate(process.env.REACT_APP_CLIENT_URL + "signup")}
        >
          <p className={style.signup_comment}>Not a member yet?</p>
          <p className={style.signup_btn}>JOIN HERE</p>
        </div>
      </section>
    </>
  );
}

export default Signin;
