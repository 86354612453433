import { Spin } from "antd";
import React from "react";

function Loading(props) {
  return (
    <section className="loading_wrapper">
      <Spin />
    </section>
  );
}

export default Loading;
