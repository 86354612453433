import React from "react";
import style from "css/mypage.module.css";
import Content from "components/myPage/content";
import NewsLetter from "./newsLetter";
import Register from "./register";
import Library from "./library";
import { useMediaQuery } from "react-responsive";
import ComingSoon from "./comingSoon";
function Favorite() {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  return (
    <section className={style.content}>
      <Content title="Register" component={<Register />} />
      {!isMobile && (
        <div className={`${style.content_item} ${style.content_line}`} />
      )}
      <Content title="Coming Soon" component={<ComingSoon />} />
      {!isMobile && (
        <div className={`${style.content_item} ${style.content_line}`} />
      )}
      <Content title="Library" component={<Library />} />
      {!isMobile && (
        <div className={`${style.content_item} ${style.content_line}`} />
      )}
      <Content title="Newsletter" component={<NewsLetter />} />
    </section>
  );
}

export default Favorite;
