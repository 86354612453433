import React, { useState } from "react";
import style from "css/account.module.css";
import Item from "components/account/item";
import Input from "components/account/input";
import Button from "components/account/button";
import { closeAlert, openAlert } from "redux/slices/alertSlice";
import { useDispatch } from "react-redux";
import axios from "axios";
import SelectBox from "components/account/select";
import { Cookies } from "react-cookie";
import { insertUserInfo } from "redux/slices/userSlice";
import { useNavigate } from "react-router-dom";

function Info({ email }) {
  return (
    <section className={style.wrapper}>
      <div className={style.container}>
        <Form email={email} />
      </div>
    </section>
  );
}

function Form({ email }) {
  const dispatch = useDispatch();
  //navigate
  const navigate = useNavigate();
  //state
  const [info, setInfo] = useState({
    name: {
      title: "Name",
      first_name: "",
      last_name: "",
      require: true,
      error: false,
      msg: "",
    },
    country: {
      title: "Country",
      value: "",
      require: true,
      error: false,
      msg: "",
    },
    specialty: {
      title: "Specialty",
      value: "",
      require: true,
      error: false,
      msg: "",
    },
    password: {
      title: "Password",
      value: "",
      confirm_value: "",
      require: true,
      error: false,
      msg: "",
      maxLength: 50,
    },
    gender: {
      title: "Gender",
      value: "",
      require: true,
      error: false,
      msg: "",
    },
    age: {
      title: "Age",
      value: "",
      require: true,
      error: false,
      msg: "",
    },
  });
  const [registerBtn, setRegisterBtn] = useState({
    content: "Register",
    valid: false,
    loading: false,
    disabled: false,
  });

  const registerEvent = () => {
    setRegisterBtn({
      ...registerBtn,
      loading: true,
    });

    if (confirmValue()) {
      const data = {
        MEMBER_ID: email,
        MEMBER_PWD: info.password.value,
        FIRST_NM: info.name.first_name,
        LAST_NM: info.name.last_name,
        COUNTRY: info.country.value,
        SPECIALTY: info.specialty.value,
        AGE: info.age.value,
        GENDER: info.gender.value,
      };
      axios
        .post("/user/signup", data)
        .then((res) => {
          if (res.status >= 200 && res.status < 300) {
            const cookies = new Cookies();
            const authNm = process.env.REACT_APP_AUTH_TOKEN;
            const refreshNm = process.env.REACT_APP_REFRESH_TOKEN;
            const domain = process.env.REACT_APP_DOMAIN;
            const data = {
              MEMBER_ID: email,
              MEMBER_PW: info.password.value,
            };

            axios
              .post("/user/signin", data)
              .then((res) => {
                if (res.status >= 200 && res.status < 300) {
                  const memeberInfo = res.data;

                  cookies.set(authNm, memeberInfo.authToken, {
                    path: "/",
                    domain: domain,
                  });

                  cookies.set(refreshNm, memeberInfo.refreshToken, {
                    path: "/",
                    maxAge: null,
                    domain: domain,
                  });

                  dispatch(
                    insertUserInfo({
                      name: memeberInfo.name,
                      id: memeberInfo.id,
                    })
                  );
                  navigate(process.env.REACT_APP_CLIENT_URL);
                } else {
                  const obj = {
                    title: "Notice",
                    content: "Fail to log in.",
                    submitEventHandler: () => {
                      navigate(process.env.REACT_APP_CLIENT_URL + "/signin");
                      dispatch(closeAlert());
                    },
                  };
                  dispatch(openAlert(obj));
                }
              })
              .catch(() => {
                const obj = {
                  title: "Notice",
                  content: "Fail to log in.",
                  submitEventHandler: () => {
                    navigate(process.env.REACT_APP_CLIENT_URL + "/signin");
                    dispatch(closeAlert());
                  },
                };
                dispatch(openAlert(obj));
              });
          } else {
            setRegisterBtn({
              ...registerBtn,
              loading: false,
            });
            const obj = {
              title: "Notice",
              content: "Fail to log up.",
              submitEventHandler: () => {
                navigate(process.env.REACT_APP_CLIENT_URL + "/signin");
                dispatch(closeAlert());
              },
            };
            dispatch(openAlert(obj));
          }
        })
        .catch((err) => {
          setRegisterBtn({
            ...registerBtn,
            loading: false,
          });
          const obj = {
            title: "Notice",
            content: err.response.data,
            submitEventHandler: () => {
              dispatch(closeAlert());
            },
          };
          dispatch(openAlert(obj));
        });
    } else {
      setRegisterBtn({
        ...registerBtn,
        loading: false,
      });
    }
  };

  const confirmValue = () => {
    return (
      confirmName() &&
      confirmAge() &&
      confirmGender() &&
      confirmCountry() &&
      confirmSpecialty() &&
      confirmPassword()
    );
  };

  const confirmName = () => {
    if (info.name.first_name === "") {
      setInfo({
        ...info,
        name: {
          ...info.name,
          error: true,
          msg: "Please insert first name.",
        },
      });
      return false;
    } else if (info.name.last_name === "") {
      setInfo({
        ...info,
        name: {
          ...info.name,
          error: true,
          msg: "Please insert last name.",
        },
      });
      return false;
    }
    return true;
  };

  const confirmAge = () => {
    if (info.age.value === "") {
      setInfo({
        ...info,
        age: {
          ...info.age,
          error: true,
          msg: "Please insert age.",
        },
      });
      return false;
    } else if (info.age.value > 100 || info.age.value < 0) {
      setInfo({
        ...info,
        age: {
          ...info.age,
          error: true,
          msg: "Please enter a valid age range. (1 to 100)",
        },
      });
      return false;
    } else {
      var number = /[0-9]/;
      if (!number.test(info.age.value)) {
        setInfo({
          ...info,
          age: {
            ...info.age,
            error: true,
            msg: "Please insert number.",
          },
        });
        return false;
      }
    }
    return true;
  };

  const confirmGender = () => {
    if (info.gender.value === "") {
      setInfo({
        ...info,
        gender: {
          ...info.gender,
          error: true,
          msg: "Please select gender.",
        },
      });
      return false;
    }
    return true;
  };

  const confirmCountry = () => {
    if (info.country.value === "") {
      setInfo({
        ...info,
        country: {
          ...info.country,
          error: true,
          msg: "Please select country.",
        },
      });
      return false;
    }
    return true;
  };

  const confirmSpecialty = () => {
    if (info.specialty.value === "") {
      setInfo({
        ...info,
        specialty: {
          ...info.specialty,
          error: true,
          msg: "Please select specialty.",
        },
      });
      return false;
    }
    return true;
  };

  const confirmPassword = () => {
    if (info.password.value === "") {
      setInfo({
        ...info,
        password: {
          ...info.password,
          error: true,
          msg: "Please insert password.",
        },
      });
      return false;
    } else if (info.password.confirm_value === "") {
      setInfo({
        ...info,
        password: {
          ...info.password,
          error: true,
          msg: "Please insert confirm password.",
        },
      });
      return false;
    } else if (info.password.value !== info.password.confirm_value) {
      setInfo({
        ...info,
        password: {
          ...info.password,
          error: true,
          msg: "The password does not match.",
        },
      });
      return false;
    } else {
      var number = /[0-9]/;
      var alphabet = /[a-zA-Z]/;
      var special = /[~!@\\#$%<>^&*]/;
      const validLength = info.password.value.length >= 8;
      const validPassword =
        number.test(info.password.value) &&
        alphabet.test(info.password.value) &&
        special.test(info.password.value);
      if (!validLength) {
        setInfo({
          ...info,
          password: {
            ...info.password,
            error: true,
            msg: "The password should be at least 8 character long.",
          },
        });
        return false;
      } else if (!validPassword) {
        setInfo({
          ...info,
          password: {
            ...info.password,
            error: true,
            msg: "The password should include alphabet, number, special character.",
          },
        });
        return false;
      }
    }
    return true;
  };

  return (
    <>
      <section className={style.form}>
        <Item
          require={info.name.require}
          error={info.name.error}
          msg={info.name.msg}
          title={info.name.title}
          component={
            <div className={style.row_grid}>
              <Input
                type="text"
                placeholder="First Name"
                error={info.name.error}
                value={info.name.first_name}
                onChangeEvent={(res) => {
                  if (info.name.error && res !== info.name.first_name) {
                    setInfo({
                      ...info,
                      name: {
                        ...info.name,
                        first_name: res,
                        error: false,
                        msg: "",
                      },
                    });
                  } else {
                    setInfo({
                      ...info,
                      name: {
                        ...info.name,
                        first_name: res,
                      },
                    });
                  }
                }}
                disabled={registerBtn.loading}
                maxLength={50}
              />
              <Input
                type="text"
                placeholder="Last Name"
                error={info.name.error}
                value={info.name.last_name}
                onChangeEvent={(res) => {
                  if (info.name.error && res !== info.name.last_name) {
                    setInfo({
                      ...info,
                      name: {
                        ...info.name,
                        last_name: res,
                        error: false,
                        msg: "",
                      },
                    });
                  } else {
                    setInfo({
                      ...info,
                      name: {
                        ...info.name,
                        last_name: res,
                      },
                    });
                  }
                }}
                disabled={registerBtn.loading}
                maxLength={50}
              />
            </div>
          }
        />

        <Item
          require={info.age.require}
          error={info.age.error}
          msg={info.age.msg}
          title={info.age.title}
          component={
            <Input
              type="number"
              placeholder="Age"
              error={info.age.error}
              value={info.age.value}
              onChangeEvent={(res) => {
                if (info.age.error && res !== info.age.value) {
                  setInfo({
                    ...info,
                    age: {
                      ...info.age,
                      value: res,
                      error: false,
                      msg: "",
                    },
                  });
                } else {
                  setInfo({
                    ...info,
                    age: {
                      ...info.age,
                      value: res,
                    },
                  });
                }
              }}
              disabled={registerBtn.loading}
              maxLength={3}
            />
          }
        />

        <Item
          require={info.gender.require}
          error={info.gender.error}
          msg={info.gender.msg}
          title={info.gender.title}
          component={
            <SelectBox
              placeholder="Gender"
              error={info.gender.error}
              value={info.gender.value}
              onChangeEvent={(res) => {
                if (info.gender.error && res !== info.gender.value) {
                  setInfo({
                    ...info,
                    gender: {
                      ...info.gender,
                      value: res,
                      error: false,
                      msg: "",
                    },
                  });
                } else {
                  setInfo({
                    ...info,
                    gender: {
                      ...info.gender,
                      value: res,
                    },
                  });
                }
              }}
              disabled={registerBtn.loading}
              options={genderList}
            />
          }
        />

        <Item
          require={info.country.require}
          error={info.country.error}
          msg={info.country.msg}
          title={info.country.title}
          component={
            <SelectBox
              placeholder="Country"
              error={info.country.error}
              value={info.country.value}
              onChangeEvent={(res) => {
                if (info.country.error && res !== info.country.value) {
                  setInfo({
                    ...info,
                    country: {
                      ...info.country,
                      value: res,
                      error: false,
                      msg: "",
                    },
                  });
                } else {
                  setInfo({
                    ...info,
                    country: {
                      ...info.country,
                      value: res,
                    },
                  });
                }
              }}
              disabled={registerBtn.loading}
              options={countryList}
            />
          }
        />
        <Item
          require={info.specialty.require}
          error={info.specialty.error}
          msg={info.specialty.msg}
          title={info.specialty.title}
          component={
            <SelectBox
              placeholder="Specialty"
              error={info.specialty.error}
              value={info.specialty.value}
              onChangeEvent={(res) => {
                if (info.specialty.error && res !== info.specialty.value) {
                  setInfo({
                    ...info,
                    specialty: {
                      ...info.specialty,
                      value: res,
                      error: false,
                      msg: "",
                    },
                  });
                } else {
                  setInfo({
                    ...info,
                    specialty: {
                      ...info.specialty,
                      value: res,
                    },
                  });
                }
              }}
              disabled={registerBtn.loading}
              options={specialtyList}
            />
          }
        />

        <Item
          require={info.password.require}
          error={info.password.error}
          msg={info.password.msg}
          title={info.password.title}
          component={
            <div className={style.password_grid}>
              <Input
                type="password"
                placeholder="Password"
                error={info.password.error}
                value={info.password.value}
                onChangeEvent={(res) => {
                  if (info.password.error && res !== info.password.value) {
                    setInfo({
                      ...info,
                      password: {
                        ...info.password,
                        value: res,
                        error: false,
                        msg: "",
                      },
                    });
                  } else {
                    setInfo({
                      ...info,
                      password: {
                        ...info.password,
                        value: res,
                      },
                    });
                  }
                }}
                maxLength={info.password.maxLength}
                disabled={registerBtn.loading}
              />
              <Input
                type="password"
                placeholder="Confirm Password"
                error={info.password.error}
                value={info.password.confirm_value}
                onChangeEvent={(res) => {
                  if (
                    info.password.error &&
                    res !== info.password.confirm_value
                  ) {
                    setInfo({
                      ...info,
                      password: {
                        ...info.password,
                        confirm_value: res,
                        error: false,
                        msg: "",
                      },
                    });
                  } else {
                    setInfo({
                      ...info,
                      password: {
                        ...info.password,
                        confirm_value: res,
                      },
                    });
                  }
                }}
                maxLength={info.password.maxLength}
                disabled={registerBtn.loading}
              />
              <article className={style.password_comment_article}>
                <p className={style.password_comment}>
                  At least 8 characters with alphabet, number, and special
                  characters
                </p>
              </article>
            </div>
          }
        />
      </section>

      <section className={style.register}>
        <Button
          content={registerBtn.content}
          disabled={registerBtn.disabled}
          loading={registerBtn.loading}
          valid={registerBtn.valid}
          clickEvent={registerEvent}
        />
        <article className={style.register_comment}>
          <div className={`${style.dot} ${style.require}`} />
          <p>Required</p>
        </article>
      </section>
    </>
  );
}

export default Info;

// const genderList = ["Male", "Female"];
// const specialtyList = [
//   "Anatomist",
//   "Anesthesiologist",
//   "Cardiologist",
//   "Cosmetic Surgeon",
//   "Dermatologist",
//   "Endocrinologist",
//   "Facial Plastic Surgeon",
//   "Gynecologist",
//   "Internal medicine",
//   "Journalist medical",
//   "MD",
//   "Mesotherapist",
//   "Neurologist",
//   "Nutritionist",
//   "Oculoplastic Surgeon",
//   "Oncologist",
//   "Ophthalmologist",
//   "Orthopedist",
//   "Otorhinolaryngologist",
//   "Pediatrician",
//   "Phlebologist",
//   "Plastic Surgeon",
//   "Psychiatrist",
//   "Radiologist",
//   "Rheumatologist",
//   "Stomatologist",
//   "Urologist",
//   "Beautician",
//   "Biomedical engineers",
//   "Dental Surgeon",
//   "Dietetician",
//   "Kinesiotherapist",
//   "Medical office staff",
//   "Nurse Practitioner",
//   "Pharmacist",
//   "Pharmacologist",
//   "PhD - Research",
//   "Physician Assistant",
//   "Psychologist",
//   "Registered Nurse",
//   "Chartered Accountant",
//   "Corporate finance",
//   "Event organizer",
//   "Industry Representative",
//   "Journalist economy / finance",
//   "Journalist mass audience",
//   "Lawyer",
//   "Marketing consultant",
//   "Press officer",
//   "Translator",
//   "Travel agent",
//   "Not specified",
// ];
// const countryList = [
//   "Afghanistan",
//   "Albania",
//   "Algeria",
//   "American Samoa",
//   "Andorra",
//   "Angola",
//   "Anguilla",
//   "Antarctica",
//   "Antigua and Barbuda",
//   "Argentina",
//   "Armenia",
//   "Aruba",
//   "Australia",
//   "Austria",
//   "Azerbaijan",
//   "Bahamas",
//   "Bahrain",
//   "Bangladesh",
//   "Barbados",
//   "Belarus",
//   "Belgium",
//   "Belize",
//   "Benin",
//   "Bermuda",
//   "Bhutan",
//   "Bolivia",
//   "Bonaire, Sint Eustatius and Saba",
//   "Bosnia and Herzegovina",
//   "Botswana",
//   "Brazil",
//   "British Virgin Islands",
//   "Brunei",
//   "Bulgaria",
//   "Burkina Faso",
//   "Burundi",
//   "Cambodia",
//   "Cameroon",
//   "Canada",
//   "Cape Verde",
//   "Cayman Islands",
//   "Central African Republic",
//   "Chad",
//   "Chile",
//   "China",
//   "Christmas Island",
//   "Cocos (Keeling) Islands",
//   "Colombia",
//   "Comoros",
//   "Congo (Democratic Republic of the)",
//   "Congo (Republic of the)",
//   "Cook Islands",
//   "Costa Rica",
//   "Croatia",
//   "Cuba",
//   "Curaçao",
//   "Cyprus",
//   "Czech Republic",
//   "Côte d'Ivoire",
//   "Denmark",
//   "Djibouti",
//   "Dominica",
//   "Dominican Republic",
//   "Ecuador",
//   "Egypt",
//   "El Salvador",
//   "Equatorial Guinea",
//   "Eritrea",
//   "Estonia",
//   "Ethiopia",
//   "Falkland Islands",
//   "Faroe Islands",
//   "Fiji",
//   "Finland",
//   "France",
//   "French Guiana",
//   "French Polynesia",
//   "Gabon",
//   "Gambia",
//   "Georgia",
//   "Germany",
//   "Ghana",
//   "Gibraltar",
//   "Greece",
//   "Greenland",
//   "Grenada",
//   "Guadeloupe",
//   "Guam",
//   "Guatemala",
//   "Guernsey",
//   "Guinea",
//   "Guinea-Bissau",
//   "Guyana",
//   "Haiti",
//   "Honduras",
//   "Hong Kong SAR China",
//   "Hungary",
//   "Iceland",
//   "India",
//   "Indonesia",
//   "Iran",
//   "Iraq",
//   "Ireland",
//   "Isle of Man",
//   "Israel",
//   "Italy",
//   "Jamaica",
//   "Japan",
//   "Jersey",
//   "Jordan",
//   "Kazakhstan",
//   "Kenya",
//   "Kiribati",
//   "Kosovo",
//   "Kuwait",
//   "Kyrgyzstan",
//   "Laos",
//   "Latvia",
//   "Lebanon",
//   "Lesotho",
//   "Liberia",
//   "Libya",
//   "Liechtenstein",
//   "Lithuania",
//   "Luxembourg",
//   "Macau SAR China",
//   "Macedonia",
//   "Madagascar",
//   "Malawi",
//   "Malaysia",
//   "Maldives",
//   "Mali",
//   "Malta",
//   "Marshall Islands",
//   "Martinique",
//   "Mauritania",
//   "Mauritius",
//   "Mayotte",
//   "Mexico",
//   "Micronesia",
//   "Moldova",
//   "Monaco",
//   "Mongolia",
//   "Montenegro",
//   "Montserrat",
//   "Morocco",
//   "Mozambique",
//   "Myanmar ",
//   "Namibia",
//   "Nauru",
//   "Nepal",
//   "Netherlands",
//   "New Caledonia",
//   "New Zealand",
//   "Nicaragua",
//   "Niger",
//   "Nigeria",
//   "Niue",
//   "Norfolk Island",
//   "North Korea",
//   "Northern Mariana Islands",
//   "Norway",
//   "Oman",
//   "Pakistan",
//   "Palau",
//   "Palestine",
//   "Panama",
//   "Papua New Guinea",
//   "Paraguay",
//   "Peru",
//   "Philippines",
//   "Poland",
//   "Portugal",
//   "Puerto Rico",
//   "Qatar",
//   "Romania",
//   "Russia",
//   "Rwanda",
//   "Réunion",
//   "Saint Barthélemy",
//   "Saint Kitts and Nevis",
//   "Saint Lucia",
//   "Saint Martin",
//   "Saint Pierre and Miquelon",
//   "Saint Vincent and the Grenadines",
//   "Samoa",
//   "San Marino",
//   "Santa Helena, Ascension and Tristan de Cuhna",
//   "Saudi Arabia",
//   "Senegal",
//   "Serbia",
//   "Seychelles",
//   "Sierra Leone",
//   "Singapore",
//   "Sint Maarten",
//   "Slovakia",
//   "Slovenia",
//   "Solomon Islands",
//   "Somalia",
//   "South Africa",
//   "South Korea",
//   "South Sudan",
//   "Spain",
//   "Sri Lanka",
//   "Sudan",
//   "Suriname",
//   "Svalbard and Jan Mayen",
//   "Swaziland",
//   "Sweden",
//   "Switzerland",
//   "Syria",
//   "São Tomé and Príncipe",
//   "Taiwan",
//   "Tajikistan",
//   "Tanzania",
//   "Thailand",
//   "Timor-Leste",
//   "Togo",
//   "Tonga",
//   "Trinidad and Tobago",
//   "Tunisia",
//   "Turkey",
//   "Turkmenistan",
//   "Turks and Caicos Islands",
//   "Tuvalu",
//   "U.S. Virgin Islands",
//   "Uganda",
//   "Ukraine",
//   "United Arab Emirates",
//   "United Kingdom",
//   "United States",
//   "Uruguay",
//   "Uzbekistan",
//   "Vanuatu",
//   "Venezuela",
//   "Vietnam",
//   "Wallis and Futuna",
//   "Western Sahara",
//   "Yemen",
//   "Zambia",
//   "Zimbabwe",
//   "Åland Islands",
// ];
const genderList = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
];
const specialtyList = [
  { label: "Anatomist", value: "Anatomist" },
  { label: "Anesthesiologist", value: "Anesthesiologist" },
  { label: "Cardiologist", value: "Cardiologist" },
  { label: "Cosmetic Surgeon", value: "Cosmetic Surgeon" },
  { label: "Dermatologist", value: "Dermatologist" },
  { label: "Endocrinologist", value: "Endocrinologist" },
  { label: "Facial Plastic Surgeon", value: "Facial Plastic Surgeon" },
  { label: "Gynecologist", value: "Gynecologist" },
  { label: "Internal medicine", value: "Internal medicine" },
  { label: "Journalist medical", value: "Journalist medical" },
  { label: "MD", value: "MD" },
  { label: "Mesotherapist", value: "Mesotherapist" },
  { label: "Neurologist", value: "Neurologist" },
  { label: "Nutritionist", value: "Nutritionist" },
  { label: "Oculoplastic Surgeon", value: "Oculoplastic Surgeon" },
  { label: "Oncologist", value: "Oncologist" },
  { label: "Ophthalmologist", value: "Ophthalmologist" },
  { label: "Orthopedist", value: "Orthopedist" },
  { label: "Otorhinolaryngologist", value: "Otorhinolaryngologist" },
  { label: "Pediatrician", value: "Pediatrician" },
  { label: "Phlebologist", value: "Phlebologist" },
  { label: "Plastic Surgeon", value: "Plastic Surgeon" },
  { label: "Psychiatrist", value: "Psychiatrist" },
  { label: "Radiologist", value: "Radiologist" },
  { label: "Rheumatologist", value: "Rheumatologist" },
  { label: "Stomatologist", value: "Stomatologist" },
  { label: "Urologist", value: "Urologist" },
  { label: "Beautician", value: "Beautician" },
  { label: "Biomedical engineers", value: "Biomedical engineers" },
  { label: "Dental Surgeon", value: "Dental Surgeon" },
  { label: "Dietetician", value: "Dietetician" },
  { label: "Kinesiotherapist", value: "Kinesiotherapist" },
  { label: "Medical office staff", value: "Medical office staff" },
  { label: "Nurse Practitioner", value: "Nurse Practitioner" },
  { label: "Pharmacist", value: "Pharmacist" },
  { label: "Pharmacologist", value: "Pharmacologist" },
  { label: "PhD - Research", value: "PhD - Research" },
  { label: "Physician Assistant", value: "Physician Assistant" },
  { label: "Psychologist", value: "Psychologist" },
  { label: "Registered Nurse", value: "Registered Nurse" },
  { label: "Chartered Accountant", value: "Chartered Accountant" },
  { label: "Corporate finance", value: "Corporate finance" },
  { label: "Event organizer", value: "Event organizer" },
  { label: "Industry Representative", value: "Industry Representative" },
  {
    label: "Journalist economy / finance",
    value: "Journalist economy / finance",
  },
  { label: "Journalist mass audience", value: "Journalist mass audience" },
  { label: "Lawyer", value: "Lawyer" },
  { label: "Marketing consultant", value: "Marketing consultant" },
  { label: "Press officer", value: "Press officer" },
  { label: "Translator", value: "Translator" },
  { label: "Travel agent", value: "Travel agent" },
  { label: "Not specified", value: "Not specified" },
];
const countryList = [
  { label: " Afghanistan", value: "Afghanistan" },
  { label: " Albania", value: "Albania" },
  { label: " Algeria", value: "Algeria" },
  { label: " American Samoa", value: "American Samoa" },
  { label: " Andorra", value: "Andorra" },
  { label: " Angola", value: "Angola" },
  { label: " Anguilla", value: "Anguilla" },
  { label: " Antarctica", value: "Antarctica" },
  { label: " Antigua and Barbuda", value: "Antigua and Barbuda" },
  { label: " Argentina", value: "Argentina" },
  { label: " Armenia", value: "Armenia" },
  { label: " Aruba", value: "Aruba" },
  { label: " Australia", value: "Australia" },
  { label: " Austria", value: "Austria" },
  { label: " Azerbaijan", value: "Azerbaijan" },
  { label: " Bahamas", value: "Bahamas" },
  { label: " Bahrain", value: "Bahrain" },
  { label: " Bangladesh", value: "Bangladesh" },
  { label: " Barbados", value: "Barbados" },
  { label: " Belarus", value: "Belarus" },
  { label: " Belgium", value: "Belgium" },
  { label: " Belize", value: "Belize" },
  { label: " Benin", value: "Benin" },
  { label: " Bermuda", value: "Bermuda" },
  { label: " Bhutan", value: "Bhutan" },
  { label: " Bolivia", value: "Bolivia" },
  {
    label: " Bonaire, Sint Eustatius and Saba",
    value: "Bonaire, Sint Eustatius and Saba",
  },
  { label: " Bosnia and Herzegovina", value: "Bosnia and Herzegovina" },
  { label: " Botswana", value: "Botswana" },
  { label: " Brazil", value: "Brazil" },
  { label: " British Virgin Islands", value: "British Virgin Islands" },
  { label: " Brunei", value: "Brunei" },
  { label: " Bulgaria", value: "Bulgaria" },
  { label: " Burkina Faso", value: "Burkina Faso" },
  { label: " Burundi", value: "Burundi" },
  { label: " Cambodia", value: "Cambodia" },
  { label: " Cameroon", value: "Cameroon" },
  { label: " Canada", value: "Canada" },
  { label: " Cape Verde", value: "Cape Verde" },
  { label: " Cayman Islands", value: "Cayman Islands" },
  { label: " Central African Republic", value: "Central African Republic" },
  { label: " Chad", value: "Chad" },
  { label: " Chile", value: "Chile" },
  { label: " China", value: "China" },
  { label: " Christmas Island", value: "Christmas Island" },
  { label: " Cocos(Keeling) Islands", value: "Cocos(Keeling) Islands" },
  { label: " Colombia", value: "Colombia" },
  { label: " Comoros", value: "Comoros" },
  {
    label: " Congo(Democratic Republic of the)",
    value: "Congo(Democratic Republic of the)",
  },
  { label: " Congo(Republic of the)", value: "Congo(Republic of the)" },
  { label: " Cook Islands", value: "Cook Islands" },
  { label: " Costa Rica", value: "Costa Rica" },
  { label: " Croatia", value: "Croatia" },
  { label: " Cuba", value: "Cuba" },
  { label: " Curaçao", value: "Curaçao" },
  { label: " Cyprus", value: "Cyprus" },
  { label: " Czech Republic", value: "Czech Republic" },
  { label: " Côte d'Ivoire", value:"Côte d'Ivoire" },
  { label: " Denmark", value: "Denmark" },
  { label: " Djibouti", value: "Djibouti" },
  { label: " Dominica", value: "Dominica" },
  { label: " Dominican Republic", value: "Dominican Republic" },
  { label: " Ecuador", value: "Ecuador" },
  { label: " Egypt", value: "Egypt" },
  { label: " El Salvador", value: "El Salvador" },
  { label: " Equatorial Guinea", value: "Equatorial Guinea" },
  { label: " Eritrea", value: "Eritrea" },
  { label: " Estonia", value: "Estonia" },
  { label: " Ethiopia", value: "Ethiopia" },
  { label: " Falkland Islands", value: "Falkland Islands" },
  { label: " Faroe Islands", value: "Faroe Islands" },
  { label: " Fiji", value: "Fiji" },
  { label: " Finland", value: "Finland" },
  { label: " France", value: "France" },
  { label: " French Guiana", value: "French Guiana" },
  { label: " French Polynesia", value: "French Polynesia" },
  { label: " Gabon", value: "Gabon" },
  { label: " Gambia", value: "Gambia" },
  { label: " Georgia", value: "Georgia" },
  { label: " Germany", value: "Germany" },
  { label: " Ghana", value: "Ghana" },
  { label: " Gibraltar", value: "Gibraltar" },
  { label: " Greece", value: "Greece" },
  { label: " Greenland", value: "Greenland" },
  { label: " Grenada", value: "Grenada" },
  { label: " Guadeloupe", value: "Guadeloupe" },
  { label: " Guam", value: "Guam" },
  { label: " Guatemala", value: "Guatemala" },
  { label: " Guernsey", value: "Guernsey" },
  { label: " Guinea", value: "Guinea" },
  { label: " Guinea - Bissau", value: "Guinea - Bissau" },
  { label: " Guyana", value: "Guyana" },
  { label: " Haiti", value: "Haiti" },
  { label: " Honduras", value: "Honduras" },
  { label: " Hong Kong SAR China", value: "Hong Kong SAR China" },
  { label: " Hungary", value: "Hungary" },
  { label: " Iceland", value: "Iceland" },
  { label: " India", value: "India" },
  { label: " Indonesia", value: "Indonesia" },
  { label: " Iran", value: "Iran" },
  { label: " Iraq", value: "Iraq" },
  { label: " Ireland", value: "Ireland" },
  { label: " Isle of Man", value: "Isle of Man" },
  { label: " Israel", value: "Israel" },
  { label: " Italy", value: "Italy" },
  { label: " Jamaica", value: "Jamaica" },
  { label: " Japan", value: "Japan" },
  { label: " Jersey", value: "Jersey" },
  { label: " Jordan", value: "Jordan" },
  { label: " Kazakhstan", value: "Kazakhstan" },
  { label: " Kenya", value: "Kenya" },
  { label: " Kiribati", value: "Kiribati" },
  { label: " Kosovo", value: "Kosovo" },
  { label: " Kuwait", value: "Kuwait" },
  { label: " Kyrgyzstan", value: "Kyrgyzstan" },
  { label: " Laos", value: "Laos" },
  { label: " Latvia", value: "Latvia" },
  { label: " Lebanon", value: "Lebanon" },
  { label: " Lesotho", value: "Lesotho" },
  { label: " Liberia", value: "Liberia" },
  { label: " Libya", value: "Libya" },
  { label: " Liechtenstein", value: "Liechtenstein" },
  { label: " Lithuania", value: "Lithuania" },
  { label: " Luxembourg", value: "Luxembourg" },
  { label: " Macau SAR China", value: "Macau SAR China" },
  { label: " Macedonia", value: "Macedonia" },
  { label: " Madagascar", value: "Madagascar" },
  { label: " Malawi", value: "Malawi" },
  { label: " Malaysia", value: "Malaysia" },
  { label: " Maldives", value: "Maldives" },
  { label: " Mali", value: "Mali" },
  { label: " Malta", value: "Malta" },
  { label: " Marshall Islands", value: "Marshall Islands" },
  { label: " Martinique", value: "Martinique" },
  { label: " Mauritania", value: "Mauritania" },
  { label: " Mauritius", value: "Mauritius" },
  { label: " Mayotte", value: "Mayotte" },
  { label: " Mexico", value: "Mexico" },
  { label: " Micronesia", value: "Micronesia" },
  { label: " Moldova", value: "Moldova" },
  { label: " Monaco", value: "Monaco" },
  { label: " Mongolia", value: "Mongolia" },
  { label: " Montenegro", value: "Montenegro" },
  { label: " Montserrat", value: "Montserrat" },
  { label: " Morocco", value: "Morocco" },
  { label: " Mozambique", value: "Mozambique" },
  { label: " Myanmar", value: "Myanmar" },
  { label: " Namibia", value: "Namibia" },
  { label: " Nauru", value: "Nauru" },
  { label: " Nepal", value: "Nepal" },
  { label: " Netherlands", value: "Netherlands" },
  { label: " New Caledonia", value: "New Caledonia" },
  { label: " New Zealand", value: "New Zealand" },
  { label: " Nicaragua", value: "Nicaragua" },
  { label: " Niger", value: "Niger" },
  { label: " Nigeria", value: "Nigeria" },
  { label: " Niue", value: "Niue" },
  { label: " Norfolk Island", value: "Norfolk Island" },
  { label: " North Korea", value: "North Korea" },
  { label: " Northern Mariana Islands", value: "Northern Mariana Islands" },
  { label: " Norway", value: "Norway" },
  { label: " Oman", value: "Oman" },
  { label: " Pakistan", value: "Pakistan" },
  { label: " Palau", value: "Palau" },
  { label: " Palestine", value: "Palestine" },
  { label: " Panama", value: "Panama" },
  { label: " Papua New Guinea", value: "Papua New Guinea" },
  { label: " Paraguay", value: "Paraguay" },
  { label: " Peru", value: "Peru" },
  { label: " Philippines", value: "Philippines" },
  { label: " Poland", value: "Poland" },
  { label: " Portugal", value: "Portugal" },
  { label: " Puerto Rico", value: "Puerto Rico" },
  { label: " Qatar", value: "Qatar" },
  { label: " Romania", value: "Romania" },
  { label: " Russia", value: "Russia" },
  { label: " Rwanda", value: "Rwanda" },
  { label: " Réunion", value: "Réunion" },
  { label: " Saint Barthélemy", value: "Saint Barthélemy" },
  { label: " Saint Kitts and Nevis", value: "Saint Kitts and Nevis" },
  { label: " Saint Lucia", value: "Saint Lucia" },
  { label: " Saint Martin", value: "Saint Martin" },
  { label: " Saint Pierre and Miquelon", value: "Saint Pierre and Miquelon" },
  {
    label: " Saint Vincent and the Grenadines",
    value: "Saint Vincent and the Grenadines",
  },
  { label: " Samoa", value: "Samoa" },
  { label: " San Marino", value: "San Marino" },
  {
    label: " Santa Helena, Ascension and Tristan de Cuhna",
    value: "Santa Helena, Ascension and Tristan de Cuhna",
  },
  { label: " Saudi Arabia", value: "Saudi Arabia" },
  { label: " Senegal", value: "Senegal" },
  { label: " Serbia", value: "Serbia" },
  { label: " Seychelles", value: "Seychelles" },
  { label: " Sierra Leone", value: "Sierra Leone" },
  { label: " Singapore", value: "Singapore" },
  { label: " Sint Maarten", value: "Sint Maarten" },
  { label: " Slovakia", value: "Slovakia" },
  { label: " Slovenia", value: "Slovenia" },
  { label: " Solomon Islands", value: "Solomon Islands" },
  { label: " Somalia", value: "Somalia" },
  { label: " South Africa", value: "South Africa" },
  { label: " South Korea", value: "South Korea" },
  { label: " South Sudan", value: "South Sudan" },
  { label: " Spain", value: "Spain" },
  { label: " Sri Lanka", value: "Sri Lanka" },
  { label: " Sudan", value: "Sudan" },
  { label: " Suriname", value: "Suriname" },
  { label: " Svalbard and Jan Mayen", value: "Svalbard and Jan Mayen" },
  { label: " Swaziland", value: "Swaziland" },
  { label: " Sweden", value: "Sweden" },
  { label: " Switzerland", value: "Switzerland" },
  { label: " Syria", value: "Syria" },
  { label: " São Tomé and Príncipe", value: "São Tomé and Príncipe" },
  { label: " Taiwan", value: "Taiwan" },
  { label: " Tajikistan", value: "Tajikistan" },
  { label: " Tanzania", value: "Tanzania" },
  { label: " Thailand", value: "Thailand" },
  { label: " Timor - Leste", value: "Timor - Leste" },
  { label: " Togo", value: "Togo" },
  { label: " Tonga", value: "Tonga" },
  { label: " Trinidad and Tobago", value: "Trinidad and Tobago" },
  { label: " Tunisia", value: "Tunisia" },
  { label: " Turkey", value: "Turkey" },
  { label: " Turkmenistan", value: "Turkmenistan" },
  { label: " Turks and Caicos Islands", value: "Turks and Caicos Islands" },
  { label: " Tuvalu", value: "Tuvalu" },
  { label: " U.S.Virgin Islands", value: "U.S.Virgin Islands" },
  { label: " Uganda", value: "Uganda" },
  { label: " Ukraine", value: "Ukraine" },
  { label: " United Arab Emirates", value: "United Arab Emirates" },
  { label: " United Kingdom", value: "United Kingdom" },
  { label: " United States", value: "United States" },
  { label: " Uruguay", value: "Uruguay" },
  { label: " Uzbekistan", value: "Uzbekistan" },
  { label: " Vanuatu", value: "Vanuatu" },
  { label: " Venezuela", value: "Venezuela" },
  { label: " Vietnam", value: "Vietnam" },
  { label: " Wallis and Futuna", value: "Wallis and Futuna" },
  { label: " Western Sahara", value: "Western Sahara" },
  { label: " Yemen", value: "Yemen" },
  { label: " Zambia", value: "Zambia" },
  { label: " Zimbabwe", value: "Zimbabwe" },
  { label: " Åland Islands", value: "Åland Islands" },
];
