import React, { useEffect, useRef, useState, useCallback } from "react";
import style from "css/landing.module.css";
import { ArrowRightOutlined } from "@ant-design/icons";
import { motion } from "framer-motion";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import { useMediaQuery } from "react-responsive";
import { Image, Skeleton } from "antd";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function NewsLetterArea() {
  const navigate = useNavigate();
  const clickHandler = (data) => {
    navigate(process.env.REACT_APP_CLIENT_URL + data);
  };

  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const isTablet = useMediaQuery({
    query: "(min-width: 768px) and (max-width: 1023px)",
  });

  const prevRef = useRef(null);
  const nextRef = useRef(null);

  const [newsletterList, setNewsletterList] = useState([]);

  const getNewsletterList = useCallback(() => {
    const url = "/newsletter/list";
    const param = {
      params: {},
    };

    axios.get(url, param).then((res) => {
      if (res.status === 200) {
        setNewsletterList(res.data.list);
      }
    });
  }, []);

  useEffect(() => {
    getNewsletterList();
  }, [getNewsletterList]);

  return (
    <div className={style.newsletter_area}>
      <div className={style.newsletter_title_wrap}>
        <div className={style.newsletter_title}>Newsletter</div>
        <div className={style.newsletter_explanation}>
          <div />
          <span>
            Want to know more about Jetema’s latest news, scientific findings
            and global presence?
          </span>
        </div>
        <div className={style.newsletter_viewall}>
          {isMobile ? (
            <>
              <span>View All</span>
              <div
                className={style.newsletter_viewall_btn}
                onClick={() => clickHandler("newsletter")}
              >
                <ArrowRightOutlined style={{ color: "#f15928" }} />
              </div>
            </>
          ) : (
            <>
              <div ref={prevRef} className={style.product_swiper_prev_btn}>
                <img
                  alt=""
                  src={
                    process.env.REACT_APP_CLIENT_URL +
                    "img/landing/arrow_black.png"
                  }
                />
              </div>

              <div ref={nextRef} className={style.product_swiper_next_btn}>
                <img
                  alt=""
                  src={
                    process.env.REACT_APP_CLIENT_URL +
                    "img/landing/arrow_black.png"
                  }
                />
              </div>
            </>
          )}
        </div>
      </div>

      {/* <div className={style.newletter_list}>
        {isMobile ? (
          newsletterList.slice(0, 2).map((newsLetter, index) => (
            <motion.div
              key={index}
              className={style.newletter_item}
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true, amount: 0.2 }}
              variants={{
                offscreen: {
                  opacity: 0,
                  scale: 0.5,
                },
                onscreen: {
                  opacity: 1,
                  scale: 1,
                  transition: {
                    duration: 0.8,
                    delay: 0.5 * index,
                    ease: [0, 0.71, 0.2, 1.01],
                  },
                },
              }}
            >
              {newsLetter.THUMBNAIL_IMG === "" ? (
                <Skeleton.Image
                  active
                  style={
                    isMobile
                      ? { width: "100%", height: "115px" }
                      : isTablet
                      ? { width: "235px", height: "165px" }
                      : { width: "359px", height: "428px" }
                  }
                />
              ) : (
                <Image
                  // className={style.webinar_register_img}
                  preview={false}
                  // width={100%}
                  // height={428}
                  src={newsLetter.THUMBNAIL_IMG}
                />
              )}
              <div className={style.newsletter_item_title}>
                {newsLetter.TITLE}
              </div>
              <div className={style.newsletter_item_text}>
                {newsLetter.DESCRIPTION}
              </div>
            </motion.div>
          ))
        ) : (
          <motion.div
            // ref={carouselRef}
            className={style.newsletter_list_carousel}
            // style={{ x }}
          >
            {newsletterList.map((newsLetter, index) => (
              <motion.div
                // {...slideAnimation}
                key={index}
                className={style.newsletter_item}
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0.2 }}
                // variants={{
                //   offscreen: {
                //     opacity: 0,
                //     scale: 0.5,
                //   },
                //   onscreen: {
                //     opacity: 1,
                //     scale: 1,
                //     transition: {
                //       duration: 0.8,
                //       delay: 0.5 * index,
                //       ease: [0, 0.71, 0.2, 1.01],
                //     },
                //   },
                // }}
              >
                {newsLetter.THUMBNAIL_IMG === "" ? (
                  <Skeleton.Image
                    active
                    style={
                      isMobile
                        ? { width: "100%" }
                        : isTablet
                        ? { width: "235px", height: "165px" }
                        : { width: "442px", height: "311px" }
                    }
                  />
                ) : (
                  <Image
                    // className={style.webinar_register_img}
                    preview={false}
                    width={isMobile ? "auto" : isTablet ? 235 : 442}
                    // height={"100%"}
                    src={newsLetter.THUMBNAIL_IMG}
                  />
                )}
                <div className={style.newsletter_item_title}>
                  {newsLetter.TITLE}
                </div>
                <div className={style.newsletter_item_text}>
                  {newsLetter.DESCRIPTION}
                </div>
              </motion.div>
            ))}
          </motion.div>
        )}
      </div> */}

      {isMobile ? (
        <div className={style.newletter_list}>
          {newsletterList.slice(0, 2).map((newsLetter, index) => (
            <motion.div
              key={index}
              className={style.newletter_item}
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true, amount: 0.2 }}
              variants={{
                offscreen: {
                  opacity: 0,
                  scale: 0.5,
                },
                onscreen: {
                  opacity: 1,
                  scale: 1,
                  transition: {
                    duration: 0.8,
                    delay: 0.5 * index,
                    ease: [0, 0.71, 0.2, 1.01],
                  },
                },
              }}
              onClick={() =>
                clickHandler("newsletter?id=" + newsLetter.NEWSLETTER_ID)
              }
            >
              {newsLetter.THUMBNAIL_IMG === "" ? (
                <Skeleton.Image
                  active
                  style={
                    isMobile
                      ? { width: "100%", height: "115px" }
                      : isTablet
                      ? { width: "235px", height: "165px" }
                      : { width: "359px", height: "428px" }
                  }
                />
              ) : (
                <Image
                  // className={style.webinar_register_img}
                  // width={100%}
                  // height={428}
                  preview={false}
                  src={newsLetter.THUMBNAIL_IMG}
                />
              )}
              <div className={style.newsletter_item_title}>
                {newsLetter.TITLE}
              </div>
              <div className={style.newsletter_item_text}>
                {newsLetter.DESCRIPTION}
              </div>
            </motion.div>
          ))}
        </div>
      ) : (
        <Swiper
          navigation={{ prevEl: prevRef.current, nextEl: nextRef.current }}
          modules={[Navigation]}
          loop={true}
          slidesPerView={isMobile ? 1 : 2.8}
          spaceBetween={isTablet ? 16 : 44}
          onSwiper={(swiper) => {
            setTimeout(() => {
              // Override prevEl & nextEl now that refs are defined
              swiper.params.navigation.prevEl = prevRef.current;
              swiper.params.navigation.nextEl = nextRef.current;

              // Re-init navigation
              swiper.navigation.destroy();
              swiper.navigation.init();
              swiper.navigation.update();
            });
          }}
        >
          {newsletterList.map((newsLetter, index) => (
            <SwiperSlide key={index} className={style.newsletter_swiper_slide}>
              <div
                className={`${style.newsletter_item} cursor`}
                onClick={() =>
                  clickHandler("newsletter?id=" + newsLetter.NEWSLETTER_ID)
                }
                // initial="offscreen"
                // whileInView="onscreen"
                // viewport={{ once: true, amount: 0.2 }}
                // variants={{
                //   offscreen: {
                //     opacity: 0,
                //     scale: 0.5,
                //   },
                //   onscreen: {
                //     opacity: 1,
                //     scale: 1,
                //     transition: {
                //       duration: 0.8,
                //       delay: 0.5,
                //       ease: [0, 0.71, 0.2, 1.01],
                //     },
                //   },
                // }}
              >
                {newsLetter.THUMBNAIL_IMG === "" ? (
                  <Skeleton.Image
                    active
                    style={
                      isMobile
                        ? { width: "100%" }
                        : isTablet
                        ? { width: "235px", height: "165px" }
                        : { width: "442px", height: "311px" }
                    }
                  />
                ) : (
                  <div className={style.newsletter_img_wrapper}>
                    <img
                      className={style.newsletter_img}
                      // preview={false}
                      alt=""
                      style={{
                        width: isMobile ? "auto" : isTablet ? 235 : 442,
                      }}
                      // width={isMobile ? "auto" : isTablet ? 235 : 442}
                      // height={"100%"}
                      src={newsLetter.THUMBNAIL_IMG}
                    />
                  </div>
                )}
                <div className={style.newsletter_item_title}>
                  {newsLetter.TITLE}
                </div>
                <div className={style.newsletter_item_text}>
                  {newsLetter.DESCRIPTION}
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </div>
  );
}

export default NewsLetterArea;
