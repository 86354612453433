import { createSlice } from "@reduxjs/toolkit";

/**
 * 파일명: alertSlice.js
 * 작성자: 권도훈
 * 작성일자: 23.04.06
 * 설명: alert, confirm
 */
const alertSlice = createSlice({
  name: "alertSlice",
  initialState: {
    display: false,
    cancleBtn: false,
    title: "",
    content: "",
    img: "",
    submitEventHandler: function () {},
    cancelEventHandler: function () {},
  },
  reducers: {
    openAlert: (state, action) => {
      state.display = true;
      state.cancleBtn = false;
      state.title = action.payload.title;
      state.img = action.payload.img !== undefined ? action.payload.img : "";
      state.content = action.payload.content;
      state.submitEventHandler = () => {
        action.payload.submitEventHandler();
      };
      state.cancelEventHandler = () => {
        if (typeof action.payload.cancelEventHandler === "function") {
          action.payload.cancelEventHandler();
        }
      };
    },
    openConfirm: (state, action) => {
      state.display = true;
      state.cancleBtn = true;
      state.title = action.payload.title;
      state.img = action.payload.img !== undefined ? action.payload.img : "";
      state.content = action.payload.content;
      state.submitEventHandler = () => {
        action.payload.submitEventHandler();
      };
      state.cancelEventHandler = () => {
        if (typeof action.payload.cancelEventHandler === "function") {
          action.payload.cancelEventHandler();
        }
      };
    },
    closeAlert: (state) => {
      state.display = false;
    },
  },
});

export default alertSlice;

export const { openAlert, openConfirm, closeAlert } = alertSlice.actions;
