import { createSlice } from "@reduxjs/toolkit";

/**
 * 파일명: calendarRangeSlice.js
 * 작성자: 강연승
 * 작성일자: 23.04.12
 * 설명: 캘린더 출력 범위 정보
 */

const calendarRangeSlice = createSlice({
  name: "calendarRangeSlice",
  initialState: {
    VIEW: false,
    ACTIVE_START: "",
    ACTIVE_END: "",
  },
  reducers: {
    updateCalendarRange: (state, action) => {
      state.VIEW = action.payload.VIEW;
      state.ACTIVE_START = action.payload.ACTIVE_START;
      state.ACTIVE_END = action.payload.ACTIVE_END;
    },
  },
});

export default calendarRangeSlice;

export const { updateCalendarRange } = calendarRangeSlice.actions;
