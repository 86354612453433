import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeAlert } from "redux/slices/alertSlice";
import { Modal, Button, ConfigProvider } from "antd";
import { useTranslation } from "react-i18next";
import { ReactComponent as Icon } from "asset/common/alert.svg";

/**
 * 파일명: alert.js
 * 작성자: 권도훈
 * 작성일자: 23.04.06
 * 설명: 공통 alert, comfirm 컴포넌트
 */
function Alert() {
  //dispatch
  const dispatch = useDispatch();

  const { t } = useTranslation();

  //payload
  const payload = useSelector((state) => state.alert);

  //close
  const closeEventHandler = () => {
    payload.cancelEventHandler();
    dispatch(closeAlert());
  };

  //submit
  const submitEventHandler = () => {
    payload.submitEventHandler();
    closeEventHandler();
  };

  // Modal.config({});
  ConfigProvider.config({});

  return (
    <Modal
      className="alert-modal"
      zIndex="1111"
      title={
        <section className="alert-header">
          {/* {payload.img !== undefined && payload.img !== "" && payload.img} */}
          <div className="alert-icon">
            <Icon />
          </div>
          <p className="alert-title">{payload.title}</p>
        </section>
      }
      centered
      maskClosable={false}
      keyboard={false}
      open={payload.display}
      onOk={() => submitEventHandler()}
      onCancel={() => closeEventHandler()}
      closable={false}
      okText={t("common.button.ok")}
      footer={
        payload.cancleBtn ? (
          <>
            <ConfigProvider
              theme={{
                components: {
                  Button: {
                    colorText: "#13348f",
                    colorPrimaryActive: "#13348f",
                    colorPrimaryHover: "#13348f",
                    colorBgMask: "#1f299c",
                    fontFamily: "Pretendard",
                  },
                },
              }}
            >
              <Button size="large" onClick={closeEventHandler}>
                Cancel
              </Button>
            </ConfigProvider>
            <Button type="primary" size="large" onClick={submitEventHandler}>
              Confirm
            </Button>
          </>
        ) : (
          <Button type="primary" size="large" onClick={submitEventHandler}>
            Confirm
          </Button>
        )
      }
      cancelText="Cancel"
    >
      <section className="alert-content">{payload.content}</section>
    </Modal>
  );
}

export default Alert;
