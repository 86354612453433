import axios from "axios";
import LibraryCard from "components/library/libraryCard";
import style from "css/library/library.module.css";
import { useCallback, useEffect, useState } from "react";

function Library() {
  // const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const [init, setInit] = useState(true);
  const [jamDataList, setJamDataList] = useState([]);

  // Get Library Data
  const getLibraryData = useCallback(() => {
    axios
      .get("/library/event")
      .then((res) => {
        if (res.status === 200) {
          console.log("success");
          console.log(res.data);
          setJamDataList(res.data);
        }
      })
      .catch(() => {
        console.log("failed");
      })
      .finally(() => {});
  });

  useEffect(() => {
    if (init) {
      getLibraryData();
      setInit(false);
    }
  }, [getLibraryData, init]);

  // 회차 역순으로 재정렬
  const sortListDesc = (listData) => {
    return [...listData].sort((a, b) => b.episode - a.episode);
  };

  // const sortedJamData = sortListDesc(jamDataList());

  return (
    <div className={style.main_wrapper}>
      <div className={style.title}>
        Library <span>Watch our previous JAM videos.</span>
      </div>
      <div className={style.content}>
        {jamDataList.map((data, idx) => (
          <LibraryCard data={data} key={idx} />
        ))}
      </div>
    </div>
  );
}

export default Library;
