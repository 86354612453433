import axios from "axios";
import Alert from "components/common/alert";
import "css/common.css";
import Landing from "pages/common/landing";
import NotFound from "pages/common/notFound";
import { useCallback, useLayoutEffect } from "react";
import { Cookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { openAlert } from "redux/slices/alertSlice";
import Body from "components/template/body";
import Footer from "components/template/footer";
import Header from "components/template/header";
import Wrapper from "components/template/wrapper";
import "lang/i18n";
import Library from "pages/library/library";
import LibraryDetail from "pages/library/libraryDetail";
import Newsletter from "pages/newsletter/newsletter";
import { insertUserInfo } from "redux/slices/userSlice";
import Product from "pages/product/product";
import Productdetail from "components/product/productdetail";
import Event from "pages/register/event";
import EventList from "pages/register/eventList";
import EventRegister from "pages/register/eventRegister";
import Contact from "pages/contact/contact";
import MyPage from "pages/myPage/myPage";
import Signup from "pages/account/signup";
import Signin from "pages/account/signin";
import Password from "pages/account/password";
import Reset from "pages/account/reset";
import Academy from "pages/academy/academy";
import moment from "moment";
import ScrollToTop from "components/common/scrollToTop";

/**
 * 파일명: root.js
 * 작성자: 권도훈
 * 작성일자: 23.04.06
 * 설명: 메인 라우터
 * axios 설정 처리
 * 스크린 사이즈에 따른 css 처리
 */
function Root() {
  //dispatch
  const dispatch = useDispatch();

  //cookie
  const cookies = new Cookies();

  //selector
  const userPayload = useSelector((state) => state.user);

  //env
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const authNm = process.env.REACT_APP_AUTH_TOKEN;
  // const authTime = process.env.REACT_APP_AUTH_TOKEN_TIME;
  const refreshNm = process.env.REACT_APP_REFRESH_TOKEN;
  const domain = process.env.REACT_APP_DOMAIN;

  //set screen size
  const setScreenSize = useCallback(() => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    let prevVisualViewport = 0;
    const currentVisualViewportHeight = window.visualViewport.height;

    if (
      prevVisualViewport - 30 > currentVisualViewportHeight &&
      prevVisualViewport - 100 < currentVisualViewportHeight
    ) {
      const scrollHeight = window.document.scrollingElement.scrollHeight;
      const scrollTop = scrollHeight - window.visualViewport.height;

      window.scrollTo(0, scrollTop);
    }

    prevVisualViewport = window.visualViewport.height;

    window.visualViewport.onresize = prevVisualViewport;
  }, []);

  //refresh user info
  const refreshInfo = useCallback(() => {
    axios
      .get("/user/refresh/info")
      .then((res) => {
        if (res.status === 200) {
          const memeberInfo = res.data;

          dispatch(
            insertUserInfo({
              name: memeberInfo.name,
              id: memeberInfo.id,
            })
          );
        }
      })
      .catch(() => {
        const effectCookies = new Cookies();
        effectCookies.remove(authNm, { path: "/", domain: domain });
        effectCookies.remove(refreshNm, { path: "/", domain: domain });
        window.location.href = process.env.REACT_APP_CLIENT_URL;
      });
  }, [authNm, dispatch, domain, refreshNm]);

  //resize eventListener
  useLayoutEffect(() => {
    setScreenSize();
    window.addEventListener("resize", () => setScreenSize());
  });

  useLayoutEffect(() => {
    const effectCookies = new Cookies();
    const refreshCookie = effectCookies.get(refreshNm);

    if (userPayload.name === "" && refreshCookie !== undefined) {
      refreshInfo();
    }
  }, [authNm, refreshInfo, refreshNm, userPayload.name]);

  //axios
  axios.defaults.url = serverUrl;
  axios.defaults.baseURL = serverUrl;
  axios.defaults.withCredentials = false;

  //axios request
  axios.interceptors.request.use(
    async (config) => {
      if (config.params == null) {
        config.params = {};
      }

      const authCookie = cookies.get(authNm);
      const refreshCookie = cookies.get(refreshNm);

      if (authCookie !== undefined && refreshCookie !== undefined) {
        config.headers.Authorization = `Bearer ${authCookie}`;
        config.headers.RefreshAuthorization = `Bearer ${refreshCookie}`;
      }

      return config;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  //axios response
  axios.interceptors.response.use(
    (res) => {
      if (res.headers.refresh_authorization !== undefined) {
        cookies.remove(authNm, { path: "/", domain: domain });
        cookies.set(authNm, res.headers.refresh_authorization, {
          path: "/",
          domain: domain,
        });
      }

      return res;
    },
    (err) => {
      const response = err.response;

      if (response !== undefined) {
        const status = response.status;

        if (status === 400) {
          cookies.remove(authNm, { path: "/", domain: domain });
          cookies.remove(refreshNm, { path: "/", domain: domain });
        } else if (status === 401) {
          cookies.remove(authNm, { path: "/", domain: domain });
          cookies.remove(refreshNm, { path: "/", domain: domain });

          dispatch(
            openAlert({
              title: "Notice",
              content: err.response.data,
              submitEventHandler: () =>
                (window.location.href = process.env.REACT_APP_CLIENT_URL),
            })
          );
        } else if (status === 404) {
          throw new Error("It is an unknown request.");
        }
      } else {
        dispatch(
          openAlert({
            title: "Error",
            content: err.code,
            submitEventHandler: () => {},
          })
        );
      }

      return Promise.reject(err);
    }
  );

  const visitCount = (today) => {
    let url = "/user/visitCount";
    axios.post(url).then((res) => {
      if (res.status >= 200 && res.status < 300) {
        cookies.set("connectTime", today.format("YYYY-MM-DD"), {
          maxAge: 60 * 60 * 24 * 1000,
        });
      }
    });
  };

  let today = moment();
  let connect_time = cookies.get("connectTime");
  if (connect_time) {
    if (moment(connect_time).isAfter(today, "day")) {
      visitCount(today);
    }
  } else {
    visitCount(today);
  }

  return (
    <div className="container">
      <Alert />

      <BrowserRouter>
        <ScrollToTop />
        <Wrapper>
          <Header />
          <Body>
            <Routes>
              <Route
                path={process.env.REACT_APP_CLIENT_URL}
                element={<Landing />}
              />
              <Route
                path={process.env.REACT_APP_CLIENT_URL + "mypage"}
                element={<MyPage />}
              />
              <Route
                path={process.env.REACT_APP_CLIENT_URL + "signin"}
                element={<Signin />}
              />
              <Route
                path={process.env.REACT_APP_CLIENT_URL + "signup"}
                element={<Signup />}
              />
              <Route
                path={process.env.REACT_APP_CLIENT_URL + "password"}
                element={<Password />}
              />
              <Route
                path={process.env.REACT_APP_CLIENT_URL + "reset"}
                element={<Reset />}
              />
              <Route
                path={process.env.REACT_APP_CLIENT_URL + "register"}
                element={<Event />}
              />
              {/* <Route
                path={process.env.REACT_APP_CLIENT_URL + "register"}
                element={<EventList />}
              />
              <Route
                path={process.env.REACT_APP_CLIENT_URL + "register/:eventId"}
                element={<EventRegister />}
              /> */}
              <Route
                // path='/library'
                path={process.env.REACT_APP_CLIENT_URL + "library"}
                element={<Library />}
              />
              <Route
                path={process.env.REACT_APP_CLIENT_URL + "library/:libraryId"}
                element={<LibraryDetail />}
              />
              <Route
                path={process.env.REACT_APP_CLIENT_URL + "newsletter"}
                element={<Newsletter />}
              />
              <Route
                path={process.env.REACT_APP_CLIENT_URL + "product"}
                element={<Product />}
              />
              <Route
                path={process.env.REACT_APP_CLIENT_URL + "product/:productId"}
                element={<Productdetail />}
              />
              <Route
                path={process.env.REACT_APP_CLIENT_URL + "contactUs"}
                element={<Contact />}
              />
              <Route
                path={process.env.REACT_APP_CLIENT_URL + "academy"}
                element={<Academy />}
              />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Body>
          <Footer />
        </Wrapper>
      </BrowserRouter>
    </div>
  );
}

export default Root;
