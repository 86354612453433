import React, { useCallback, useEffect, useRef, useState } from "react";
import style from "css/library/sideNavBar.module.css";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

function NewsletterNavi({ newsletterId, total, refresh }) {
  const episode = () => {
    let list = [];
    for (let i = 0; i < total; i++) {
      list.push(i + 1);
    }

    return list;
  };

  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const navigate = useNavigate();
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [listPosition, setListPosition] = useState(0);
  const [listWrapperSize, setListWrapperSize] = useState(0);
  const [listContainerSize, setlistContainerSize] = useState(0);
  const listWrapper = useRef(null);
  const listContainer = useRef(null);

  // Arrow Handler
  const arrowUpHandler = useCallback(() => {
    let move = listPosition + 50;
    const max = Number(listWrapperSize) - Number(listContainerSize);
    if (move < max) move = max;
    setListPosition(move >= 0 ? 0 : move);
  }, [listPosition, listWrapperSize, listContainerSize]);
  const arrowDownHandler = useCallback(() => {
    const move = listPosition - 50;
    const max = Number(listWrapperSize) - Number(listContainerSize);
    setListPosition(move < max ? max : move);
  }, [listPosition, listWrapperSize, listContainerSize]);
  // Move Page
  const viewBtnHandler = (id) => {
    navigate(process.env.REACT_APP_CLIENT_URL + "newsletter?id=" + id);
    refresh();
  };

  // get style
  useEffect(() => {
    const listWrapperStyles = window.getComputedStyle(listWrapper.current);
    const listContainerStyles = window.getComputedStyle(listContainer.current);
    const listWrapperHeight = listWrapperStyles
      .getPropertyValue("height")
      .replace("px", "");
    const listContainerHeight = listContainerStyles
      .getPropertyValue("height")
      .replace("px", "");

    setListWrapperSize(Number(listWrapperHeight));
    setlistContainerSize(Number(listContainerHeight));
  }, [arrowDownHandler, arrowUpHandler]);

  function ListBtnArea() {
    return (
      <div className={style.top_btn} onClick={() => setIsNavOpen(!isNavOpen)}>
        <button className={style.list_btn}>
          <img
            className={style.list_icon}
            alt=""
            src={process.env.REACT_APP_CLIENT_URL + "img/library/list_icon.png"}
          />
          <span className={style.list_txt}>List</span>
        </button>
      </div>
    );
  }

  function ArrowBtnArea() {
    return (
      <div className={style.bot_btn}>
        <button className={style.arrow_btn} onClick={arrowUpHandler}>
          <img
            className={style.arrow_img}
            alt=""
            src={
              process.env.REACT_APP_CLIENT_URL +
              "img/library/black_arrow_up.png"
            }
          />
        </button>
        <button className={style.arrow_btn} onClick={arrowDownHandler}>
          <img
            className={style.arrow_img}
            alt=""
            src={
              process.env.REACT_APP_CLIENT_URL +
              "img/library/black_arrow_down.png"
            }
          />
        </button>
      </div>
    );
  }

  return (
    <div className={style.newsletter_nav_sidebar}>
      {isMobile ? (
        // 모바일
        <>
          <div
            className={`${style.content_area} ${isNavOpen ? style.open : ""}`}
          >
            {/* TOP */}
            {ArrowBtnArea()}

            <div className={style.divider} />

            {/* CENTER */}
            <div ref={listWrapper} className={style.list_wrapper}>
              <div
                ref={listContainer}
                className={style.list_container}
                style={{ top: `${listPosition}px` }}
              >
                {episode()
                  .reverse()
                  .map((ep, idx) => (
                    <button
                      className={`${style.episode_btn} ${
                        ep.toString() === newsletterId ? style.active : ""
                      }`}
                      onClick={() => viewBtnHandler(ep)}
                      key={idx}
                    >
                      {"vol. " + ep}
                    </button>
                  ))}
              </div>
            </div>

            <div className={style.divider} />
          </div>

          {/* BOTTOM */}
          {ListBtnArea()}
        </>
      ) : (
        // 태블릿 & 웹
        <>
          {/* TOP */}
          {ListBtnArea()}
          <div
            className={`${style.content_area} ${isNavOpen ? style.open : ""}`}
          >
            <div className={style.divider} />

            {/* CENTER */}
            <div ref={listWrapper} className={style.list_wrapper}>
              <div
                ref={listContainer}
                className={style.list_container}
                style={{ top: `${listPosition}px` }}
              >
                {episode()
                  .reverse()
                  .map((ep, idx) => (
                    <button
                      className={`${style.episode_btn} ${
                        ep.toString() === newsletterId ? style.active : ""
                      }`}
                      onClick={() => viewBtnHandler(ep)}
                      key={idx}
                    >
                      {"vol. " + ep}
                    </button>
                  ))}
              </div>
            </div>

            <div className={style.divider} />

            {/* BOTTOM */}
            {ArrowBtnArea()}
          </div>
        </>
      )}
    </div>
  );
}

export default NewsletterNavi;
