import axios from "axios";
import AgendaTable from "components/library/agendaTable";
import SideNavBar from "components/library/sideNavBar";
import SpeakerCard from "components/library/speakerCard";
import WebinarCard from "components/library/webinarCard";
import style from "css/library/libraryDetail.module.css";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useParams } from "react-router-dom";

function LibraryDetail() {
  const userPayload = useSelector((state) => state.user);
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const { libraryId } = useParams();
  const [init, setInit] = useState(true);
  const [isWebinarOpen, setIsWebinarOpen] = useState(false);
  const [webinarUrl, setWebinarUrl] = useState("");

  const [data, setData] = useState(null);

  // Get Library Data
  const getDetailData = useCallback(() => {
    const data = {
      params: {
        EVENT_ID: libraryId,
        // IS_LOGGEDIN: userPayload.name !== "",
      },
    };
    axios.get("/library/detail", data).then((res) => {
      if (res.status === 200) {
        console.log("==== detail data :: ");
        console.log(res.data);
        console.log(res.data.WEBINAR);
        setData(res.data);
      }
    });
  }, [libraryId]);

  useEffect(() => {
    if (init) {
      setData(null);
      getDetailData();
      setInit(false);
    }
  }, [libraryId, getDetailData, init]);

  function getOrdinal(number) {
    const suffixes = ["th", "st", "nd", "rd"];
    const lastTwoDigits = number % 100;
    const lastDigit = number % 10;
    const specialCases = [11, 12, 13];

    if (specialCases.includes(lastTwoDigits)) {
      return number + "th";
    } else if (suffixes[lastDigit]) {
      return number + suffixes[lastDigit];
    } else {
      return number + suffixes[0];
    }
  }

  // 2023-12-25 00:00:00 to 2023.12.25 MON
  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");

    const daysOfWeek = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
    const dayOfWeek = daysOfWeek[date.getDay()];

    return `${year}.${month}.${day} ${dayOfWeek}`;
  }

  return (
    <div className={style.main}>
      {data !== null ? (
        <>
          <div className={style.sideNavBar_area}>
            <SideNavBar
              libraryId={libraryId}
              episodeList={data.EPISODE_LIST}
              refresh={() => setInit(true)}
            />
          </div>
          <div className={style.main_wrapper}>
            {data.DETAIL !== null && (
              <div
                className={style.main_head}
                style={{
                  backgroundImage: `url(${
                    process.env.REACT_APP_CLIENT_URL +
                    (isMobile
                      ? data.DETAIL.DETAIL_IMG_MOBILE
                      : data.DETAIL.DETAIL_IMG_WEB)
                  })`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }}
              >
                <div className={style.main_head_content}>
                  <div
                    className={style.main_head_title}
                    style={{ color: `${data.DETAIL.FONT_COLOR}` }}
                  >
                    {getOrdinal(data.DETAIL.EPISODE)} Jetema Academic Meeting
                  </div>
                  <div
                    className={style.main_head_subtitle}
                    style={{ color: `${data.DETAIL.FONT_COLOR}` }}
                  >
                    {data.DETAIL.DESCRIPTION}
                    {/* The Latest Discussion About Minimally Invasive Aesthetic
                    Procedures */}
                  </div>
                  <div
                    className={style.main_head_datetime}
                    style={{ color: `${data.DETAIL.FONT_COLOR}` }}
                  >
                    {formatDate(data.DETAIL.START_TIME)}
                  </div>
                  <div className={style.main_head_platform}>
                    <img
                      className={style.camera_img}
                      alt=""
                      src={
                        process.env.REACT_APP_CLIENT_URL +
                        "img/library/camera_" +
                        data.DETAIL.FONT_COLOR +
                        ".png"
                      }
                    />
                    <span style={{ color: `${data.DETAIL.FONT_COLOR}` }}>
                      Platform | Zoom Webinar
                    </span>
                  </div>
                </div>
              </div>
            )}

            {/* Webinar */}
            {data.WEBINAR !== undefined &&
              data.WEBINAR !== null &&
              data.WEBINAR.length !== 0 && (
                <div className={style.webinar_wrapper}>
                  <div className={style.webinar_title}>
                    {getOrdinal(data.DETAIL.EPISODE)} JAM Webinar
                  </div>
                  <div className={style.webinar_list}>
                    {data.WEBINAR.map((webinar, idx) => (
                      <WebinarCard key={idx} data={webinar} />
                    ))}
                  </div>
                </div>
              )}

            {/* Speakers */}
            {data.SPEAKER !== null && data.SPEAKER.length !== 0 && (
              <div className={style.speakers_wrapper}>
                <div className={style.speakers_title}>Speakers</div>
                <div className={style.speakers_list}>
                  {data.SPEAKER.map((speaker, idx) => (
                    <SpeakerCard key={idx} data={speaker} />
                  ))}
                </div>
              </div>
            )}

            {/* Agenda */}
            {data.AGENDA !== null && data.AGENDA.length !== 0 && (
              <div className={style.agenda_wrapper}>
                <div className={style.agenda_title}>Agenda</div>
                <div className={style.agenda_table}>
                  <AgendaTable data={data.AGENDA} />
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
}

export default LibraryDetail;
