import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import style from "css/mypage.module.css";
import { Image, Skeleton } from "antd";
import { ReactComponent as ClockIcon } from "asset/myPage/clock.svg";
import { ReactComponent as FavortiteIcon } from "asset/common/favorite.svg";
import { closeAlert, openConfirm } from "redux/slices/alertSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

function Register() {
  const [init, setInit] = useState(true);
  const [data, setData] = useState({});

  const getData = useCallback(() => {
    const url = "/mypage/register";
    axios.get(url).then((res) => {
      if (res.status >= 200 && res.status < 300) {
        setData(res.data);
      }
    });
  }, []);

  useEffect(() => {
    if (init) {
      getData();
      setInit(false);
    }
  }, [getData, init]);

  const refreshEvent = () => {
    setInit(true);
  };

  return (
    <section className={style.register_container}>
      {data.id !== undefined && data.id !== "" && (
        <Card
          id={data.id}
          title={data.title}
          episode={data.episode}
          description={data.description}
          img={data.img}
          startDate={data.startDate}
          startTime={data.startTime}
          endTime={data.endTime}
          refreshEvent={refreshEvent}
        />
      )}
    </section>
  );
}

function Card(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const deleteEvent = (e) => {
    e.stopPropagation();
    const obj = {
      title: "Notice",
      content: "Would you like to remove the bookmark.",
      submitEventHandler: () => {
        const config = {
          params: {
            ID: props.id,
          },
        };
        axios
          .delete("/mypage/library", config)
          .then((res) => {
            if (res.status >= 200 && res.status < 300) {
              props.refreshEvent();
            }
          })
          .catch((err) => {
            console.log(err);
          });
        dispatch(closeAlert());
      },
    };
    dispatch(openConfirm(obj));
  };
  const getDetailEvent = () => {
    navigate(process.env.REACT_APP_CLIENT_URL + "register?id=" + props.id);
  };
  return (
    <section className={style.register_item} onClick={getDetailEvent}>
      <div
        className={`${style.like_btn} ${style.bottom}`}
        onClick={(e) => deleteEvent(e)}
      >
        <FavortiteIcon />
      </div>
      <div>
        {props.img === "" ? (
          <Skeleton.Image className={style.register_item_img} active />
        ) : (
          <Image
            className={style.register_item_img}
            preview={false}
            width={"auto"}
            height={"auto"}
            src={props.img}
            // src={"/img/library/webinar/1_1.png"}
          />
        )}
      </div>
      <div className={style.register_item_info}>
        <article>
          <p className={style.register_item_title}>{props.title}</p>
          <p className={style.register_item_description}>{props.description}</p>
        </article>

        <article className={style.register_item_time}>
          <ClockIcon />
          <p>{props.startTime}</p>
          <p>~</p>
          <p>{props.endTime}</p>
          <p>(GMT)</p>
        </article>
      </div>
    </section>
  );
}

Card.propTypes = {
  id: PropTypes.string,
  episode: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  img: PropTypes.string,
  startDate: PropTypes.string,
  startTime: PropTypes.string,
  endTime: PropTypes.string,
  refreshEvent: PropTypes.func,
};

export default Register;
