import React, { useState } from "react";
import style from "css/account.module.css";
import Item from "components/account/item";
import Input from "components/account/input";
import Button from "components/account/button";
import { closeAlert, openAlert } from "redux/slices/alertSlice";
import axios from "axios";
import { useDispatch } from "react-redux";

function Email({ setType, setEmail }) {
  return (
    <section className={style.wrapper}>
      <div className={style.container}>
        <Form setType={setType} setResult={setEmail} />
      </div>
    </section>
  );
}

export default Email;

function Form({ setType, setResult }) {
  //dispatch
  const dispatch = useDispatch();
  //state
  const [email, setEmail] = useState({
    title: "Email",
    value: "",
    require: true,
    error: false,
    msg: "",
  });
  const [sendBtn, setSendBtn] = useState({
    content: "Continue",
    valid: false,
    loading: false,
    disabled: false,
  });

  const confirmEmail = () => {
    if (email.value.length <= 0) {
      setEmail({
        ...email,
        error: true,
        msg: "Please Insert Email.",
      });
      setSendBtn({
        ...sendBtn,
        loading: false,
      });
      return false;
    }
    return true;
  };

  const sendEvent = () => {
    setSendBtn({
      ...sendBtn,
      loading: true,
    });

    if (!confirmEmail()) {
      setSendBtn({
        ...sendBtn,
        loading: false,
      });
      return;
    }

    const data = {
      email: email.value,
    };

    axios
      .post("/user/email/valid", data)
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          const obj = {
            title: "Notice",
            content: "Verification code sent to your email.",
            submitEventHandler: () => {
              setResult(email.value);
              setType("auth");
              dispatch(closeAlert());
            },
          };
          dispatch(openAlert(obj));
        } else {
          setSendBtn({
            ...sendBtn,
            loading: false,
          });
        }
      })
      .catch((err) => {
        setEmail({
          ...email,
          error: true,
          msg: err.response.data,
        });
        setSendBtn({
          ...sendBtn,
          loading: false,
        });
      });
  };

  return (
    <section className={style.form}>
      <Item
        require={email.require}
        error={email.error}
        msg={email.msg}
        title={email.title}
        component={
          <Input
            type="text"
            placeholder="Email"
            error={email.error}
            value={email.value}
            onChangeEvent={(res) => {
              if (email.error && res !== email.value) {
                setEmail({
                  ...email,
                  value: res,
                  error: false,
                  msg: "",
                });
              } else {
                setEmail({
                  ...email,
                  value: res,
                });
              }
            }}
            disabled={sendBtn.loading}
          />
        }
      />
      <section className={style.button_area}>
        <Button
          content={sendBtn.content}
          disabled={sendBtn.disabled}
          loading={sendBtn.loading}
          valid={sendBtn.valid}
          clickEvent={sendEvent}
        />
      </section>
    </section>
  );
}
