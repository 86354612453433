import React from "react";
import style from "css/library/agendaTable.module.css";

function AgendaTable({ data }) {
  const firstData = data[0];
  const midDataList = () => {
    let result = [];

    if (data.length > 2) {
      for (let i = 1; i < data.length - 1; i++) {
        result.push(data[i]);
      }
    }

    return result;
  };
  const lastData = data[data.length - 1];

  return (
    <div className={style.table_wrapper}>
      <div className={style.col_index}>
        <div className={style.row_topic}>
          <div>Topic</div>
        </div>
        <div className={style.row_speaker}>
          <span>Speaker</span>
        </div>
        <div className={style.row_time}>
          <span>Time</span>
        </div>
      </div>
      <div className={style.col_white}>
        <div className={style.row_topic}>
          <div>{firstData.TOPIC}</div>
        </div>
        <div className={style.row_speaker}>
          <div className={style.speaker_info}>
            {firstData.SPEAKER_NM !== null && firstData.SPEAKER_NM !== "" ? (
              <span className={style.name}>{firstData.SPEAKER_NM}</span>
            ) : (
              <></>
            )}
            {firstData.SPEAKER_TITLE !== null &&
              firstData.SPEAKER_TITLE !== "" &&
              firstData.SPEAKER_TITLE !== undefined && (
                <span className={style.position}>
                  {firstData.SPEAKER_TITLE}
                </span>
              )}
          </div>
        </div>
        <div className={style.row_time}>
          {firstData.DURATION !== null &&
            firstData.DURATION !== "" &&
            firstData.DURATION !== undefined && (
              <span>{firstData.DURATION} min</span>
            )}
        </div>
      </div>
      {midDataList().length > 0 ? (
        midDataList().map((el, index) => (
          <div key={index} className={style.col_blue}>
            <div className={style.row_topic}>
              <div>{el.TOPIC}</div>
            </div>
            <div className={style.row_speaker}>
              <div className={style.speaker_info}>
                {el.SPEAKER_NM !== null && el.SPEAKER_NM !== "" ? (
                  <span className={style.name}>{el.SPEAKER_NM}</span>
                ) : (
                  <></>
                )}
                {el.SPEAKER_TITLE !== null &&
                  el.SPEAKER_TITLE !== "" &&
                  el.SPEAKER_TITLE !== undefined && (
                    <span className={style.position}>{el.SPEAKER_TITLE}</span>
                  )}
              </div>
            </div>
            <div className={style.row_time}>
              {el.DURATION !== null &&
                el.DURATION !== "" &&
                el.DURATION !== undefined && <span>{el.DURATION} min</span>}
            </div>
          </div>
        ))
      ) : (
        <></>
      )}
      {data.length > 1 ? (
        <div className={style.col_white}>
          <div className={style.row_topic}>
            <div>{lastData.TOPIC}</div>
          </div>
          <div className={style.row_speaker}>
            <div className={style.speaker_info}>
              {lastData.SPEAKER_NM !== null && lastData.SPEAKER_NM !== "" ? (
                <span className={style.name}>{lastData.SPEAKER_NM}</span>
              ) : (
                <></>
              )}
              {lastData.SPEAKER_TITLE !== null &&
                lastData.SPEAKER_TITLE !== "" &&
                lastData.SPEAKER_TITLE !== undefined && (
                  <span className={style.position}>
                    {lastData.SPEAKER_TITLE}
                  </span>
                )}
            </div>
          </div>
          <div className={style.row_time}>
            {lastData.DURATION !== null &&
              lastData.DURATION !== "" &&
              lastData.DURATION !== undefined && (
                <span> {lastData.DURATION} min</span>
              )}
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default AgendaTable;
