import React, { useCallback, useEffect, useState } from "react";
import style from "css/account.module.css";
import Item from "components/account/item";
import Input from "components/account/input";
import Button from "components/account/button";
import axios from "axios";
import { useDispatch } from "react-redux";
import { closeAlert, openAlert } from "redux/slices/alertSlice";

function Auth({ setType, email }) {
  return (
    <section className={style.wrapper}>
      <div className={style.container}>
        <Form setType={setType} email={email} />
      </div>
    </section>
  );
}

export default Auth;

function Form({ setType, email }) {
  const maxTime = 300;
  //dispatch
  const dispatch = useDispatch();
  //state
  const [time, setTime] = useState(maxTime);
  const [code, setCode] = useState({
    title: "Code",
    value: "",
    require: true,
    error: false,
    msg: "",
  });
  const [sendBtn, setSendBtn] = useState({
    content: "Continue",
    valid: false,
    loading: false,
    disabled: false,
  });
  const [reSendBtn, setReSendBtn] = useState({
    content: "Continue",
    valid: false,
    loading: false,
    disabled: false,
  });

  const reSendEvent = useCallback(() => {
    if (reSendBtn.loading) {
      return;
    }

    setReSendBtn({
      ...reSendBtn,
      loading: true,
    });

    const data = {
      email: email,
    };

    axios
      .post("/user/email/valid", data)
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          setTime(maxTime);
          const obj = {
            title: "Notice",
            content: "Verification code sent to your email.",
            submitEventHandler: () => {
              setReSendBtn({
                ...reSendBtn,
                loading: false,
              });
              dispatch(closeAlert());
            },
          };
          dispatch(openAlert(obj));
        } else {
          setReSendBtn({
            ...reSendBtn,
            loading: false,
          });
        }
      })
      .catch(() => {
        setReSendBtn({
          ...reSendBtn,
          loading: false,
        });
      });
  }, [dispatch, email, reSendBtn]);

  useEffect(() => {
    const timerId = setInterval(() => {
      if (time > 1) {
        setTime((prevCount) => prevCount - 1);
      } else {
        reSendEvent();
        setTime(maxTime);
      }
    }, 1000);

    return () => {
      clearInterval(timerId);
    };
  }, [reSendEvent, time]);

  const confirmCode = () => {
    if (code.value.length <= 0) {
      setCode({
        ...code,
        error: true,
        msg: "Please Insert Code.",
      });
      setSendBtn({
        ...sendBtn,
        loading: false,
      });
      return false;
    }
    return true;
  };

  const checkAuthcode = () => {
    setSendBtn({
      ...sendBtn,
      loading: true,
    });

    if (!confirmCode()) {
      setSendBtn({
        ...sendBtn,
        loading: false,
      });
      return;
    }

    const data = {
      email: email,
      code: code.value,
    };

    axios
      .put("/user/signup/authcode", data)
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          if (res.data === true) {
            setType("info");
          } else {
            setCode({
              ...code,
              value: "",
              error: true,
              msg: "The authentication code is not valid.",
            });
            setSendBtn({
              ...sendBtn,
              loading: false,
            });
          }
        } else {
          setCode({
            ...code,
            value: "",
            error: true,
            msg: "The authentication code is not valid.",
          });
          setSendBtn({
            ...sendBtn,
            loading: false,
          });
        }
      })
      .catch(() => {
        setCode({
          ...code,
          value: "",
          error: true,
          msg: "The authentication code is not valid.",
        });
        setSendBtn({
          ...sendBtn,
          loading: false,
        });
      });
  };

  const timeFormat = () => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    let result = "0" + minutes + ":";
    if (seconds > 9) {
      result = result + seconds;
    } else {
      result = result + "0" + seconds;
    }
    return result;
  };

  return (
    <section className={style.form}>
      <Item
        require={code.require}
        error={code.error}
        msg={code.msg}
        title={code.title}
        component={
          <Input
            type="text"
            placeholder="Code"
            error={code.error}
            value={code.value}
            onChangeEvent={(res) => {
              if (code.error && res !== code.value) {
                setCode({
                  ...code,
                  value: res,
                  error: false,
                  msg: "",
                });
              } else {
                setCode({
                  ...code,
                  value: res,
                });
              }
            }}
            disabled={sendBtn.loading}
            maxLength={6}
          />
        }
      />

      <section className={style.authcode_article}>
        <article>
          <p>The remaining time available</p>
          <p className={style.authcode_time}>{timeFormat()}</p>
        </article>
        <article>
          <p>Did you not receive the email?</p>
          <div
            className={style.authcode_resend_btn}
            onClick={() => reSendEvent()}
          >
            Resend Authcode
          </div>
        </article>
      </section>

      <section className={style.button_area}>
        <Button
          content={sendBtn.content}
          disabled={sendBtn.disabled}
          loading={sendBtn.loading}
          valid={sendBtn.valid}
          clickEvent={checkAuthcode}
        />
      </section>
    </section>
  );
}
