import { createSlice } from "@reduxjs/toolkit";

/**
 * 파일명: meetingTimeSlice.js
 * 작성자: 강연승
 * 작성일자: 23.04.12
 * 설명: 캘린더 선택한 시간 정보
 */

const meetingTimeSlice = createSlice({
  name: "meetingTimeSlice",
  initialState: {
    TYPE: "",
    VIEW: false,
    START_DATE: "",
    END_DATE: "",
    START_TIME: "",
    END_TIME: "",
  },
  reducers: {
    updateMeetingTime: (state, action) => {
      state.TYPE = action.payload.TYPE;
      state.VIEW = action.payload.VIEW;
      state.START_DATE = action.payload.START_DATE;
      state.END_DATE = action.payload.END_DATE;
      state.START_TIME = action.payload.START_TIME;
      state.END_TIME = action.payload.END_TIME;
    },
  },
});

export default meetingTimeSlice;

export const { updateMeetingTime } = meetingTimeSlice.actions;
