import React, { useState } from "react";
import style from "css/template.module.css";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { MenuOutlined, UserOutlined, CloseOutlined } from "@ant-design/icons";
import { ReactComponent as JamWhite } from "asset/common/jam_logo_white.svg";
import Menu from "./menu";
import { Cookies } from "react-cookie";
import { useSelector } from "react-redux";

/**
 * Version : 1.0
 * 작성일자 : 2023-12-11
 * 작성자 : 권도훈
 * 설명 : 공통 템플릿 영역
 */
function Header() {
  //cookie
  const cookies = new Cookies();
  //selector
  const userPayload = useSelector((state) => state.user);
  //navigate
  const navigate = useNavigate();

  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const isTablet = useMediaQuery({
    query: "(min-width: 768px) and (max-width: 1023px)",
  });
  const menuList = [
    {
      label: "Register",
      key: "register",
    },
    {
      label: "Library",
      key: "library",
    },
    {
      label: "Product",
      key: "product",
    },
    {
      label: "Academy",
      key: "academy",
    },
    {
      label: "Newsletter",
      key: "newsletter",
    },
    {
      label: "Contact us",
      key: "contactUs",
    },
  ];
  const signList = [
    {
      label: "Login",
      key: "signin",
    },
    {
      label: "Join",
      key: "signup",
    },
  ];
  const menuHandler = (e) => {
    navigate(process.env.REACT_APP_CLIENT_URL + e.key);
  };

  const [isOpenMenu, setIsOpenMenu] = useState(false);

  const handleMenu = () => {
    setIsOpenMenu(!isOpenMenu);
    if (!isOpenMenu) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.removeProperty("overflow");
    }
  };

  const goLogout = () => {
    const authNm = process.env.REACT_APP_AUTH_TOKEN;
    const refreshNm = process.env.REACT_APP_REFRESH_TOKEN;
    const domain = process.env.REACT_APP_DOMAIN;
    cookies.remove(authNm, { path: "/", domain: domain });
    cookies.remove(refreshNm, { path: "/", domain: domain });
    window.location.href = process.env.REACT_APP_CLIENT_URL;
  };

  return (
    <>
      {isOpenMenu ? <Menu close={() => handleMenu()} /> : <></>}
      <div className={style.header}>
        <div className={style.header_left_area}>
          {isMobile ? (
            isOpenMenu ? (
              <CloseOutlined
                className={style.menu_icon}
                color="#1f299c"
                onClick={() => handleMenu()}
              />
            ) : (
              <MenuOutlined
                className={style.menu_icon}
                color="#1f299c"
                onClick={() => handleMenu()}
              />
            )
          ) : (
            <JamWhite
              className="cursor"
              width={isTablet ? "100" : "128"}
              fill="#1f299c"
              onClick={() => menuHandler({ key: "" })}
            />
          )}
        </div>

        <div className={style.header_center_area}>
          {isMobile ? (
            <JamWhite
              className="cursor"
              width="84"
              fill="#1f299c"
              onClick={() => {
                if (isOpenMenu) {
                  setIsOpenMenu(false);
                  document.body.style.removeProperty("overflow");
                }
                menuHandler({ key: "" });
              }}
            />
          ) : (
            menuList.map((menu) => (
              <div
                key={menu.key}
                className={style.menu}
                onClick={() => menuHandler(menu)}
              >
                {menu.label}
              </div>
            ))
          )}
        </div>

        <div className={style.header_right_area}>
          {/* <Menu
            onClick={menuHandler}
            mode="horizontal inline"
            items={signList}
            inlineIndent={100}
          /> */}
          {isMobile ? (
            <div
              className={style.user_menu}
              onClick={() => {
                if (isOpenMenu) {
                  setIsOpenMenu(false);
                  document.body.style.removeProperty("overflow");
                }
                if (userPayload.id === "") {
                  menuHandler({ key: "signin" });
                } else {
                  menuHandler({ key: "mypage" });
                }
              }}
            >
              <UserOutlined className={style.user_icon} />
            </div>
          ) : userPayload.id === "" ? (
            signList.map((menu) => (
              <div
                key={menu.key}
                className={style.menu}
                onClick={() => menuHandler(menu)}
              >
                {menu.label}
              </div>
            ))
          ) : (
            <>
              <div
                className={style.menu}
                onClick={() => menuHandler({ key: "mypage" })}
              >
                <div className={style.user_menu}>
                  <UserOutlined className={style.user_icon} />
                </div>
                My Page
              </div>
              <div className={style.menu} onClick={() => goLogout()}>
                Logout
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default Header;
