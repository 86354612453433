import React from "react";
import style from "css/myInfo.module.css";
import { Select } from "antd";

function SelectInput({ title, list, value, setValue }) {
  return (
    <div className={style.myInfo_input_box}>
      <div className={style.myInfo_input_title}>
        <div className={style.orange_dot}></div>
        <div className={style.input_title}>{title}</div>
      </div>
      <Select
        className={style.select}
        showSearch
        value={value}
        style={{ height: 50 }}
        filterOption={(input, option) =>
          (option?.label.toLowerCase() ?? "").includes(input.toLowerCase())
        }
        filterSort={(optionA, optionB) =>
          (optionA?.label ?? "")
            .toLowerCase()
            .localeCompare((optionB?.label ?? "").toLowerCase())
        }
        onChange={(res) => setValue(res)}
        options={list}
      />
    </div>
  );
}

export default SelectInput;
