import { createSlice } from "@reduxjs/toolkit";

/**
 * 파일명: userSlice.js
 * 작성자: 권도훈
 * 작성일자: 23.04.06
 * 설명: 사용자 정보
 */
const userSlice = createSlice({
    name: "userSlice",
    initialState: {
        name: "",
        id: ""
    },
    reducers: {
        insertUserInfo: (state, action) => {
            state.name = action.payload.name;
            state.id = action.payload.id;
        },
        deleteUserInfo: (state) => {
            state.name = "";
            state.id = "";
        }
    }
})

export default userSlice;

export const { insertUserInfo, deleteUserInfo } = userSlice.actions;