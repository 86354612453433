import React from "react";
import PropTypes from "prop-types";
import style from "css/mypage.module.css";

function Content(props) {
  return (
    <section className={style.content_item}>
      <div className={style.content_item_container}>
        <article className={style.cotent_title}>
          <p>{props.title}</p>
        </article>
        {props.component}
      </div>
    </section>
  );
}

Content.propTypes = {
  title: PropTypes.string,
  component: PropTypes.element,
};

export default Content;
