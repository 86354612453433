import React, { useState, useEffect, useCallback } from "react";
import style from "css/landing.module.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import { useMediaQuery } from "react-responsive";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Cookies } from "react-cookie";

function SlideTextArea() {
  //cookie
  const cookies = new Cookies();
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const isTablet = useMediaQuery({
    query: "(min-width: 768px) and (max-width: 1023px)",
  });

  const [registerYn, setRegisterYn] = useState("N");
  const [registerEvent, setRegisterEvent] = useState({});

  const getRegisterEvent = useCallback(() => {
    const data = {
      params: {},
    };

    axios.get("/event/landing/registerCheck", data).then((res) => {
      if (res.status === 200) {
        setRegisterYn(res.data.REGISTER_YN);
        setRegisterEvent(res.data.EVENT);
      }
    });
  }, []);

  useEffect(() => {
    getRegisterEvent();
  }, [getRegisterEvent]);

  //11회차 임시주석
  // registerYn === "N"
  //11회차 임시주석
  //return cookies.get("AuthToken") !== undefined ? (
  return registerYn === "N" !== undefined ? (
    <></>
  ) : (
    <div
      className={`${style.slideText_area} cursor`}
      //11회차 임시 주석
      onClick={() => navigate(process.env.REACT_APP_CLIENT_URL + "register")}
      // onClick={() => navigate(process.env.REACT_APP_CLIENT_URL + "signup")}
    >
      <Swiper
        className={style.slideText_swiper}
        modules={[Autoplay]}
        loop={true}
        loopAdditionalSlides={1}
        // spaceBetween={isMobile ? 2.5 : isTablet ? 6 : 81}
        slidesPerView={isMobile ? 3 : isTablet ? 7 : 10}
        draggable={false}
        resistance={true}
        autoplay={{
          delay: 0,
          disableOnInteraction: false,
        }}
        speed={7000}
      >
        <SwiperSlide className={style.slide_list_content}>
          <div className={style.slide_text}>
            {/* {registerEvent.EVENT_NM === undefined
              ? "Join us JAM"
              : "Join us " + registerEvent.EVENT_NM.split(" ")[0] + " JAM"} */}
            Become a member
          </div>
        </SwiperSlide>
        <SwiperSlide className={style.slide_list_content}>
          <div className={style.slide_middot} />
        </SwiperSlide>
        <SwiperSlide className={style.slide_list_content}>
          <div className={style.slide_text}>
            {/* {registerEvent.EVENT_NM === undefined
              ? "Join us JAM"
              : "Join us " + registerEvent.EVENT_NM.split(" ")[0] + " JAM"} */}
            Become a member
          </div>
        </SwiperSlide>
        <SwiperSlide className={style.slide_list_content}>
          <div className={style.slide_middot} />
        </SwiperSlide>
        <SwiperSlide className={style.slide_list_content}>
          <div className={style.slide_text}>
            {/* {registerEvent.EVENT_NM === undefined
              ? "Join us JAM"
              : "Join us " + registerEvent.EVENT_NM.split(" ")[0] + " JAM"} */}
            Become a member
          </div>
        </SwiperSlide>
        <SwiperSlide className={style.slide_list_content}>
          <div className={style.slide_middot} />
        </SwiperSlide>
        <SwiperSlide className={style.slide_list_content}>
          <div className={style.slide_text}>
            {/* {registerEvent.EVENT_NM === undefined
              ? "Join us JAM"
              : "Join us " + registerEvent.EVENT_NM.split(" ")[0] + " JAM"} */}
            Become a member
          </div>
        </SwiperSlide>
        <SwiperSlide className={style.slide_list_content}>
          <div className={style.slide_middot} />
        </SwiperSlide>
        <SwiperSlide className={style.slide_list_content}>
          <div className={style.slide_text}>
            {/* {registerEvent.EVENT_NM === undefined
              ? "Join us JAM"
              : "Join us " + registerEvent.EVENT_NM.split(" ")[0] + " JAM"} */}
            Become a member
          </div>
        </SwiperSlide>
        <SwiperSlide className={style.slide_list_content}>
          <div className={style.slide_middot} />
        </SwiperSlide>
        <SwiperSlide className={style.slide_list_content}>
          <div className={style.slide_text}>
            {/* {registerEvent.EVENT_NM === undefined
              ? "Join us JAM"
              : "Join us " + registerEvent.EVENT_NM.split(" ")[0] + " JAM"} */}
            Become a member
          </div>
        </SwiperSlide>
        <SwiperSlide className={style.slide_list_content}>
          <div className={style.slide_middot} />
        </SwiperSlide>
        <SwiperSlide className={style.slide_list_content}>
          <div className={style.slide_text}>
            {/* {registerEvent.EVENT_NM === undefined
              ? "Join us JAM"
              : "Join us " + registerEvent.EVENT_NM.split(" ")[0] + " JAM"} */}
            Become a member
          </div>
        </SwiperSlide>
        <SwiperSlide className={style.slide_list_content}>
          <div className={style.slide_middot} />
        </SwiperSlide>
        <SwiperSlide className={style.slide_list_content}>
          <div className={style.slide_text}>
            {/* {registerEvent.EVENT_NM === undefined
              ? "Join us JAM"
              : "Join us " + registerEvent.EVENT_NM.split(" ")[0] + " JAM"} */}
            Become a member
          </div>
        </SwiperSlide>
        <SwiperSlide className={style.slide_list_content}>
          <div className={style.slide_middot} />
        </SwiperSlide>
        <SwiperSlide className={style.slide_list_content}>
          <div className={style.slide_text}>
            {/* {registerEvent.EVENT_NM === undefined
              ? "Join us JAM"
              : "Join us " + registerEvent.EVENT_NM.split(" ")[0] + " JAM"} */}
            Become a member
          </div>
        </SwiperSlide>
        <SwiperSlide className={style.slide_list_content}>
          <div className={style.slide_middot} />
        </SwiperSlide>
        <SwiperSlide className={style.slide_list_content}>
          <div className={style.slide_text}>
            {/* {registerEvent.EVENT_NM === undefined
              ? "Join us JAM"
              : "Join us " + registerEvent.EVENT_NM.split(" ")[0] + " JAM"} */}
            Become a member
          </div>
        </SwiperSlide>
        <SwiperSlide className={style.slide_list_content}>
          <div className={style.slide_middot} />
        </SwiperSlide>
      </Swiper>
    </div>
  );
}

export default SlideTextArea;
