import React from "react";
import style from "css/product/productCard.module.css";
import { useNavigate } from "react-router-dom";
// import axios from "axios";
// import { useDispatch, useSelector } from "react-redux";
// import { motion } from "framer-motion";
// import Cookies from "universal-cookie";
// import { openAlert } from "redux/slices/alertSlice";

function Productcard(props) {
  const navigate = useNavigate();
  const viewBtnHandler = (data) => {
    navigate(process.env.REACT_APP_CLIENT_URL + "product/" + data);
  };
  // const cookies = new Cookies();
  // const dispatch = useDispatch();
  // const userPayload = useSelector((state) => state.user);
  // const favorite = () => {
  //   const data = {
  //     PRODUCT_ID: props.product_num,
  //     TYPE: "",
  //     MEMBER_ID: userPayload.id,
  //   };
  //   if (props.favorite === "N") {
  //     data.TYPE = "I";
  //   } else {
  //     data.TYPE = "D";
  //   }

  //   if (cookies.get("AuthToken") === undefined) {
  //     // alert("This service requires login. Please login first.");
  //     dispatch(
  //       openAlert({
  //         title: "Notice",
  //         content: "This service requires login. Please login first.",
  //         submitEventHandler: () =>
  //           (window.location.href =
  //             process.env.REACT_APP_CLIENT_URL + "signin"),
  //       })
  //     );
  //     return;
  //   } else {
  //     axios.post("/product/favorite", data).then((res) => {
  //       if (res.status === 200) {
  //         if (res.data === 1) {
  //           props.refresh();
  //         }
  //       }
  //     });
  //   }
  // };

  return (
    <div
      className={style.card_body}
      onClick={() => viewBtnHandler(props.product_num)}
    >
      <div className={style.card_box}>
        <img
          className={style.card_img}
          alt=""
          src={process.env.REACT_APP_CLIENT_URL + props.IMG_URL}
        />
      </div>
      <div className={style.card_title}>{props.title}</div>
    </div>
  );
}

export default Productcard;
