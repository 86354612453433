import React, { useState, useEffect, useCallback } from "react";
import style from "css/landing.module.css";
import { Image, Skeleton } from "antd";
import LibraryItem from "components/landing/libraryItem";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import axios from "axios";

/**
 * Version : 1.0
 * 작성일자 : 2023-12-11
 * 작성자 : 강연승
 * 설명 : 랜딩페이지 - 이벤트
 */
function WebinarArea() {
  //navigate
  const navigate = useNavigate();
  const clickHandler = (e) => {
    navigate(process.env.REACT_APP_CLIENT_URL + e);
  };
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const isTablet = useMediaQuery({
    query: "(min-width: 768px) and (max-width: 1023px)",
  });
  const [registerEvent, setRegisterEvent] = useState({});
  const [libraryList, setLibraryList] = useState([]);

  const getRegisterEvent = useCallback(() => {
    const data = {
      params: {},
    };

    axios.get("/event/landing/register", data).then((res) => {
      if (res.status === 200) {
        setRegisterEvent(res.data.data);
      }
    });
  }, []);
  const getLibraryList = useCallback(() => {
    const data = {
      params: {},
    };

    axios.get("/event/landing/library", data).then((res) => {
      if (res.status === 200) {
        setLibraryList(res.data.list);
      }
    });
  }, []);

  useEffect(() => {
    getRegisterEvent();
    getLibraryList();
  }, [getRegisterEvent, getLibraryList]);
  return (
    <div className={style.webinar_area}>
      <div className={style.webinar_title}>Join JAM</div>
      <div className={style.webinar_content}>
        <motion.div
          className={style.webinar_register_wrap}
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0 }}
          variants={{
            offscreen: {
              opacity: 0,
              scale: 0.5,
            },
            onscreen: {
              opacity: 1,
              scale: 1,
              transition: {
                duration: 0.8,
                delay: 0.5,
                ease: [0, 0.71, 0.2, 1.01],
              },
            },
          }}
        >
          <div className={style.webinar_register_content}>
            <div className={style.webinar_register_item}>
              <div className={style.webinar_register_label}>
                {registerEvent.STATUS_CD === "CD1402" ? "Register" : "Library"}
              </div>
              <div className={style.webinar_register_title}>
                {registerEvent.STATUS_CD === "CD1402" ? "Join us " : ""}
                <span>
                  {registerEvent.EVENT_NM === "" ||
                  registerEvent.EVENT_NM === undefined
                    ? "JAM"
                    : registerEvent.STATUS_CD === "CD1402"
                    ? registerEvent.EVENT_NM.split(" ")[0] + " JAM"
                    : registerEvent.EVENT_NM.split(" ")[0]}
                </span>
              </div>
              <div className={style.webinar_register_text}>
                {registerEvent.STATUS_CD === "CD1402"
                  ? registerEvent.DESCRIPTION === ""
                    ? "The Latest Discussion about Minimally Invasive Aesthetic Procedures"
                    : registerEvent.DESCRIPTION
                  : "Jetema Academic Meeting"}
              </div>

              {(registerEvent.STATUS_CD === "CD1402" &&
                registerEvent.LANDING_REGISTER_IMG === "") ||
              (registerEvent.STATUS_CD !== "CD1402" &&
                registerEvent.LANDING_IMG === "") ? (
                <Skeleton.Image
                  active
                  style={
                    isMobile
                      ? { width: "100%", height: "188px" }
                      : isTablet
                      ? { width: "100%", height: "236px" }
                      : { width: "100%", height: "478px" }
                  }
                />
              ) : (
                <img
                  className={`${style.webinar_register_img} cursor`}
                  onClick={() =>
                    registerEvent.STATUS_CD === "CD1402"
                      ? clickHandler("register")
                      : clickHandler("library/" + registerEvent.EVENT_ID)
                  }
                  // preview={false}
                  // width={"100%"}
                  // height={isMobile ? 188 : isTablet ? 236 : 478}
                  alt=""
                  src={
                    registerEvent.STATUS_CD === "CD1402"
                      ? registerEvent.LANDING_REGISTER_IMG
                      : registerEvent.LANDING_IMG
                  }
                />
              )}
            </div>
            <div
              className={style.webinar_view_more_btn}
              onClick={() =>
                registerEvent.STATUS_CD === "CD1402"
                  ? clickHandler("register")
                  : clickHandler("library/" + registerEvent.EVENT_ID)
              }
            >
              View more
            </div>
          </div>
        </motion.div>

        <div className={style.webinar_library_wrap}>
          <div className={style.webinar_register_content}>
            <div className={style.webinar_register_item}>
              <motion.div
                className={style.webinar_library_label}
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0 }}
                variants={{
                  offscreen: {
                    opacity: 0,
                    scale: 0.5,
                  },
                  onscreen: {
                    opacity: 1,
                    scale: 1,
                    transition: {
                      duration: 0.8,
                      delay: 0.7,
                      ease: [0, 0.71, 0.2, 1.01],
                    },
                  },
                }}
              >
                Library
              </motion.div>
              {/* <div className={style.webinar_library_label}>Library</div> */}
              <div className={style.webinar_library_list}>
                {libraryList.map((library, index) => (
                  <LibraryItem key={index} data={library} />
                ))}
              </div>
            </div>
            <motion.div
              className={style.webinar_view_more_btn}
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true, amount: 0 }}
              variants={{
                offscreen: {
                  opacity: 0,
                  scale: 0.5,
                },
                onscreen: {
                  opacity: 1,
                  scale: 1,
                  transition: {
                    duration: 0.8,
                    delay: 0.7,
                    ease: [0, 0.71, 0.2, 1.01],
                  },
                },
              }}
              onClick={() => clickHandler("library")}
            >
              View more
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WebinarArea;
