import React, { useCallback, useEffect, useState } from "react";
import style from "css/product/product.module.css";
import Productcard from "components/product/productcard";
import axios from "axios";
import { useSelector } from "react-redux";

function Product() {
  const [productlist, setproductlist] = useState([]);
  const [refresh, setrefresh] = useState(true);

  const userPayload = useSelector((state) => state.user);

  const getproductlist = useCallback(() => {
    const data = {
      params: {
        MEMBER_ID: userPayload.id,
      },
    };

    axios.get("/product/productlist", data).then((res) => {
      if (res.status === 200) {
        setproductlist(res.data);
        setrefresh(false);
      }
    });
  }, [userPayload]);

  const refreschfunction = () => {
    setrefresh(true);
  };
  useEffect(() => {
    if (refresh) {
      getproductlist();
    }
  }, [getproductlist, refresh, userPayload.id]);

  useEffect(() => {
    if (userPayload.id !== "") {
      setrefresh(true);
    }
  }, [userPayload.id]);

  return (
    <div className={style.main_wrapper}>
      <div className={style.title}>
        Product<span>Learn more about our products.</span>
      </div>
      <div className={style.content}>
        {productlist.map((product, idx) => (
          <div key={idx}>
            <Productcard
              title={product.TITLE}
              IMG_URL={product.THUMBNAIL_IMG}
              product_num={product.ID}
              //    favorite={product.FAVORITE}
              refresh={() => refreschfunction()}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default Product;
