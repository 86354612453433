import React, { useState, useEffect, useCallback } from "react";
import style from "css/newsletter.module.css";
import { useMediaQuery } from "react-responsive";
import { useDispatch } from "react-redux";
import { closeAlert, openAlert } from "redux/slices/alertSlice";
import { Image, Skeleton } from "antd";
import NewsletterNavi from "./newsletterNavi";
import axios from "axios";
import Loading from "components/common/loading";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";

function NewsletterDetail(props) {
  const cookies = new Cookies();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const isTablet = useMediaQuery({
    query: "(min-width: 768px) and (max-width: 1023px)",
  });

  const [init, setInit] = useState(true);
  const [loading, setLoading] = useState(true);
  const [newsletterDetail, setNewsletterDetail] = useState({});

  const getNewsletterDetail = useCallback((newsletterId) => {
    setLoading(true);
    const url = "/newsletter/detail";
    const param = {
      params: {
        NEWSLETTER_ID: newsletterId,
      },
    };

    axios
      .get(url, param)
      .then((res) => {
        if (res.status === 200) {
          setNewsletterDetail(res.data.detail);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const [favorite, setFavorite] = useState(
    props.newsletter.FAVORITE === "Y" ? true : false
  );
  const favoriteHandler = () => {
    if (cookies.get("AuthToken") === undefined) {
      // alert("This service requires login. Please login first.");
      dispatch(
        openAlert({
          title: "Notice",
          content: "This service requires login. Please login first.",
          submitEventHandler: () =>
            navigate(process.env.REACT_APP_CLIENT_URL + "signin", {
              state: { returnPage: true },
            }),
        })
      );
      return;
    }

    const param = {
      params: {
        NEWSLETTER_ID: props.newsletterId,
        FAVORITE_YN: favorite ? "N" : "Y",
      },
    };
    axios
      .get("/newsletter/favorite", param)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.result === "success") {
            setFavorite(res.data.detail.FAVORITE === "Y" ? true : false);
          }
        }
      })
      .catch(() => {})
      .finally(() => {});
  };

  const downloadFile = (newsletter) => {
    let params = {
      FILE_NM: newsletter.PDF_FILE,
      ORI_FILE_NM: newsletter.PDF_FILE_NM,
    };
    axios
      .post("/newsletter/downloadFile", params, { responseType: "blob" })
      .then((response) => response.data)
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", newsletter.PDF_FILE_NM);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        dispatch(
          openAlert({
            title: "Notice",
            content: "An error occurred while downloading the file.",
            submitEventHandler: () => dispatch(closeAlert()),
          })
        );
      });
  };

  useEffect(() => {
    if (props.newsletterId !== null || init) {
      setNewsletterDetail({});
      getNewsletterDetail(props.newsletterId);
      setInit(false);
    }
    if (props.newsletter !== null) {
      document.documentElement.style.setProperty(
        "--newsletterColor",
        props.newsletter.COLOR
      );
    }
  }, [getNewsletterDetail, props.newsletterId, props.newsletter, init]);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <NewsletterNavi
            newsletterId={props.newsletterId}
            total={props.newsletterTotal}
            refresh={() => setInit(true)}
          />

          <div className={style.newsletter_detail_wrapper}>
            <div className={style.newsletter_detail_title_wrapper}>
              <img
                alt=""
                src={
                  isMobile
                    ? process.env.REACT_APP_CLIENT_URL +
                      newsletterDetail.TITLE_MOBILE_IMG
                    : process.env.REACT_APP_CLIENT_URL +
                      newsletterDetail.TITLE_IMG
                }
              />
              {isMobile ? (
                <></>
              ) : (
                <div className={style.newsletter_detail_btn_area}>
                  <div
                    className={style.newsletter_detail_like_btn}
                    onClick={(e) => {
                      e.stopPropagation();
                      favoriteHandler();
                    }}
                  >
                    <img
                      alt=""
                      src={
                        favorite
                          ? process.env.REACT_APP_CLIENT_URL +
                            "img/library/filled_heart.png"
                          : process.env.REACT_APP_CLIENT_URL +
                            "img/library/empty_heart.png"
                      }
                    />
                  </div>

                  <div
                    className={style.newsletter_detail_download_btn}
                    // onClick={() => window.open(newsletterDetail.PDF_FILE)}
                    onClick={() => downloadFile(newsletterDetail)}
                  >
                    <img
                      alt=""
                      src={
                        process.env.REACT_APP_CLIENT_URL +
                        "img/common/download.png"
                      }
                    />
                    <span>PDF Download</span>
                  </div>
                </div>
              )}
            </div>

            <div className={style.newsletter_detail_content_wrapper}>
              <div className={style.newsletter_detail_content_list}>
                {Array.isArray(newsletterDetail.CONTENTS) &&
                  newsletterDetail.CONTENTS.map((data, index) => (
                    <div
                      className={style.newsletter_detail_content_item}
                      key={index}
                    >
                      <div
                        className={style.newsletter_detail_content_item_title}
                      >
                        {data.TITLE}
                      </div>
                      <div className={style.newsletter_detail_content_item_img}>
                        {data.CONTENT_IMG === "" ? (
                          <Skeleton.Image
                            active
                            style={
                              isMobile
                                ? { width: "100%", height: "600px" }
                                : isTablet
                                ? { width: "100%", height: "600x" }
                                : { width: "100%", height: "900px" }
                            }
                          />
                        ) : (
                          <Image
                            // className={style.webinar_register_img}
                            preview={false}
                            width={"100%"}
                            // width={isMobile ? "100%" : isTablet ? 294 : 338}
                            // height={isMobile ? "236px" : isTablet ? 206 : 338}
                            src={
                              isMobile
                                ? data.CONTENT_MOBILE_IMG
                                : data.CONTENT_IMG
                            }
                          />
                        )}
                      </div>
                    </div>
                  ))}
              </div>
              <div className={style.newsletter_detail_card_area}>
                <div className={style.newsletter_detail_card_area_title}>
                  JETEMA Activities Worldwide
                </div>

                {props.newsletterId === "1" ? (
                  <div className={style.newsletter_detail_card_list_vol_1}>
                    {Array.isArray(newsletterDetail.CARD) &&
                      newsletterDetail.CARD.map((data, index) => (
                        <div
                          className={style.newsletter_detail_card_item}
                          key={index}
                        >
                          <div
                            className={
                              style.newsletter_detail_card_title_wrapper
                            }
                          >
                            {data.COUNTRY_IMG === "" ? (
                              <></>
                            ) : (
                              <img
                                className={
                                  style.newsletter_detail_card_country_img
                                }
                                alt=""
                                src={
                                  process.env.REACT_APP_CLIENT_URL +
                                  data.COUNTRY_IMG
                                }
                              />
                            )}

                            <div className={style.newsletter_detail_card_title}>
                              {data.TITLE}
                            </div>
                          </div>
                          <div className={style.newsletter_detail_card_text}>
                            {data.CONTENT}
                          </div>

                          <div className={style.newsletter_detail_card_img}>
                            {data.CONTENT_IMG === "" ? (
                              <Skeleton.Image
                                active
                                style={
                                  isMobile
                                    ? { width: "100%" }
                                    : isTablet
                                    ? { width: "100%", height: "164px" }
                                    : { width: "100%", height: "257px" }
                                }
                              />
                            ) : (
                              <Image
                                preview={false}
                                // className={style.webinar_register_img}
                                width={"100%"}
                                // height={
                                //   isMobile ? "auto" : isTablet ? 164 : 257
                                // }
                                src={data.CONTENT_IMG}
                              />
                            )}
                            {/* {data.CONTENT_SUB_IMG === "" ? (
                              <Skeleton.Image
                                active
                                style={
                                  isMobile
                                    ? { width: "100%" }
                                    : isTablet
                                    ? { width: "100%", height: "164px" }
                                    : { width: "100%", height: "257px" }
                                }
                              />
                            ) : (
                              <Image
                                preview={false}
                                // className={style.webinar_register_img}
                                width={isMobile ? "100%" : isTablet ? 290 : 455}
                                height={
                                  isMobile ? "auto" : isTablet ? 164 : 257
                                }
                                src={data.CONTENT_SUB_IMG}
                              />
                            )} */}
                          </div>
                        </div>
                      ))}
                  </div>
                ) : (
                  <div className={style.newsletter_detail_card_list}>
                    {Array.isArray(newsletterDetail.CARD) &&
                      newsletterDetail.CARD.map((data, index) => (
                        <div
                          className={
                            props.newsletterId === "4" &&
                            index + 1 === newsletterDetail.CARD.length
                              ? style.newsletter_detail_card_item_large
                              : style.newsletter_detail_card_item
                          }
                          key={index}
                        >
                          {data.CONTENT_IMG === "" ? (
                            <Skeleton.Image
                              active
                              style={
                                isMobile
                                  ? { width: "100%" }
                                  : isTablet
                                  ? { width: "290px", height: "164px" }
                                  : { width: "455px", height: "257px" }
                              }
                            />
                          ) : (
                            <Image
                              preview={false}
                              // className={style.webinar_register_img}
                              width={
                                isMobile
                                  ? "100%"
                                  : isTablet
                                  ? props.newsletterId === "4" &&
                                    index + 1 === newsletterDetail.CARD.length
                                    ? 603
                                    : 290
                                  : props.newsletterId === "4" &&
                                    index + 1 === newsletterDetail.CARD.length
                                  ? 934
                                  : 455
                              }
                              height={isMobile ? "auto" : isTablet ? 164 : 257}
                              src={
                                isMobile && data.CONTENT_MOBILE_IMG
                                  ? data.CONTENT_MOBILE_IMG
                                  : data.CONTENT_IMG
                              }
                            />
                          )}
                          <div
                            className={
                              style.newsletter_detail_card_title_wrapper
                            }
                          >
                            {data.COUNTRY_IMG === "" ? (
                              <></>
                            ) : (
                              <img
                                className={
                                  style.newsletter_detail_card_country_img
                                }
                                alt=""
                                src={
                                  process.env.REACT_APP_CLIENT_URL +
                                  data.COUNTRY_IMG
                                }
                              />
                            )}

                            <div className={style.newsletter_detail_card_title}>
                              {data.TITLE}
                            </div>
                          </div>
                          <div className={style.newsletter_detail_card_text}>
                            {data.CONTENT}
                          </div>
                        </div>
                      ))}
                  </div>
                )}
              </div>

              {Array.isArray(newsletterDetail.NEWS) &&
              newsletterDetail.NEWS.length > 0 ? (
                <div className={style.newsletter_detail_news_area}>
                  <div className={style.newsletter_detail_news_area_title}>
                    JETEMA News
                  </div>
                  <div className={style.newsletter_detail_news_list}>
                    {newsletterDetail.NEWS.map((data, index) => (
                      <div
                        className={style.newsletter_detail_news_item}
                        key={index}
                      >
                        <div className={style.newsletter_detail_news_text}>
                          {data.CONTENT}
                        </div>
                        {data.CONTENT_IMG === "" ? (
                          <Skeleton.Image
                            active
                            style={
                              isMobile
                                ? { width: "100%" }
                                : isTablet
                                ? { width: "100%", height: "291px" }
                                : { width: "100%", height: "582px" }
                            }
                          />
                        ) : (
                          <Image
                            preview={false}
                            // className={style.webinar_register_img}
                            width={"100%"}
                            src={
                              isMobile && data.CONTENT_MOBILE_IMG
                                ? data.CONTENT_MOBILE_IMG
                                : data.CONTENT_IMG
                            }
                          />
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
          {isMobile ? (
            <div className={style.newsletter_detail_btn_area}>
              <div
                className={style.newsletter_detail_like_btn}
                onClick={(e) => {
                  e.stopPropagation();
                  favoriteHandler();
                }}
              >
                <img
                  alt=""
                  src={
                    favorite
                      ? process.env.REACT_APP_CLIENT_URL +
                        "img/library/filled_heart.png"
                      : process.env.REACT_APP_CLIENT_URL +
                        "img/library/empty_heart.png"
                  }
                />
              </div>

              <div
                className={style.newsletter_detail_download_btn}
                // onClick={() => window.open(newsletterDetail.PDF_FILE)}
                onClick={() => downloadFile(newsletterDetail)}
              >
                <img
                  alt=""
                  src={
                    process.env.REACT_APP_CLIENT_URL + "img/common/download.png"
                  }
                />
                <span>PDF Download</span>
              </div>
            </div>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
}

export default NewsletterDetail;
