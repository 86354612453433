import React, { useRef, useState, useCallback, useEffect } from "react";
import style from "css/landing.module.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import { Image, Skeleton } from "antd";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function ProductsArea() {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const isTablet = useMediaQuery({
    query: "(min-width: 768px) and (max-width: 1023px)",
  });
  const navigate = useNavigate();
  const clickHandler = (data) => {
    navigate(process.env.REACT_APP_CLIENT_URL + "product/" + data);
  };
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const [productList, setProductList] = useState([]);

  const getProductList = useCallback(() => {
    const data = {
      params: {},
    };

    axios.get("/product/landing", data).then((res) => {
      if (res.status === 200) {
        setProductList(res.data);
      }
    });
  }, []);

  useEffect(() => {
    getProductList();
  }, [getProductList]);

  return (
    <div className={style.products_area}>
      <div className={style.products_title_wrap}>
        <div className={style.products_title}>Products</div>
        <div className={style.products_explanation}>
          <div />
          <span>
            Watch JETEMA’s beauty and cosmetic products for healthy beauty.
          </span>
        </div>
      </div>
      <div className={style.product_swiper_area}>
        <div ref={prevRef} className={style.product_swiper_prev_btn}>
          <img
            alt=""
            src={
              process.env.REACT_APP_CLIENT_URL + "img/landing/arrow_black.png"
            }
          />
        </div>
        <Swiper
          navigation={{ prevEl: prevRef.current, nextEl: nextRef.current }}
          modules={[Autoplay, Navigation]}
          loop={isMobile ? true : false}
          slidesPerView={isMobile ? 1 : 4}
          spaceBetween={isTablet ? 16 : 30}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          onSwiper={(swiper) => {
            setTimeout(() => {
              // Override prevEl & nextEl now that refs are defined
              swiper.params.navigation.prevEl = prevRef.current;
              swiper.params.navigation.nextEl = nextRef.current;

              // Re-init navigation
              swiper.navigation.destroy();
              swiper.navigation.init();
              swiper.navigation.update();
            });
          }}
        >
          {productList.map((product, idx) => (
            <SwiperSlide key={idx} className={style.product_swiper_slide}>
              <div
                className={style.product_swiper_item}
                onClick={() => clickHandler(product.ID)}
              >
                <div className={style.product_swiper_item_img_wrapper}>
                  {product.LANDING_IMG === "" ? (
                    <Skeleton.Image
                      active
                      style={
                        isMobile
                          ? { width: "100%", height: "288px" }
                          : isTablet
                          ? { width: "100%", height: "229px" }
                          : { width: "100%", height: "428px" }
                      }
                    />
                  ) : (
                    <img
                      className={style.product_swiper_item_img}
                      // height={428}
                      alt=""
                      src={product.LANDING_IMG}
                    />
                  )}
                </div>
                <div className={style.product_swiper_item_text}>
                  <div className={style.product_swiper_name}>
                    {product.NAME}
                  </div>
                  {product.DESCRIPTION !== undefined ? (
                    <div className={style.product_swiper_explain}>
                      {product.DESCRIPTION}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div ref={nextRef} className={style.product_swiper_next_btn}>
          <img
            alt=""
            src={
              process.env.REACT_APP_CLIENT_URL + "img/landing/arrow_black.png"
            }
          />
        </div>
      </div>
    </div>
  );
}

export default ProductsArea;
