import React, { useState, useEffect, useCallback } from "react";
import style from "css/menu.module.css";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Cookies } from "react-cookie";
import axios from "axios";

function Menu(props) {
  //cookie
  const cookies = new Cookies();
  //navigate
  const navigate = useNavigate();
  const userPayload = useSelector((state) => state.user);
  const menuHandler = (e) => {
    props.close();
    navigate(process.env.REACT_APP_CLIENT_URL + e);
  };
  const logoutEvent = () => {
    const authNm = process.env.REACT_APP_AUTH_TOKEN;
    const refreshNm = process.env.REACT_APP_REFRESH_TOKEN;
    const domain = process.env.REACT_APP_DOMAIN;
    cookies.remove(authNm, { path: "/", domain: domain });
    cookies.remove(refreshNm, { path: "/", domain: domain });
    window.location.href = process.env.REACT_APP_CLIENT_URL;
  };

  const [registerYn, setRegisterYn] = useState("N");
  const [registerEvent, setRegisterEvent] = useState({});

  const getRegisterEvent = useCallback(() => {
    const data = {
      params: {},
    };

    axios.get("/event/landing/registerCheck", data).then((res) => {
      if (res.status === 200) {
        setRegisterYn(res.data.REGISTER_YN);
        setRegisterEvent(res.data.EVENT);
      }
    });
  }, []);

  useEffect(() => {
    getRegisterEvent();
  }, [getRegisterEvent]);

  return (
    <motion.div
      className={style.menu_wrapper}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className={style.menu_background} onClick={props.close}>
        <div className={style.menu_area} onClick={(e) => e.stopPropagation()}>
          <div className={style.menu_container}>
            <div className={style.menu_list}>
              <div
                className={style.menu_list_item}
                onClick={() => menuHandler("register")}
              >
                Register
              </div>
              <div
                className={style.menu_list_item}
                onClick={() => menuHandler("library")}
              >
                Library
              </div>
              <div
                className={style.menu_list_item}
                onClick={() => menuHandler("product")}
              >
                Product
              </div>
              <div
                className={style.menu_list_item}
                onClick={() => menuHandler("academy")}
              >
                Academy
              </div>
              <div
                className={style.menu_list_item}
                onClick={() => menuHandler("newsletter")}
              >
                Newsletter
              </div>
              <div
                className={style.menu_list_item}
                onClick={() => menuHandler("contactus")}
              >
                Contact Us
              </div>
            </div>
            <div className={style.sign_menu_area}>
              {userPayload.id === "" ? (
                <>
                  <div
                    className={style.sign_menu_item}
                    onClick={() => menuHandler("signin")}
                  >
                    Login
                  </div>
                  <div
                    className={style.sign_menu_item}
                    onClick={() => menuHandler("signup")}
                  >
                    Join
                  </div>
                </>
              ) : (
                <>
                  <div
                    className={style.sign_menu_item}
                    onClick={() => menuHandler("mypage")}
                  >
                    My Page
                  </div>
                  <div
                    className={style.sign_menu_item}
                    onClick={() => logoutEvent()}
                  >
                    Logout
                  </div>
                </>
              )}
            </div>
          </div>

          {registerYn === "Y" ? (
            <div className={style.menu_footer}>
              <div className={style.menu_footer_title_area}>
                <div className={style.menu_footer_title}>
                  {registerEvent.EVENT_NM}
                </div>
                <div className={style.menu_footer_explain}>
                  {"Pre-register and participate in the " +
                    registerEvent.EVENT_NM}
                </div>
              </div>
              <div
                className={style.menu_footer_register_btn}
                onClick={() =>
                  menuHandler("register?id=" + registerEvent.EVENT_ID)
                }
              >
                Register
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </motion.div>
  );
}

export default Menu;
