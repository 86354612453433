import React, { useCallback, useEffect, useRef, useState } from "react";
import style from "css/academy.module.css";
import modalStyle from "css/library/webinarModal.module.css";
import { useMediaQuery } from "react-responsive";
import { Modal, Skeleton } from "antd";
import { useDispatch } from "react-redux";
import { openAlert } from "redux/slices/alertSlice";
import { Cookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

function AcademyItem(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cookies = new Cookies();
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const isTablet = useMediaQuery({
    query: "(min-width: 768px) and (max-width: 1023px)",
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRunning, setIsRunning] = useState(false);
  const [isPlayBtnVisible, setIsPlayBtnVisible] = useState(false);

  const modalHandler = () => {
    if (cookies.get("AuthToken") === undefined) {
      dispatch(
        openAlert({
          title: "Notice",
          content: "This service requires login. Please login first.",
          submitEventHandler: () =>
            navigate(process.env.REACT_APP_CLIENT_URL + "signin", { state: { returnPage: true } }),
        })
      );
    } else {
      setIsModalOpen(true);
    }
  };
  // video control
  const videoRef = useRef(null);
  const playVideo = () => {
    if (videoRef.current) {
      videoRef.current.play();
      setIsRunning(true);
    }
  };

  const pauseVideo = () => {
    if (videoRef.current) {
      videoRef.current.pause();
      setIsRunning(false);
    }
  };

  const isVideoPlaying = useCallback(() => {
    if (videoRef.current) {
      const isPaused = videoRef.current.paused;

      if (isPaused) {
        setIsRunning(false);
      } else {
        setIsRunning(true);
      }
    }
  }, []);

  const btnImg = useCallback(() => {
    if (isRunning) {
      return (
        <img
          className={`${modalStyle.play_triangle}`}
          alt=""
          src={
            process.env.REACT_APP_CLIENT_URL +
            "img/library/pause_triangle_blue.png"
          }
        />
      );
    } else {
      return (
        <img
          className={`${modalStyle.play_triangle}`}
          alt=""
          src={
            process.env.REACT_APP_CLIENT_URL +
            "img/library/play_triangle_blue.png"
          }
        />
      );
    }
  }, [isRunning]);

  useEffect(() => {
    isVideoPlaying();
  }, []);

  return (
    <>
      <Modal
        open={isModalOpen}
        onCancel={(e) => {
          e.stopPropagation();

          pauseVideo();
          setIsModalOpen(false);
        }}
        footer={null}
        width={1136}
        maskClosable={false}
        zIndex={9000}
        centered
      >
        <div className={modalStyle.content_wrapper}     onContextMenu={
        (e) => {
            e.preventDefault();
        }
    } >
          <div className={modalStyle.content_container}>
            <div className={modalStyle.video_container}>
              <div className={modalStyle.video_wrapper}>
                <div
                  className={`${modalStyle.play_btn} ${isPlayBtnVisible ? "" : modalStyle.invisible
                    }`}
                  onMouseEnter={() => setIsPlayBtnVisible(true)}
                  onClick={() => (isRunning ? pauseVideo() : playVideo())}
                >
                  {btnImg()}
                </div>
                <video
                  ref={videoRef}
                  className={modalStyle.video}
                  onMouseEnter={() => setIsPlayBtnVisible(true)}
                  onMouseLeave={() => setIsPlayBtnVisible(false)}
                  onClick={() => setIsRunning(!isRunning)}
                  onPlay={() => isVideoPlaying()}
                  onPause={() => isVideoPlaying()}
                  controls
                  controlsList="nodownload"
                >
                  <source src={props.data.VIDEO_URL} />
                </video>
              </div>
              <div className={modalStyle.video_title}>
                {/* Aesthetic Analysis of Facial Anatomy */}
                {props.data.TITLE}
              </div>
            </div>

            <div className={modalStyle.subContainer}></div>
          </div>
        </div>
      </Modal>
      <div className={style.academy_item} onClick={() => modalHandler()}>
        <div className={`${style.academy_item_img_wrapper}  cursor`}>
          {props.data.THUMBNAIL_IMG === "" ? (
            <Skeleton.Image
              active
              style={
                isMobile
                  ? { width: "100%", height: "249px" }
                  : isTablet
                    ? { width: "294px", height: "165px" }
                    : { width: "338px", height: "190px" }
              }
            />
          ) : (
            <>
              <img
                className={style.academy_item_img}
                alt=""
                src={props.data.THUMBNAIL_IMG}
              />
              <div className={style.play_icon_wrapper}>
                <img
                  className={style.play_icon}
                  alt=""
                  src={
                    process.env.REACT_APP_CLIENT_URL +
                    "img/library/play_triangle_white.png"
                  }
                />
              </div>
            </>
          )}
          {/* <div
          className={style.academy_item_like}
          onClick={(e) => {
            e.stopPropagation();
            favoriteHandler();
          }}
        >
          <img
            alt=""
            src={
              favorite
                ? process.env.REACT_APP_CLIENT_URL +
                  "img/library/filled_heart.png"
                : process.env.REACT_APP_CLIENT_URL +
                  "img/library/empty_heart.png"
            }
          />
        </div> */}
        </div>
        <div className={style.academy_item_title}>{props.data.TITLE}</div>
        <div className={style.academy_item_explain}>
          {props.data.DESCRIPTION}
        </div>
      </div>
    </>
  );
}

export default AcademyItem;
